import {Col, Row} from 'react-bootstrap';
import {TextField} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ICreateCampaign, ICreateCampaignRequest} from '../dto/ICreateCampaign';
import {UploadImage} from '../../../../../shared/components/UploadImage';

interface ICampaignCreateStepOneProps {
    campaignData?: ICreateCampaign;
    createCampaign: ICreateCampaignRequest;
    setCreateCampaign: React.Dispatch<React.SetStateAction<ICreateCampaignRequest>>;
    isSubmitted: boolean;
    setFiles: React.Dispatch<React.SetStateAction<Blob[] | MediaSource[] | undefined>>;
    files: Blob[] | MediaSource[] | undefined;
}

export function CampaignCreateStepOne({
                                          campaignData,
                                          isSubmitted,
                                          createCampaign,
                                          setCreateCampaign,
                                          setFiles,
                                          files,
                                      }: ICampaignCreateStepOneProps) {
    const {t} = useTranslation();
    return (
        <>
            <Row>
                <Col className="px-0 px-md-1" xs={12}>
                    {campaignData &&
                        <TextField
                            label={t('brand.campaign.briefing.campaign_name')}
                            variant="outlined"
                            size="small"
                            defaultValue={campaignData?.info?.title}
                            type="text"
                            error={isSubmitted && !(!!createCampaign?.title)}
                            name="campaignName"
                            className="w-100 my-3"
                            onChange={(event) => setCreateCampaign({
                                ...createCampaign,
                                title: event.target.value,
                            })}
                        />
                    }
                </Col>
                <Col xs={12} className="px-0 px-md-1">
                    <div className="d-flex flex-column mr-2">
                        <h6>{t('brand.campaign.briefing.campaign_cover')}</h6>
                        <p>
                            {t('brand.campaign.briefing.upload_photo_description')}
                        </p>
                    </div>
                    <div className="w-100 cursor-pointer">
                        <div>
                            <UploadImage saveFile={setFiles} isDeleteHidden={true} isFullHeight={true}
                                         error={isSubmitted && !files && !campaignData?.info?.campaignImages[0]?.path}
                                         classes="px-0"
                                         maxSize={Number(process.env.REACT_APP_CAMPAIGN_COVER_MAX_FILE_SIZE)}
                                         previewImage={campaignData?.info?.campaignImages[0]?.path}
                                         isMultiple={false} isPreviewArray={false}/>
                        </div>
                    </div>
                </Col>

            </Row>
        </>
    );
}