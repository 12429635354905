import {Card, Col, Row} from 'react-bootstrap';
import {ICampaignOverview} from '../../../../../model/briefing/overview/ICampaignOverview';
import {rootNavigateWithState} from '../../../../../shared/functions/Functions';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {IconMeasure, MessagesIcon, PlannerIcon, ReportingIcon} from '../../../../../shared/Icons';
import {BackButton} from '../../../../../shared/components/BackButton';
import {IBriefingHeader} from '../../dto/IBriefingHeader';
import {useParams} from 'react-router-dom';
import {IParams} from '../../../../../model/shared/IParams';

interface IBriefingDetailCardProps {
    briefingHeader?: IBriefingHeader;
    campaign: ICampaignOverview,
    isAgencySide?: boolean;
}

export function BriefingDetailTopCard({briefingHeader, isAgencySide = false, campaign}: IBriefingDetailCardProps) {
    const {t} = useTranslation();
    const {id} = useParams<IParams>();
    const isCompleted = campaign?.offers[0]?.status === 'completed'
    const actionNavigateTo = (route: string) => {
        rootNavigateWithState(`/${isAgencySide ? 'agency' : 'influencer'}/${route}`, +id);
    };
    return (
        <>
            <Row className="mb-2 mb-md-0 d-md-flex d-none">
                {isAgencySide &&
                    <Col xl={4} md={2} xs={12} className="mb-3 text-left d-none d-md-block">
                        <BackButton/>
                        <h6 className="mt-2">{t('agency.general_briefing')}</h6>
                    </Col>
                }
                <Col xl={3} md={3}>
                    <Card
                        className={`mb-2 mb-md-4 ${isCompleted ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                        onClick={() => !isCompleted && actionNavigateTo('planner')}>
                        <Card.Body>
                            <div
                                className={'d-flex align-items-center justify-content-between'}>
                                <h6 className="d-flex align-items-center text-muted mb-0">
                                    <PlannerIcon width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                                    <div className="mr-md-3">{t('general.tabs.planner')}</div>
                                </h6>
                                <div className="d-flex justify-content-around w-100">
                                    <div>
                                        <h4 className="text-primary mb-0">{briefingHeader?.plannerTodo}</h4>
                                        <span>
                                            {t('general.todo')}
                                          </span>
                                    </div>
                                    <div>
                                        <h4 className="info-color mb-0">{briefingHeader?.plannerDone}</h4>
                                        <span>
                                            {t('general.done')}
                                          </span>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col id="padding-right-mobile" xl={3} md={3} xs={6}>
                    <Card className="cursor-pointer"
                          onClick={() => actionNavigateTo('reporting')}>
                        <Card.Body>
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 className="d-flex align-items-center text-muted mb-0">
                                    <IconMeasure width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                                    <div>{t('general.reports')}</div>
                                </h6>
                                <div className="d-flex justify-content-around w-100">
                                    <div>
                                        <h4 className="text-primary mb-0">{briefingHeader?.reportsTodo}</h4>
                                        <span>
                                            {t('general.todo')}
                                          </span>
                                    </div>
                                    <div>
                                        <h4 className="info-color mb-0">{briefingHeader?.reportsDone}</h4>
                                        <span>
                                            {t('general.done')}
                                          </span>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col id="padding-left-mobile" xl={2} md={3} xs={6}>
                    <Card className="cursor-pointer"
                          onClick={() => actionNavigateTo('messages')}>
                        <Card.Body>
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 className="d-flex align-items-center text-muted mb-0">
                                    <MessagesIcon width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                                    <div>{t('influencer.campaign.tabs.messages')}</div>
                                </h6>
                                <div>
                                    <h4 className="text-primary mb-0">{briefingHeader?.messagesCount}</h4>
                                    <span>
                                        {t('general.button.new')}
                                      </span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                {!isAgencySide &&
                    <Col xl={4} md={2} xs={12} className="mb-3 text-right d-none d-md-block">
                        <BackButton/>
                    </Col>
                }
            </Row>

            {/*MOBILE HEADER CARDS*/}
            <div className="d-flex align-items-center justify-content-between mb-3 d-md-none">
                <Card onClick={() => actionNavigateTo('planner')}>
                    <Card.Body>
                        <div className="d-flex align-items-center">
                            <PlannerIcon width="15" height="15"/>
                            <span className="font-weight-bold text-muted ml-1">
                                {t('influencer.campaign.tabs.planner')}
                              </span>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <h4 className="text-primary mb-0">{briefingHeader?.plannerDone ?? 0}</h4>
                            <span
                                className="font-weight-bold">/{briefingHeader?.plannerTodo ?? 0}</span>
                        </div>
                    </Card.Body>
                </Card>

                <Card className="mx-2"
                      onClick={() => actionNavigateTo('reporting')}>
                    <Card.Body>
                        <div className="d-flex align-items-center">
                            <ReportingIcon width="15" height="15"/>
                            <span className="font-weight-bold text-muted ml-1">
                                {t('influencer.campaign.tabs.reporting')}
                              </span>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <h4 className="text-primary mb-0">{briefingHeader?.reportsTodo ?? 0}</h4>
                            <span
                                className="font-weight-bold">/{briefingHeader?.reportsDone ?? 0}</span>
                        </div>
                    </Card.Body>
                </Card>

                <Card onClick={() => actionNavigateTo('messages')}>
                    <Card.Body>
                        <div className="d-flex align-items-center">
                            <MessagesIcon width="15" height="15"/>
                            <span className="font-weight-bold text-muted ml-1">
                            {t('influencer.campaign.tabs.messages')}
                        </span>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <h4 className="text-primary mb-0">{briefingHeader?.messagesCount ?? 0}{' '}</h4>
                            <span className="font-weight-bold"> new</span>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>

    );
}
