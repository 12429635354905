import React, { useRef, useEffect, PropsWithChildren } from 'react';
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { OptionsType } from '@fancyapps/ui/types/Fancybox/options';

interface Props {
  options?: Partial<OptionsType>;
  delegate?: string;
}

export function Fancybox(props: PropsWithChildren<Props>) {
  // fixed issue with prev and next 
  useEffect(() => {
    const dialogElement = document.querySelector('.fade.modal');    
    if (dialogElement) {
      dialogElement.removeAttribute('tabindex');
    }
  }, [props]);

  useEffect(() => {

    const delegate = props.delegate || '[data-fancybox]';
    const options = {
      Carousel: {
        infinite: false,
      },
      ...props.options,
    };

    NativeFancybox.bind(delegate, options);

    return () => {
      // NativeFancybox.unbind(container);
      // NativeFancybox.close();
      NativeFancybox.destroy();
    };
    
  }, []);

  return <>{props.children}</>;
}
