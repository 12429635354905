import {BaseModel} from '../../../../model/shared/BaseModel';
import {requests} from '../../../../utils';
import {
    BRAND_BOOKING_CONTRACTS_MODAL_URL,
    BRAND_BOOKING_CONTRACTS_URL,
    BRAND_CONTRACTS_URL, CATEGORY_URL, PLATFORM_URL
} from '../../../../utils/endpoints';
import {Platforms} from '../../../../model/platform/Platforms';
import {IInviteContractInfluencer, Invites} from '../../../../model/book/Invites';
import {CampaignCategory} from '../../../../model/campaign/CampaignCategory';
import {User} from '../../../../model/user/User';
import {ICampaignOverview} from '../../../../model/briefing/overview/ICampaignOverview';
import {IContractOverviewCart} from './contract/dto/IContractOverviewCart';
import {IBookingContracts, IDeclineContract} from './contract/dto/IBookingContracts';
import {IPastCampaign} from '../book/invite/dto/IPastCampaig';
import {IContractsCheckout} from './checkout/dto/IContractsCheckout';
import {IAudienceDataModal} from '../book/offer/dto/IAudienceDataModal';
import {IContract} from '../../../../model/shared/IContract';
import { IContractReviewsData} from '../../../../model/book/Book';

export const ContractService = {
    getContracts: (campaignId: number, params: any): Promise<BaseModel<IBookingContracts>> => requests.get(`${BRAND_BOOKING_CONTRACTS_URL}/${campaignId}`, params),
    declineContract: (offerId?: number, body?: IDeclineContract) => requests.put(`${BRAND_CONTRACTS_URL}/decline/${offerId}`, body ?? {}),
    setBestPick: (offerId?: number) => requests.put(`${BRAND_CONTRACTS_URL}/best-pick/toggle/${offerId}`, {}),
    acceptContract: (offerId?: number) => requests.put(`${BRAND_CONTRACTS_URL}/accept/${offerId}`, {}),
    selectContract: (offerId: number) => requests.put(`${BRAND_CONTRACTS_URL}/selection/${offerId}`, {}),
    clearSelectedContracts: (campaignId: string) => requests.delete(`${BRAND_CONTRACTS_URL}/selection/clear/${campaignId}`),
    getPlatform: (params: any): Promise<BaseModel<Platforms[]>> => requests.get(PLATFORM_URL, params),
    getContractCartInfo: (offerId: number): Promise<IContractOverviewCart> => requests.get(`${BRAND_CONTRACTS_URL}/cart/${offerId}`),
    getInvites: (campaignId: number, params: any): Promise<BaseModel<Invites>> => requests.get(`${BRAND_BOOKING_CONTRACTS_URL}/invites/${campaignId}`, params),
    getPastCampaign: (params?: any): Promise<BaseModel<IPastCampaign[]>> => requests.get(`${BRAND_BOOKING_CONTRACTS_URL}/past/campaigns`),
    getCategory: (): Promise<CampaignCategory[]> => requests.get(CATEGORY_URL),
    getContractsCheckout: (campaignId: string): Promise<IContractsCheckout> => requests.get(`${BRAND_BOOKING_CONTRACTS_URL}/contracts/checkout/${campaignId}`),
    bookSelectedOffer: (campaignId: string): Promise<IContractsCheckout> => requests.put(`${BRAND_BOOKING_CONTRACTS_URL}/contracts/selection/book/${campaignId}`, {}),
    getContract: (userId?: number, campaignId?: number): Promise<ICampaignOverview> => requests.get(`${BRAND_BOOKING_CONTRACTS_MODAL_URL}/user/${userId}/campaign/${campaignId}`),
    inviteUser: (body: IInviteContractInfluencer, userId?: number, campaignId?: number) => requests.post(`${BRAND_BOOKING_CONTRACTS_URL}/invites/user/${userId}/campaign/${campaignId}`, body),
    withdrawOffer: (id?: number) => requests.put(`${BRAND_BOOKING_CONTRACTS_MODAL_URL}/withdraw/${id}`, {}),
    submitOffer: (body: IInviteContractInfluencer, userId?: number, campaignId?: number) => requests.put(`${BRAND_BOOKING_CONTRACTS_MODAL_URL}/counter/${userId}/${campaignId}`, body),
    getAudienceDataModal: (id?: number): Promise<IAudienceDataModal> => requests.get(`${BRAND_BOOKING_CONTRACTS_MODAL_URL}/audience/${id}`),
    getReviewsData: (body: any, id?: number): Promise<BaseModel<IContractReviewsData>> => requests.get(`${BRAND_BOOKING_CONTRACTS_MODAL_URL}/reviews/${id}`, body),
    getInviteContent: (userId?: number): Promise<User> => requests.get(`${BRAND_BOOKING_CONTRACTS_MODAL_URL}/invite/content/${userId}`),
    getHistoryContracts: (id?: number): Promise<IContract[]> => requests.get(`${BRAND_BOOKING_CONTRACTS_MODAL_URL}/history/${id}`),
};

