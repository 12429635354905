import React, {SetStateAction, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Campaign} from '../../../../model/campaign/Campaign';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import usePagination from '../../../../shared/hooks/usePagination';
import {plannerAgency} from '../../../../store/agency/plannerSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {IMyCampaignItem} from '../../../influencer/campaign/dto/IMyCampaigns';
import {IconToDo} from '../../../../shared/Icons';
import {GoDotFill} from 'react-icons/go';


interface IPlannerFiltersProps {
    selectedCalendarView: 'dayGridMonth' | 'dayGridWeek' | 'listWeek';
    campaigns: IMyCampaignItem[];
    setIsCreateModalOpened: React.Dispatch<SetStateAction<boolean>>;
    isToDoSelected: boolean;
    setIsToDoSelected: React.Dispatch<SetStateAction<boolean>>;
    hasUnscheduled: boolean;
}

const AgencyCalendarFilters = ({
                                   campaigns,
                                   setIsCreateModalOpened,
                                   selectedCalendarView,
                                   isToDoSelected,
                                   setIsToDoSelected,
                                   hasUnscheduled,
                               }: IPlannerFiltersProps) => {
    const dispatch = useDispatch();
    const {selectedCampaign} = useSelector((state: IAllStates) => state.plannerAgency);
    const {setFilterParams: setFilterParamsCampaign} = usePagination({});
    const {t} = useTranslation();

    const {offerItemEventsEnum} = useSelector((state: IAllStates) => state.enums);
    const changeCreatorsHandler = (paramKey: string, paramValue: string | number) => {
        if (paramKey === 'offerId') {
            const selectedCampaign = campaigns?.find(campaign => campaign.offerId === +paramValue)
            dispatch(plannerAgency.actions.setSelectedCampaign(selectedCampaign));
            dispatch(plannerAgency.actions.setFilterParams({
                campaignId: selectedCampaign?.id,
                isLongTerm: selectedCampaign?.offers?.[0]?.isLongTerm ?? null
            }));
        } else {
            dispatch(plannerAgency.actions.setFilterParams({[paramKey]: paramValue}));
        }
    };

    useEffect(() => {
        dispatch(plannerAgency.actions.setFilterParams({campaignId: null, isLongTerm: null}));
        dispatch(plannerAgency.actions.setSelectedCampaign(null));
        // eslint-disable-next-line
    }, []);

    return (
        <div className={'d-flex align-items-center justify-content-between flex-column flex-md-row'}>
            <div className="d-flex align-items-center w-md-75">
                <span className="font-weight-semi-bold d-none d-md-flex">
                    {t('influencer.planner.filters')}:
                </span>
                <div className="d-flex align-items-center w-100">
                    <CustomAutocomplete
                        classes="mx-md-4"
                        dataArray={campaigns} isWithImage={false} inputLabel="title"
                        // setFilterState={setFilterParamsCampaign}
                        key={selectedCampaign?.id}
                        isLongSelected={selectedCampaign?.offers?.[0]?.isLongTerm}
                        defaultArray={selectedCampaign as unknown as Campaign[]}
                        disableClearable={false}
                        changeEventKey="offerId" queryFilterKey="offerId"
                        inputLabelTranslation={t('influencer.planner.filterByCampaign')}
                        handleChangeParams={changeCreatorsHandler}
                    />
                    {selectedCalendarView !== 'listWeek' ? <>
                            <CustomAutocomplete
                                classes={'ml-2 ml-md-0'}
                                dataArray={offerItemEventsEnum} inputLabel="plannerStatus" isWithImage={false}
                                queryFilterKey="status" disableClearable={false}
                                inputLabelTranslation={t('influencer.planner.filterByStatus')}
                                handleChangeParams={changeCreatorsHandler}
                            />
                        </> :
                        <div className="col-md-6 ">
                            <div className="position-relative w-fit-content">
                                <Button variant={isToDoSelected ? 'secondary' : 'outline-secondary'}
                                        onClick={() => {
                                            setIsToDoSelected(prev => !prev);
                                        }}
                                        className={`h-100 d-flex align-items-center py-2 ${isToDoSelected ? 'text-white' : ''} text-uppercase`}>
                                    <IconToDo classes="mr-1" fill={isToDoSelected ? '#fff' : undefined}/>
                                    {t('general.button.unscheduled')}
                                </Button>
                                {hasUnscheduled && <GoDotFill className="text-danger top-right-icon"
                                                              style={{top: '-15%', right: '-5%'}}
                                                              size={20}/>}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Button variant="primary" onClick={() => setIsCreateModalOpened(true)}
                    className={'mt-3 mt-md-0 w-100 w-md-fit'}>
                {t('influencer.planner.addNewPosting')}
            </Button>
        </div>
    );
};

export default AgencyCalendarFilters;
