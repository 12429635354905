import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router';
import {useLocation} from 'react-router-dom';
import {ErrorBoundary} from '../../shared/components/ErrorBoundary';
import LoadingScreen from '../../shared/components/LoadingScreen';
import CampaignRegister from './campaign/Briefing';


export function PublicRoutes() {
    const {pathname} = useLocation();

    return (
        <Suspense fallback={<LoadingScreen />}>
            <ErrorBoundary key={pathname}>
                <Switch>
                    <Route path="/public/campaign/briefing/:uuid" component={CampaignRegister}/>
                </Switch>
            </ErrorBoundary>
        </Suspense>
    );
}
