import React, {ChangeEvent, useEffect} from 'react';
import {InfluencerAdditionalItemsTable} from './InfluencerAdditionalItemsTable';
import {InfluencerContractPlatformTable} from './InfluencerContractPlatformTable';
import {Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {InfluencerContractOptionalItemsTable} from './InfluencerContractOptionalItemsTable';
import _debounce from 'lodash/debounce';
import {useDispatch, useSelector} from 'react-redux';
import {setPersonalOfferDescription} from '../../../../../../../store/influencer/contractInfluencerSlice';
import {IAllStates} from '../../../../../../../store/rootReducer';
import {DeclineOffer} from '../../../offer-modal/tabs/offer/DeclineOffer';
import {InfluencerOfferHeader} from '../../../offer-modal/tabs/offer/InfluencerOfferHeader';

export function InfluencerContractTab() {
    const {t} = useTranslation();
    const {
        initialContract,
        isEditable,
        campaign,
        contractModalStatus,
        isCharity
    } = useSelector((state: IAllStates) => state.contractInfluencer);

    const dispatch = useDispatch();
    const changeHandler = _debounce(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = event?.target?.value;
            dispatch(setPersonalOfferDescription(value));
        },
        300,
    );

    return (
        <>
            <DeclineOffer declineComment={campaign?.contracts[0]?.declineComment}
                             declineReason={campaign?.contracts[0]?.declineReason}
                             status={campaign?.contracts[0]?.status}/>
            <InfluencerOfferHeader createdAt={campaign?.contracts[0]?.createdAt ?? ''}
                                      offerModalStatus={contractModalStatus}
                                      isEditable={isEditable} isRequested={campaign?.contracts[0]?.isRequested}/>
            <div className="p-md-3">
                <InfluencerContractPlatformTable/>
                <InfluencerAdditionalItemsTable/>
                {(contractModalStatus === 'negotiate' && (campaign?.contractPlaceholder?.distribution && campaign?.contractPlaceholder?.distribution?.length > 0) && !isCharity) &&
                    <InfluencerContractOptionalItemsTable/>}
                {contractModalStatus === 'negotiate' &&
                    <>
                        <h6 className="mt-3">{t('influencer.offer_management.modal.add_personal_offer')}</h6>
                        <Form.Control
                            id="basic-textarea"
                            onChange={changeHandler}
                            defaultValue={initialContract.personalOffer}
                            as="textarea"
                            name="personalOffer"
                            rows={3}
                            disabled={campaign?.isCharity}
                            placeholder={t('influencer.offer_management.modal.add_personal_offer_placeholder')}/>
                    </>

                }
                {(contractModalStatus !== 'negotiate' && campaign?.contracts[0]?.personalOffer) &&
                    <div className="mt-4">
                        <div>
                            <h5>{t('influencer.offer_management.modal.add_personal_offer')}</h5>
                        </div>
                        <h5 className="text-muted mb-0">{campaign?.contracts[0]?.personalOffer}</h5>
                    </div>
                }
            </div>
        </>
    );
}
