import React, {SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Campaign} from '../../../model/campaign/Campaign';
import {Pagination} from '../../../shared/components/Pagination';
import {IAllStates} from '../../../store/rootReducer';
import {IMyCampaignsFilterProps} from '../campaign/dto/IMyCampaigns';
import MyCampaigns from './components/mobile/MyCampaigns';
import MyOffers from './components/mobile/MyOffers';
import {IMyOffers} from './dto/IDashboard';
import {TermsOfServiceAlert} from "../../../shared/components/alert/TermsOfServiceAlert";
import {Col} from 'react-bootstrap';
import {ContractList} from './components/desktop/ContractList';
import {ICampaignOverview} from '../../../model/briefing/overview/ICampaignOverview';
import {IOfferWithCampaign} from '../profile/profile-view';


interface Props {
    myOffers: IMyOffers;
    offers?: IOfferWithCampaign[];
    contractCampaigns?: ICampaignOverview[];
    filterParams: IMyCampaignsFilterProps;
    setFilterParams: React.Dispatch<SetStateAction<IMyCampaignsFilterProps>>;
    totalPages: number;
    handlePages: (updatePage: number) => void;
}

const MobileDashboard = ({
                             contractCampaigns,
                             myOffers,
                             offers,
                             setFilterParams,
                             filterParams,
                             handlePages,
                             totalPages
                         }: Props) => {
    const {t} = useTranslation();
    const {user} = useSelector((state: IAllStates) => state.auth);
    return (
        <div className="d-md-none">
            <h4>{t('influencer.header.welcome_back')} {user?.firstName}!</h4>
            <TermsOfServiceAlert/>
            <MyOffers myOffers={myOffers} filterParams={filterParams} setFilterParams={setFilterParams}/>
            {!!contractCampaigns?.length &&
                <ContractList campaigns={contractCampaigns}/>
            }
            {/*<MyCampaigns campaigns={campaigns}/>*/}
            <MyCampaigns offers={offers}/>
            {totalPages <= 1 ? (
                ''
            ) : (
                <Pagination
                    page={filterParams.page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                />
            )}
        </div>
    );
};

export default MobileDashboard;
