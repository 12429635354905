import {Card, ProgressBar} from 'react-bootstrap';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {formatDate} from '../../../../../shared/functions/Functions';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {UserRoles} from '../../../../../enum/UserRoles';
import {gtmService} from '../../../../../utils/gtm.service';
import {ICampaignOverview} from '../../../../../model/briefing/overview/ICampaignOverview';
import {priceSplitter} from '../../../../../utils/helpers/priceSplitter';

interface IContractCardProps {
    campaign?: ICampaignOverview;
}

export function ContractCard({campaign}: IContractCardProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const {
        user: {
            userType,
        },
    } = useSelector((reduxState: IAllStates) => reduxState.auth);

    const isAgency = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles);

    const navigateToCampaignOverview = (campaignId: number) => {
        isAgency ?
            history.push(`/agency/campaign/${campaignId}/overview`) :
            history.push(`/influencer/campaign/${campaignId}/overview`);
        gtmService('select_content');
    };

    return (
        <>
            <Card className="p-2 h-100 d-flex flex-column justify-content-between overflow-hidden">
                {(campaign?.isLongTerm || !!campaign?.parent?.id) &&
                    <div className="diagonal-text">
                            <span className="diagonal-text-sm">
                                {t(`brand.campaign.briefing.overview_card.${campaign?.isLongTerm ? 'long_term' : 'linked'}`)}
                            </span>
                    </div>
                }
                <div className="d-flex align-items-start justify-content-between">
                    <div
                        className="position-relative w-50 cursor-pointer"
                        onClick={() => navigateToCampaignOverview(campaign?.id ?? 0)}
                    >
                        <CustomImageHandler
                            style={{height: 130}}
                            classes="img-cover default-radius-sm w-100 shadow"
                            thumbnailPath={campaign?.campaignImages?.[0]?.thumbnail}
                            photoPath={campaign?.campaignImages?.[0]?.thumbnail}
                            altTag="Campaign logo"
                        />
                        <span
                            className={`status campaign-status-dashboard ${
                                campaign?.status ?? campaign?.contracts[0]?.status
                            } position-absolute`}
                        >
                            {t(`general.campaign_status.${campaign?.status ?? campaign?.contracts[0]?.status}`)}
                        </span>
                    </div>
                    <div className="w-50 px-3 d-flex flex-column h-100 justify-content-between">
                        <h5
                            className="cursor-pointer font-weight-bold"
                            onClick={() => navigateToCampaignOverview(campaign?.id ?? 0)}
                        >
                            {campaign?.title ?? '-'}
                        </h5>
                        <div className="d-flex flex-column ">
                            <div className="mb-2">
                                <div>{t('influencer.explore.publicationPeriod')} :</div>
                                <div className="text-primary font-weight-bold">
                                    {formatDate(campaign?.publishFrom ?? '')
                                        + ' - ' + formatDate(campaign?.publishTo)}
                                </div>
                            </div>
                            <span className="text-muted font-weight-bold">
                                {campaign?.company?.displayName ?? '-'}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-2">
                    {campaign?.contracts?.[0]?.status !== 'completed' && (
                        <div className="col-4 p-0">
                            <div className="m-auto w-max-content text-center">
                                <div className="text-primary font-weight-extra-bold">
                                    {formatDate(campaign?.postingDeadline ?? '')}
                                </div>
                                <div>
                                    {t('brand.campaign.briefing.step3.postingDeadline')}
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        className={
                            campaign?.contracts?.[0]?.status !== 'completed'
                                ? 'col-4 p-0'
                                : 'col-6 p-0'
                        }
                    >
                        <div
                            className={
                                campaign?.contracts?.[0]?.status !== 'completed'
                                    ? 'm-auto w-maxContent  pr-md-0 text-center'
                                    : ''
                            }
                        >
                                    <span
                                        className={`${!campaign?.isCharity && 'euro-symbol'} text-primary font-weight-extra-bold`}>
                                        {campaign?.isCharity ? t('brand.account.price_list_modal.barter_deals') :
                                            priceSplitter(
                                                Math.round(campaign?.contracts?.[0]?.price ?? 0)
                                            )}
                                    </span>
                            <div>{!campaign?.isCharity && t('brand.campaign.book.offer.modal.price')}</div>
                        </div>
                    </div>
                    <div
                        className={
                            campaign?.contracts?.[0]?.status !== 'completed'
                                ? 'col-4 p-0'
                                : 'ml-3'
                        }
                    >
                        <div className="m-auto w-max-content text-center">
                            <div className="text-primary font-weight-extra-bold">
                                {campaign?.contracts?.[0]?.nbPosts ?? 0}
                            </div>
                            <div>{t('influencer.explore.contentPieces')}</div>
                        </div>
                    </div>
                </div>
                <div className="mb-2 mt-3">
                    <div className="d-flex justify-content-between mb-1">
                        <p className="mb-0">
                            Postings completed
                            <span className="text-primary ml-3 font-weight-extra-bold">
                                {campaign?.contracts?.[0]?.postingsUsed ?? 0}
                            </span>
                            /
                            <span>{campaign?.contracts?.[0]?.nbPosts ?? 0}</span>
                        </p>
                        <p className="mb-0">
                            {campaign?.contracts?.[0]?.usagePercentage ?? 0}%
                        </p>
                    </div>
                    <ProgressBar
                        variant="primary"
                        className="progress-sm w-100 bg-primary-lighter"
                        now={campaign?.contracts?.[0]?.usagePercentage}
                    />
                </div>
            </Card>
        </>
    );
};