import React, {SetStateAction, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Fancybox} from '../../../../../../shared/components/Fancybox';
import {UploadImage} from '../../../../../../shared/components/UploadImage';
import {IReportItems} from '../../../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import {formatDate} from '../../../../../../shared/functions/Functions';
import {IReportingUpdate} from '../../ReportingCampaignCard';
import {BsFillTrashFill} from 'react-icons/bs';
import {IPicture} from '../../../../../../model/shared/IPicture';
import { OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { PlannerStatusEnum } from '../../../../../../enum/PlannerStatusEnum';

interface Props {
    reportItems: IReportItems[];
    setUpdateData: React.Dispatch<SetStateAction<IReportingUpdate>>;
    status: string;
    setSelectedFile?: React.Dispatch<SetStateAction<IPicture>>;
    isAgency?: boolean;
    fileKey?: string;
    isButtonDisabled?: boolean;
    errorFile?: boolean;
}

const ReportingScreenshots = ({
                                  reportItems,
                                  isAgency = false,
                                  isButtonDisabled = false,
                                  setUpdateData,
                                  status,
                                  setSelectedFile,
                                  fileKey = 'file',
                                  errorFile
                              }: Props) => {
    const {t} = useTranslation();
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();

    useEffect(() => {
        if (files) {
            setUpdateData(prev => ({...prev, [fileKey]: files}));
        }
        // eslint-disable-next-line
    }, [files]);

    return (
        <div className={isAgency ? 'mt-3' : 'col-12 mt-3'}>
            <h5>{t('influencer.reporting.reportingScreenshots')}</h5>
            <span className={`${errorFile ? 'text-danger' : 'text-muted'}`}>
                {t('influencer.reporting.reportingScreenshotsDescription')}
            </span>
            <Row>
                <div className="col-md-4 pl-md-0 my-1">
                    <UploadImage disabled={isButtonDisabled} saveFile={setFiles} isDeleteHidden={true} isMultiple={true}
                                 isSmall error={errorFile}
                                acceptedFormats="image/jpeg, image/png, image/jpg"
                                 classes="px-0" maxFiles={50}/>
                </div>
              <Fancybox>
              {reportItems?.map(reportImages => (
                    <React.Fragment key={reportImages?.id}>
                        {reportImages?.reportImages?.map(file => (
                            <div className="col-md-4 pl-md-0 my-1" key={file?.id}>
                                <div className={`content-files-card border-primary`}>
                                    <Row>
                                        <div className="col-4">
                                            <div
                                                data-fancybox={`gallery-${reportImages?.id}`}
                                                className="cursor-pointer"
                                                data-src={file?.externalLink ?? file?.reportPath ?? '/icons/filePlaceholder.png'}
                                            >
                                                <CustomImageHandler
                                                    classes="card-img"
                                                    photoPath={file?.reportPath as string}
                                                    thumbnailPath={file?.thumbnail as string}
                                                    altTag="Campaign image"/>
                                            </div>
                                        </div>
                                        <div className="col-8 pl-0">
                                            <div className="p-2 p-md-0 d-flex flex-column align-items-end justify-content-between h-100">
                                                <div className="d-flex flex-column w-100">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="country-tooltip">
                                                                <span className='word-brake'>{file?.title ?? '-'}</span>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='text-truncate'>{file?.title ?? '-'}</span>
                                                    </OverlayTrigger>
                                                    <span className="text-muted">
                                                        {formatDate(file?.createdAt)}
                                                    </span>
                                                </div>
                                                {/* status === undefined means it's additional */}
                                                {(status === PlannerStatusEnum.REPORTED || status === undefined) && (
                                                    <BsFillTrashFill className="text-muted cursor-pointer font-20"
                                                                     onClick={() => {
                                                                         if (setSelectedFile) {
                                                                             setSelectedFile(file);
                                                                         }
                                                                     }}/>
                                                )}
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </React.Fragment>
                ))}
              </Fancybox>
            </Row>
        </div>
    );
};

export default ReportingScreenshots;
