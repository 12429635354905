import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { CustomImageHandler } from "../../../shared/components/CustomImageHandler";
import { NavLink, useHistory, useParams } from "react-router-dom";
import HeaderPublic from "../../../shared/layout/components/header/HeaderPublic";
import { PublicService } from "../service";
import { IParams } from "../../../model/shared/IParams";
import { ErrorToast } from "../../../utils/toasters";
import { Campaign } from "../../../model/campaign/Campaign";
import { clearHtmlFromString, formatDate } from "../../../shared/functions/Functions";
import { t } from "i18next";
import { Helmet } from "react-helmet";

export default function CampaignRegister() {
  const history = useHistory();
  const [campaign, setCampaign] = useState<Campaign>();
  const { uuid } = useParams<IParams>();

  useEffect(() => {
    PublicService.getCampaign(uuid).then(response => {
      setCampaign(response);
    }).catch(error => ErrorToast(error));
  }, []);

  return (
    <div>
      <Helmet>
        <title>{campaign ? campaign.title : 'Campaign'}</title>
        <meta name="description" content={campaign ? campaign.adDescription : 'Campaign details'} />
        <meta property="og:title" content={campaign ? campaign.title : 'Campaign'} />
        <meta property="og:description" content={campaign ? campaign.adDescription : 'Campaign details'} />
        <meta property="og:image" content={campaign?.campaignImages[0].path} />
        <meta property="og:url" content={`${window.location.origin}/public/campaign/briefing/${uuid}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={campaign ? campaign.title : 'Campaign'} />
        <meta name="twitter:description" content={campaign ? campaign.adDescription : 'Campaign details'} />
        <meta name="twitter:image" content={campaign?.campaignImages[0].path} />
      </Helmet>
      
      <HeaderPublic />

      <div style={{ maxWidth: 640 }} className="mx-auto mt-5 px-4 mb-4">
        <Card>
          <Card.Body className="p-0">
            <div className="text-center px-4 px-sm-5 pt-4">
              <h3 className="mb-4">{campaign?.title}</h3>
              <h6 className="text-primary">{campaign?.brand?.name}</h6>
              <CustomImageHandler classes="rounded max-width-100 img-cover" photoPath={campaign?.campaignImages[0].path} altTag="campaign" />
            </div>

            <div className="px-4 px-sm-5 mt-5">
              <h6>{t('brand.campaign.briefing.campaign_desc')}</h6>
              <p className="text-muted truncate-3">
                {clearHtmlFromString(campaign?.adDescription ?? '-')}
              </p>
            </div>

            <div className="d-flex justify-content-between mt-4">
              <div className="pl-4 pl-sm-5 pr-2">
                <h6>{t('brand.campaign.dashboard.app_deadline')}</h6>
                <p className="text-muted truncate-3">
                  {formatDate(campaign?.date)}
                </p>
              </div>

              <div className="pr-4 pr-sm-5pl-2">
                <h6>{t('brand.campaign.dashboard.pub_period')}</h6>
                <p className="text-muted truncate-3">
                  {formatDate(campaign?.publishFrom)} - {formatDate(campaign?.publishTo)}
                </p>
              </div>
            </div>

            <div className="text-center">
              <Button onClick={() => history.push('/influencer/register/step/1')}>Register now</Button>
              <div className="mt-2">
                <span className="text-muted mr-2">{t('auth.already_have_profile')}</span>
                <NavLink to={"/"}>Login</NavLink>
              </div>
            </div>

            <div
              className="pt-5 pb-4 text-center"
              style={{
                backgroundImage: `url('https://testing-app.influencevision.com/icons/emails/mail-shapes-3.png'), url('https://testing-app.influencevision.com/icons/emails/mail-shapes-2.png')`,
                backgroundPosition: "-12px 0px, 100% 15px",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div>
                <div className="text-muted">influence.vision GmbH</div>
                <div className="text-muted">Vienna | Cologne | Bijeljina</div>
                <div>
                  <a
                    href="https://calendly.com/influencevision-support/support-influence-vision"
                    target="_blank"
                  >
                    Support
                  </a>
                </div>
                <div>
                  <a href="mailto:support@influencevision.com">
                    support@influencevision.com
                  </a>
                </div>
                <div>
                  <a href="https://www.influencevision.com/" target="_blank">
                    www.influencevision.com
                  </a>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
