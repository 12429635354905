import React from 'react';
import {Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

interface IInfluencerCampaignsListProps {
    children: JSX.Element;
    isAgency?: boolean;
    isContracts?: boolean;
}

export function InfluencerCampaignsList({children, isAgency, isContracts}: IInfluencerCampaignsListProps) {
    const {t} = useTranslation();
    return (
        <Table responsive>
            <thead>
                <tr className="text-center">
                    <th>{t('general.campaign')}</th>
                    {!isContracts && (
                        <th>Type</th>
                    )}
                    {isAgency &&
                        <th>{t('general.influencer')}</th>
                    }
                    <th>{t('general.brand')}</th>
                    <th>{t('general.postings')}</th>
                    <th>{t('general.earning')}</th>
                    <th>{t('general.status')}</th>
                    <th>{t('general.actions')}</th>
                </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </Table>
    );
}
