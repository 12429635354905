export enum InternalRoutes {
    BRAND_DASHBOARD = '/brand/dashboard',
    BRAND_CAMPAIGN = '/brand/campaign',
    BRAND_CAMPAIGN_CREATE = '/brand/create/campaign',
    BRAND_CAMPAIGN_BOOK = '/brand/campaign/book',
    BRAND_CAMPAIGN_MANAGE = '/brand/campaign/manage',
    BRAND_CAMPAIGN_MEASURE = '/brand/campaign/measure',
    BRAND_CAMPAIGN_MESSAGES = '/brand/campaign/messages',
    BRAND_CAMPAIGN_DASHBOARD = '/brand/campaign/dashboard',
    BRAND_CAMPAIGN_OPTIONS = '/brand/campaign/options',
    BRAND_CAMPAIGN_CONTRACTS = '/brand/campaign/contracts',
    BRAND_PROFILE = '/settings',
    BRAND_USER = '/settings/users',
    BRAND_SOCIAL = '/settings/social',
    BRAND_REGISTER = '/brand/register',
    BRAND_CLIENT = '/brand/client',
    BRAND_SUPPLIER = '/brand/supplier',
    BRAND_PACKAGES = '/brand/package-overview',
    MY_NETWORK = '/settings/my-network',
    INFLUENCER_PROFILE = '/influencer/profile',
    INFLUENCER_PROFILE_EDIT = '/settings/edit',
    INFLUENCER_PUBLIC_PROFILE = '/brand/influencer/profile',
    INFLUENCER_REGISTRATION = '/influencer/register/step',
    INFLUENCER_ROUTES = '/influencer',
    AGENCY_REGISTRATION = '/agency/register/step',
    AGENCY_ROUTES = '/agency',
    AGENCY_CAMPAIGNS = '/agency/campaigns',
    AGENCY_PROFILE = '/agency/influencer/profile',
    AGENCY_DECLINED_OFFER = '/agency/offer/declined',
    REGISTER_LINK = 'https://app.influencevision.com/influencer/register/step/1',
    VERIFICATION = '/verification-page',
}
