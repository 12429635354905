import React from 'react';
import {ITotalPlatforms} from '../../../../../../../../model/platform/ITotalPlatforms';
import {Table} from 'react-bootstrap';
import {Totals} from '../contract/Totals';
import {getTotals} from '../../../../../../../../shared/functions/Functions';
import {useTranslation} from 'react-i18next';
import {ContractHistoryPlatformAccordion} from './ContractHistoryPlatformAccordion';
import {TotalTypes} from '../../../../../../../../enum/TotalTypes';

interface IOfferAccordionProps {
    totalPlatforms?: ITotalPlatforms[];
}

export function ContractHistoryOfferPlatformTable({totalPlatforms}: IOfferAccordionProps) {
    const {t} = useTranslation();
    return (
        <>
            <Table responsive className="table-head-bg m-0 mt-1">
                <thead className="table-head-bg">
                <tr>
                    <th>{t('brand.campaign.book.offer.modal.platform')}</th>
                    <th>{t('brand.campaign.book.offer.modal.followers')}</th>
                    <th>{t('brand.campaign.book.offer.modal.avg_reach')}</th>
                    <th>{t('brand.campaign.book.offer.modal.audience_per_country')}</th>
                    <th>{t('brand.campaign.book.offer.modal.cpm')}</th>
                    <th className="text-right" style={{width: 150}}>
                        {t('brand.campaign.book.offer.modal.price')}
                    </th>
                </tr>
                </thead>
                <tbody>
                {totalPlatforms?.map((platform, index) => (
                    <ContractHistoryPlatformAccordion key={index} platform={platform}/>
                ))}
                </tbody>
            </Table>
            <div className="w-100 mt-2">
                <Totals title={TotalTypes.OFFER_ITEMS}
                        price={getTotals(undefined, totalPlatforms)}
                />
            </div>
        </>
    );
}