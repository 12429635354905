import React from 'react';
import {Card, Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import Flag from 'react-world-flags';
import {User} from '../../../../../model/user/User';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import EmptyState from '../../../../../shared/components/placeholder/EmptyState';
import {Fancybox} from '../../../../../shared/components/Fancybox';
import {formatDate, getCorrectImageThumbnail, linkify} from '../../../../../shared/functions/Functions';
import {numberFormatter} from '../../../../../utils/helpers/numberFormater';
import DOMPurify from 'dompurify';

interface IProfileTabProps {
    state: User;
    isPublicProfile: boolean;
}

const ProfileTab = ({state, isPublicProfile}: IProfileTabProps) => {
    const {t} = useTranslation();
    return (
        <Row>
            <Col xs={12} className="pl-md-0">
                <h6>{t('influencer.profile.about_me')}</h6>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(linkify(state?.about ?? '-'))}}/>
            </Col>
            <Col xs={12} className="pl-md-0 mb-2">
                <h6>{t('influencer.profile.socialDataOverview')}</h6>
                <span className="text-muted">
                    {t('influencer.profile.socialDataOverviewDescription')}
                </span>
            </Col>
            {state?.totalPlatforms?.map((platform) => (
                <Col md={4} className="pl-md-0 my-2 " key={platform?.id}>
                    <Card className="h-100">
                        <Card.Body>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <img src={platform?.path} alt={platform?.icon} height={33}/>
                                    <div className="d-flex flex-column ml-1">
                                        <span className="font-weight-semi-bold ">
                                            {platform?.name}
                                        </span>
                                        <span className="text-muted">
                                            {platform?.username ? '@' + platform?.username : 'n.a.'}
                                        </span>
                                    </div>
                                </div>
                                {!isPublicProfile &&
                                    <div>
                                        <h5 className="text-primary mb-0">
                                            {numberFormatter(platform?.followers > 0 ? platform.followers : platform.subscribers > 0 ?
                                                platform.subscribers : 0)}
                                        </h5>
                                        <span>{platform?.subscribers > 0 ? t('influencer.profile.subscribers') : t('influencer.profile.followers')}</span>
                                    </div>
                                }
                            </div>
                            {isPublicProfile &&
                                <div className="mt-3 row">
                                    <div className="col-lg-4 px-0">
                                        <div>
                                            <h5 className="text-primary mb-0">
                                                {numberFormatter(platform?.followers ?? 0)}
                                            </h5>
                                            <span>{t('influencer.profile.followers')}</span>
                                        </div>
                                        <div className="my-2">
                                            <h5 className="text-primary mb-0">
                                                {platform?.engagementRate > 0
                                                    ? `${Math.round(platform?.engagementRate)} %`
                                                    : '-'}
                                            </h5>
                                            <span>{t('influencer.profile.engagementRate')}</span>
                                        </div>
                                        <div>
                                            <h5 className="text-primary mb-0">
                                                {numberFormatter(platform?.reach)}
                                            </h5>
                                            <span>{t('influencer.profile.avgReach')}</span>
                                        </div>
                                    </div>
                                    <p className="mb-2"></p>
                                    <div className="col-lg-8 px-0 pl-lg-3">
                                        <div className="d-flex flex-wrap overflow-auto pt-3 pt-md-0">
                                            {!!platform?.audiences?.length
                                                ? platform?.audiences?.slice(0, 3).map((audience) => (
                                                    <div
                                                        key={audience?.id}
                                                        className="d-flex align-items-center mx-1"
                                                    >
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="country-tooltip">
                                                                    {t(`general.countries.${audience?.metric}`)}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div className="country-flag rounded">
                                                                <Flag code={audience?.metric}/>
                                                            </div>
                                                        </OverlayTrigger>
                                                        <div className="d-flex mx-1 flex-column ">
                                                            <span className="text-primary font-weight-bold mb-0">
                                                                {Math.round(audience?.value)}%
                                                            </span>
                                                            <span className="text-secondary mb-0">
                                                                {numberFormatter(audience?.followers)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))
                                                : '-'}
                                        </div>
                                        <span>
                                            {t('brand.campaign.book.invite.audience_per_country')}
                                        </span>
                                    </div>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            ))}
            {!!state?.userKpiScreenshots?.length && (
                <Col xs={12} className='px-0 mt-3'>
                    <h6>
                        {t('influencer.profile.audienceKPIScreenshots')}
                    </h6>
                    <Row>
                        {state?.userKpiScreenshots?.map((screenshot) => (
                            <Col md={2} key={screenshot?.id} className="pl-md-0 my-1">
                                <Fancybox>
                                    <div
                                        data-fancybox="gallery-feedItems"
                                        className="cursor-pointer w-100"
                                        data-src={screenshot?.path ?? '/icons/filePlaceholder.png'}
                                    >
                                        <CustomImageHandler
                                            classes="highlighted-post"
                                            thumbnailPath={screenshot.thumbnail}
                                            placeholder={screenshot?.path ?? '/icons/filePlaceholder.png'}
                                            photoPath={screenshot?.path ?? '/icons/filePlaceholder.png'}
                                            altTag={screenshot?.originalName!}/>
                                    </div>
                                </Fancybox>
                                <div className='text-center text-primary'>{formatDate(screenshot?.createdAt)}</div>
                            </Col>
                        ))}
                    </Row>
                </Col>
            )}
            <Col xs={12} className="pl-md-0 mt-3 mb-3">
                <h6>{t('influencer.profile.highlightedPosts')}</h6>
                <span className="text-muted">
                    {t('influencer.profile.highlightedPostsDescription')}
                </span>
            </Col>
            {!!state?.feedItems?.length ? state?.feedItems?.map((feed) => (
                <Col md={2} key={feed?.id} className="pl-md-0 my-1">
                    <Fancybox>
                        <div
                            data-fancybox="gallery-highligted-post"
                            className="cursor-pointer w-100"
                            data-src={feed?.postImages?.[0]?.externalLink ?? feed?.postImages?.[0]?.path ?? '/icons/filePlaceholder.png'}
                        >
                            <div className="position-relative">
                                <CustomImageHandler
                                    classes="highlighted-post cursor-pointer"
                                    style={{height: 210}}
                                    thumbnailPath={feed?.postImages?.[0]?.thumbnail}
                                    photoPath={getCorrectImageThumbnail(!!feed?.postImages?.[0]?.externalLink, feed?.postImages?.[0]?.name ?? '', feed?.postImages?.[0]?.thumbPath ?? '', feed?.postImages?.[0]?.path)}
                                    placeholder={getCorrectImageThumbnail(!!feed?.postImages?.[0]?.externalLink, feed?.postImages?.[0]?.name ?? '', feed?.postImages?.[0]?.thumbPath ?? '', feed?.postImages?.[0]?.path)}
                                    altTag={feed?.platform}
                                />
                            </div>
                        </div>
                    </Fancybox>
                </Col>
            )) : <div className="col-12 card pl-md-0">
                <div className="card-body">
                    <EmptyState/>
                </div>
            </div>}
        </Row>
    );
};

export default ProfileTab;
