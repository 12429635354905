import {useTranslation} from 'react-i18next';
import {formatDate} from '../../../../../../../shared/functions/Functions';
import React from 'react';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../store/rootReducer';

interface IOfferHeaderProps {
    createdAt: string;
    offerModalStatus: string;
    isEditable?: boolean;
    isRequested?: boolean;
}

export function InfluencerOfferHeader({createdAt, offerModalStatus, isEditable, isRequested}: IOfferHeaderProps) {
    const {t} = useTranslation();

    return (
        <>
            {offerModalStatus !== 'negotiate' &&
                <div className="px-md-3 d-flex justify-content-between justify-content-md-end">
                    <h6 className="text-left mb-0 d-md-none">
                        {t('brand.campaign.book.offer.modal.offer_item')}
                    </h6>
                        <p className="text-muted w-md-100 text-right mb-0">
                            {isRequested &&
                                <span className="mr-2 status invite text-dark" style={{fontSize: 10}}>
                                    {t(`general.campaign_status.invite`)}
                                </span>
                            }
                            {isEditable ?
                                t('brand.campaign.book.offer.modal.received_at') :
                                t('brand.campaign.book.offer.modal.sent_at')}
                            - {formatDate(createdAt)} - {createdAt.substring(11, 16)}
                        </p>
                </div>
            }
        </>
    );
};
