import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {IBriefingHeader} from '../../dto/IBriefingHeader';
import {priceSplitter} from '../../../../../utils/helpers/priceSplitter';

interface IBriefingContractDetailTopCardProps {
    briefingHeader?: Partial<IBriefingHeader>;
}
export function BriefingContractDetailTopCard({briefingHeader}: IBriefingContractDetailTopCardProps) {
    const {t} = useTranslation();

    return (
      <>
          <Card>
              <Card.Body className="d-flex justify-content-between align-items-center">
                  <h6 className="mb-0 text-muted mr-2">
                      {t('brand.campaign.book.contract.overview')}
                  </h6>
                  <div className="mr-1">
                      <h6 className="text-primary euro-symbol">{briefingHeader?.earnings ?
                        priceSplitter(briefingHeader.earnings) : 0}</h6>
                      <p className="mb-0">
                          {t('brand.campaign.book.contract.total_earnings')}
                      </p>
                  </div>
                  <div className="mr-1">
                      <h6>
                          <span className="text-primary font-weight-extra-bold">{briefingHeader?.usedPosts ?? 0}</span>
                          /
                          <span className="font-weight-extra-bold">{briefingHeader?.bookedPosts ?? 0}</span>
                      </h6>
                      <p className="mb-0">
                          {t('brand.campaign.book.contract.posting_used')}
                      </p>
                  </div>
                  <div>
                      <h6 className="text-primary">
                          {briefingHeader?.postingsCompleted ?? 0}
                      </h6>
                      <p className="mb-0">
                          {t('brand.campaign.book.contract.posting_completed')}
                      </p>
                  </div>
              </Card.Body>
          </Card>
      </>
    );
}
