import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import {Checkbox} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import {BrandedContent} from '../../../../campaign/briefing/components/BrandedContent';
import {useTranslation} from 'react-i18next';
import {UsageRights} from '../../../../campaign/briefing/components/UsageRights';
import {setDistributionCheck} from '../../../../../../store/influencer/contractInfluencerSlice';

export function ContractDistributionTab() {
    const {
        campaign,
        isDistributionChecked,
        requiredFieldChangeColor,
    } = useSelector((state: IAllStates) => state.contractInfluencer);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {checked} = event.target;
        dispatch(setDistributionCheck(checked));
    };
    return (
        <>
            <Row>
                <Col md={3}>
                    <CustomImageHandler photoPath="/tempAssets/img-organic-reach.png" altTag="Image"
                                        classes="w-100 default-radius img-cover "/>
                </Col>
                <Col md={9}>
                    <h5 className="mt-2 mt-md-0">{t('influencer.briefing_detail.organic_reach')}</h5>
                    <p>{t('influencer.briefing_detail.organic_reach_description')}</p>
                </Col>
                {campaign?.brandedContent &&
                    <>
                        <Col className="my-3" xs={12}>
                            <h6 className="mb-0">{t('influencer.offer_management.modal.branded_content')}</h6>
                        </Col>
                        <BrandedContent brandedContentData={campaign?.brandedContent}/>
                    </>
                }
                {campaign?.usageRights &&
                    <>
                        <Col className="my-3" xs={12}>
                            <h6 className="mb-0">{t('influencer.briefing_detail.usage_rights')}</h6>
                        </Col>
                        <UsageRights usageRightsData={campaign?.usageRights}/>
                    </>
                }
                <Col className="d-flex align-items-center mt-3" xs={12}>
                    <Checkbox className="mb-0" required id="checkbox" onChange={changeHandler} color="primary"
                              checked={isDistributionChecked}/>
                    <label htmlFor="checkbox"
                           className={`text-left cursor-pointer ${requiredFieldChangeColor ? 'text-danger' : 'text-muted'}`}
                           dangerouslySetInnerHTML={{__html: t('auth.register.brand.step1.terms')}}/>
                </Col>
            </Row>
        </>
    );
}
