import React, {lazy, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {useLocation} from 'react-router-dom';
import ForbiddenPage from '../../authentication/403';
import {ErrorBoundary} from '../../shared/components/ErrorBoundary';
import LoadingScreen from '../../shared/components/LoadingScreen';
import InfluencerProfile from '../influencer/profile/profile-view';
import {Layout} from '../Layout';
import {PackageOverview} from '../shared/packages';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../store/rootReducer';

const Brand = lazy(() => import('./index'));
const Storybook = lazy(() => import('../Storybook'));
const Campaign = lazy(() => import('./campaign'));
const CampaignBriefing = lazy(() => import('./campaign/briefing'));
const UserAccount = lazy(() => import('../shared/user'));
const BrandClient = lazy(() => import('./client/components/ClientOverview'));
const BrandSupplier = lazy(() => import('./supplier/SupplierOverview'));
const BrandContracts = lazy(() => import('./contracts/index'));

export function BrandRoutes() {
    const {pathname} = useLocation();
    const {company} = useSelector((states: IAllStates) => states.auth.user);
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Layout>
                <ErrorBoundary key={pathname}>
                    <Switch>
                        <Route key={1} path="/brand/dashboard" component={Brand}/>
                        <Route key={2} path="/brand/storybook" component={Storybook}/>
                        <Route key={3} path="/brand/campaign/" component={Campaign}/>
                        <Route key={4} path="/brand/create/campaign/" component={CampaignBriefing}/>
                        <Route key={5} path="/settings/" component={UserAccount}/>
                        {company?.package?.isShownToUser &&
                            <Route key={6} path="/brand/package-overview" component={PackageOverview}/>
                         }
                        <Route key={7} path="/brand/contracts" component={BrandContracts}/>
                        <Route key={8} path="/brand/client" component={BrandClient}/>
                        <Route key={9} path="/brand/supplier" component={BrandSupplier}/>
                        <Route path="/brand/influencer/profile/:id" component={InfluencerProfile}/>
                        <Route path="/403" component={ForbiddenPage}/>
                        <Redirect from="/" to="/brand/dashboard"/>
                    </Switch>
                </ErrorBoundary>
            </Layout>
        </Suspense>

    );
}
