import {TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Form, Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import registrationSlice from '../../../../store/register/brand/registrationSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {AuthService} from '../../../service';
import {StepperButtons} from '../../brand/components/StepperButtons';
import {IRegisterStepperProps} from './Step1';
import {IRegistrationStepTwoRequest} from '../../brand/dto/IRegistrationStepTwoRequest';

export const Step2 = ({stepIdNumber, handleBackStep}: IRegisterStepperProps) => {
    const savedData = useSelector((state: IAllStates) => state.employee);
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const token = useSearchQuery('token') as string;
    const utmCampaign = localStorage.getItem('utmCampaign') as string;

    const [confirmationToken, setConfirmationToken] = useState<IRegistrationStepTwoRequest>({
        confirmationToken: '',
        utmCampaign,
    });

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setConfirmationToken(prev => ({...prev, [name]: value}));
    };

    const handleNextStep = () => {
        AuthService.verifyUserData(token, confirmationToken).then(response => {
            if (response) {
                localStorage.removeItem('utmCampaign');
                history.push(`/brand/employee/register/${stepIdNumber + 1}?token=${response?.verificationUUID}`);
                SuccessToast(t('auth.register.brand.step2.successCode'));
            }
        }).catch(error => ErrorToast(error));

    };

    const resendVerify = () => {
        setIsLoading(true);
        AuthService.resetVerifyCode(savedData?.email).then(response => {
            SuccessToast(t('auth.register.brand.step1.pleaseCheckEmail'));
        }).catch(error => ErrorToast(error)).finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (!!token?.length) {
            AuthService.getUserDataById('registerEmployee', token).then(response => {
                dispatch(registrationSlice.actions.setRegisterUser(response));
            }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [token]);

    return (
        <Form className="w-100" onSubmit={(e) => {
            e.preventDefault();
            handleNextStep();
        }}>
            <div className="row justify-content-center">
                <div className="col-md-7">
                    <TextField
                        label="Code"
                        value={confirmationToken?.confirmationToken}
                        onChange={changeHandler}
                        name="confirmationToken"
                        variant="outlined"
                        size="small"
                        required
                        className="w-100 my-2"
                    />
                    <div className="d-flex align-items-center justify-content-between">
                    <span className="text-muted">
                        {t('auth.register.brand.step2.checkSpamFolder')}
                    </span>
                        <a className="d-flex align-items-center justify-content-center"
                           onClick={() => resendVerify()}>
                            {isLoading ? <>
                                Loading... <Spinner animation="border" size="sm" role="status" className="ml-1"/>
                            </> : t('auth.register.brand.step2.reSend')}
                        </a>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep} isBackHidden={true}
                                    handleBackStep={handleBackStep}/>
                </div>
            </div>
        </Form>
    );
};
