import React, {useEffect, useState} from 'react';
import CreateInvoice from './components/CreateInvoice';
import ArchiveInvoice from './components/ArchiveInvoice';
import {InfluencerInvoiceService} from './service';
import {ErrorToast} from '../../../utils/toasters';
import {Campaign} from '../../../model/campaign/Campaign';
import {useLocation} from 'react-router';
import usePagination from '../../../shared/hooks/usePagination';

export default function InfluencerInvoices() {
    const {state}: any = useLocation();

    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [archiveCampaigns, setArchiveCampaigns] = useState<Campaign[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
    const {filterParams, handlePages, setFilterParams} = usePagination({});

    useEffect(() => {
        const getInvoice = async () => {
            InfluencerInvoiceService.getInvoiceCampaigns({perPage: 0})
                .then((response) => {
                    setCampaigns(response.data);
                    if (!!state?.offerId) {
                        setSelectedCampaign(() =>
                            response.data.find((el: Campaign) => el.offers?.[0]?.id! === state.offerId),
                        );
                    }
                })
                .catch((error) => ErrorToast(error));
        };
        getInvoice();
    }, [filterParams]);

    useEffect(() => {
        const getInvoiceArchive = async () => {
            InfluencerInvoiceService.getInvoiceArchive({
                ...filterParams,
                perPage: 12,
            })
                .then((response) => {
                    setArchiveCampaigns(response.data);
                    setCount(response.count)
                    setTotalPages(Math.ceil(response.count / response.perPage));
                })
                .catch((error) => ErrorToast(error));
        };
        getInvoiceArchive();
    }, [filterParams]);

    return (
        <div className="mb-5 pb-2">
            <CreateInvoice
                campaigns={campaigns}
                selectedCampaign={selectedCampaign}
                setSelectedCampaign={setSelectedCampaign}
                setFilterParams={setFilterParams}
                filterParams={filterParams}
            />
            <ArchiveInvoice
                campaigns={archiveCampaigns}
                totalPages={totalPages}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                handlePages={handlePages}
                count={count}
            />
        </div>
    );
}
