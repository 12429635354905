import React from 'react';
import {useTranslation} from 'react-i18next';
import {ICampaignOverview} from '../../../../../model/briefing/overview/ICampaignOverview';
import {ContractCard} from './ContractCard';
import {Col, Row} from 'react-bootstrap';

interface IContractListProps {
    campaigns?: ICampaignOverview[];
}

export function ContractList({campaigns}: IContractListProps) {
    const {t} = useTranslation();
    return (
        <>
            <div className="my-2">
                <h4>{t('influencer.dashboard.long_term_contracts')}</h4>
                <Row>
                    {campaigns?.map((campaign) => (
                        <Col
                            key={campaign?.id}
                            className="my-2 pl-0 col-12 col-md-6 col-lg-4"
                        >
                            <ContractCard campaign={campaign}/>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
}