import React, {useEffect, useState} from 'react';
import {ICampaignOverview} from '../../../../model/briefing/overview/ICampaignOverview';
import {useHistory, useParams} from 'react-router-dom';
import {IParams} from '../../../../model/shared/IParams';
import {useTranslation} from 'react-i18next';
import {Card, Col, Row} from 'react-bootstrap';
import {AgencyService} from '../../service';
import {ErrorToast} from '../../../../utils/toasters';
import {AgencyBriefingDetailCard} from './components/AgencyBriefingDetailCard';
import {BriefingDetailLeftCard} from '../../../influencer/campaign/briefing/components/BriefingDetailLeftCard';
import {User} from '../../../../model/user/User';
import {setSelectMatchedUser as setSelectMatchedUserContract} from '../../../../store/agency/agencyContractSlice';
import {setSelectMatchedUser} from '../../../../store/agency/agencyOfferSlice';
import {useDispatch} from 'react-redux';
import {AgencyInfluencerOfferModal} from '../../offer/modal';
import {AcceptedOfferCard} from './components/AcceptedOfferCard';
import {IOfferWithCampaign} from '../../../influencer/profile/profile-view';
import {AgencyBriefingContractDetailCard} from './components/AgencyBriefingContractDetailCard';
import {OfferStatusEnum} from '../../../../enum/OfferStatusEnum';
import {
    BriefingContractDetailTopCard
} from '../../../influencer/campaign/briefing/components/BriefingContractDetailTopCard';
import {BriefingDetailTopCard} from '../../../influencer/campaign/briefing/components/BriefingDetailTopCard';
import {AgencyInfluencerContractModal} from '../../contract-modal/AgencyInfluencerContractModal';
import {AcceptedContractCard} from '../../../influencer/campaign/briefing/components/AcceptedContractCard';
import {
    LinkedCampaignsCard as LinkedCampaignsCardTwo
} from '../../../influencer/campaign/briefing/components/LinkedCampaignsCard';
import {LinkedCampaignsCard} from '../../../brand/campaign/briefing/components/shared/LinkedCampaignsCard';

export function AgencyBriefingDetails() {
    const [currentInformations, setCurrentInformations] = useState<ICampaignOverview>();
    const [updeteState, setUpdateSate] = useState(false);
    const [isOfferLongTerm, setIsOfferLongTerm] = useState(false);
    const [showOfferModal, setShowOfferModal] = useState(false);
    const [showContractModal, setShowContractModal] = useState(false);
    const isLongTermCampaign = currentInformations?.isLongTerm;
    const {id} = useParams<IParams>();
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        AgencyService.getBriefingDetails(id)
            .then((response) => {
                setCurrentInformations(response);
            }).catch((error) => ErrorToast(error));
    }, [updeteState]);

    const openOfferModal = (user?: User, setLongTerm?: boolean) => {
        setIsOfferLongTerm(setLongTerm ?? false);
        dispatch(setSelectMatchedUser(user));
        setShowOfferModal(true);
    };
    const openContractModal = (user?: User) => {
        // setIsOfferLongTerm(setLongTerm ?? false);
        dispatch(setSelectMatchedUserContract(user));
        setShowContractModal(true);
    };

    const openBriefing = (id: string) => {
        history.push(`/agency/campaign/${id}/overview`);
    };

    return (
        <>
            <AgencyInfluencerOfferModal show={showOfferModal}
                                        setShowModal={setShowOfferModal}
                                        updateList={setUpdateSate}
                                        campaignId={id}
                                        isLongTerm={isOfferLongTerm}
            />
            <AgencyInfluencerContractModal show={showContractModal}
                                           setShowModal={setShowContractModal}
                                           updateList={setUpdateSate}
                                           campaignId={id}
            />
            {isLongTermCampaign ?
                <Row className="mb-4 justify-content-between align-items-center">
                    <Col className="d-none d-md-flex" md={6} xl={4}>
                        <h4>
                            {t('brand.campaign.book.contract.long_term')}
                        </h4>
                    </Col>
                    <Col md={6} xl={4}>
                        {currentInformations?.contracts?.some(item => item.status === OfferStatusEnum.ACCEPTED || item.status === OfferStatusEnum.COMPLETED) &&
                            <BriefingContractDetailTopCard briefingHeader={currentInformations?.briefingHeader}/>
                        }
                    </Col>
                </Row>
                :
                <>
                    {currentInformations?.offers?.some(item => item.status === OfferStatusEnum.ACCEPTED || item.status === OfferStatusEnum.COMPLETED) &&
                        <BriefingDetailTopCard isAgencySide={true} campaign={currentInformations}
                                               briefingHeader={currentInformations?.briefingHeader}/>
                    }
                </>
            }
            <Row className="flex-column-reverse flex-md-row">
                <Col md={8}>
                    <BriefingDetailLeftCard currentInformations={currentInformations}/>
                </Col>
                <Col md={4}>
                    {isLongTermCampaign ? <AgencyBriefingContractDetailCard
                        currentInformations={currentInformations}
                        openInitialContract={openContractModal}
                    /> : <AgencyBriefingDetailCard
                        currentInformations={currentInformations}
                        openInitialOffer={openOfferModal}
                    />}
                    {!!currentInformations?.parent?.contracts?.length &&
                        <div style={{maxHeight: '350px'}} className="my-3 overflow-auto">
                            <h6 className="border-bottom pb-2 mb-3">{t('influencer.briefing_detail.long_term_contract')}</h6>
                            <LinkedCampaignsCard key={currentInformations?.parent.id}
                                                 campaign={currentInformations?.parent}
                                                 openBriefingAction={openBriefing}
                            />
                        </div>
                    }

                    {!!currentInformations?.offers?.length &&
                        <div style={{maxHeight: '350px'}} className="my-3 overflow-auto">
                            {/*<h6 className="border-bottom pb-2 mb-3">{t('agency.accepted_contracts')}</h6>*/}
                            <h6 className="border-bottom pb-2 mb-3">Accepted offers</h6>
                            {currentInformations?.offers?.map(offer => (
                                <AcceptedOfferCard key={offer.id} offer={offer as IOfferWithCampaign}
                                                   openOfferAction={openOfferModal}/>
                            ))}
                        </div>
                    }
                    {!!currentInformations?.contracts?.length &&
                        <div style={{maxHeight: '350px'}} className="my-3 overflow-auto">
                            <h6 className="border-bottom pb-2 mb-3">{t('agency.accepted_contracts')}</h6>
                            {currentInformations?.contracts?.map(contract => (
                                <AcceptedContractCard key={contract.id} contract={contract}
                                                      openContractAction={openContractModal}/>
                            ))}
                        </div>
                    }
                    {!!currentInformations?.negativeOffers?.length &&
                        <div style={{maxHeight: '350px'}} className="my-3 overflow-auto">
                            {/*<h6 className="border-bottom pb-2 mb-3">{t('agency.declined_contracts')}</h6>*/}
                            <h6 className="border-bottom pb-2 mb-3">Declined offers</h6>
                            {currentInformations?.negativeOffers?.map(offer => (
                                <AcceptedOfferCard key={offer.id} offer={offer as IOfferWithCampaign}
                                                   openOfferAction={openOfferModal}/>
                            ))}
                        </div>
                    }
                    {(!!currentInformations?.linkedCampaigns?.length && isLongTermCampaign) &&
                        <div style={{maxHeight: '350px'}} className="my-3 overflow-auto">
                            <h6 className="border-bottom pb-2 mb-3">{t('brand.campaign.dashboard.linked-campaigns.linked')}</h6>
                            {currentInformations?.linkedCampaigns?.map(campaign => (
                                <LinkedCampaignsCardTwo key={campaign.id} campaign={campaign}
                                                        openBriefingAction={openBriefing}/>
                            ))}
                        </div>
                    }
                </Col>
            </Row>
        </>
    );
}
