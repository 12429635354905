import React, {useState} from 'react';
import {Button, Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Campaign} from '../../../../../model/campaign/Campaign';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import EmptyState from '../../../../../shared/components/placeholder/EmptyState';
import {checkOfferType, truncateString} from '../../../../../shared/functions/Functions';
import {MessagesIcon, PlannerIcon, ReportingIcon} from '../../../../../shared/Icons';
import {priceSplitter} from '../../../../../utils/helpers/priceSplitter';
import {InfluencerOfferModal} from '../../../offer/modal/offer-modal/InfluencerOfferModal';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {UserRoles} from '../../../../../enum/UserRoles';
import {gtmService} from '../../../../../utils/gtm.service';
import {AgencyInfluencerOfferModal} from '../../../../agency/offer/modal';
import {setSelectMatchedUser} from '../../../../../store/agency/agencyOfferSlice';
import {User} from '../../../../../model/user/User';
import {IOfferWithCampaign} from '../../../profile/profile-view';

interface Props {
    offers: IOfferWithCampaign[];
}

const TableViewOffer = ({offers}: Props) => {
    const history = useHistory();
    const {t} = useTranslation();
    const [campaignId, setCampaignId] = useState(0);
    const [isLongTermOffer, setIsLongTermOffer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAgencyModal, setShowAgencyModal] = useState(false);
    const dispatch = useDispatch();
    const {
        user: {
            userType,
        }
    } = useSelector((reduxState: IAllStates) => reduxState.auth);
    const {selectedInfluencer} = useSelector((reduxState: IAllStates) => reduxState.agency);

    const isAgency = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles);

    const navigateHandler = (route: string, campaignId: number) => {
        history.push(route, {
            campaignId: campaignId
        })
    }

    const openOfferModal = (campaignId: number, isLongTerm: boolean) => {
        setCampaignId(campaignId);
        setIsLongTermOffer(isLongTerm);
        if (isAgency) {
            setShowAgencyModal(true);
            dispatch(setSelectMatchedUser(selectedInfluencer));
        } else {
            setShowModal(true);
        }
    };

    const navigateToCampaignOverview = (id: number) => {
        isAgency ?
            history.push(`/agency/campaign/${id}/overview`) :
            history.push(`/influencer/campaign/${id}/overview`);
        gtmService('select_content');
    };


    return (
        <>
            <InfluencerOfferModal show={showModal} setShowModal={setShowModal} campaignId={String(campaignId)} isLongTerm={isLongTermOffer}
                                  updateList={() => {
                                  }}/>
            <AgencyInfluencerOfferModal show={showAgencyModal} setShowModal={setShowAgencyModal} isLongTerm={isLongTermOffer}
                                        campaignId={String(campaignId)}/>

            <div className="myCampaigns">
                <Table responsive>
                    <thead>
                    <tr className="text-center">
                        <th>{t('general.campaign')}</th>
                        <th>{t('general.brand')}</th>
                        <th>{t('influencer.explore.contentPieces')}</th>
                        <th>{t('brand.campaign.book.offer.modal.price')}</th>
                        <th>{t('general.status')}</th>
                        <th>{t('general.offers')}</th>
                        <th>To Dos</th>
                    </tr>
                    </thead>
                    <tbody>
                    {offers?.map((offer) => (<tr key={offer?.id}>
                        <td className="text-left cursor-pointer"
                            onClick={() => navigateToCampaignOverview(offer?.campaign?.id)}>
                            <div className="d-flex align-items-center cursor-pointer pr-3 position-relative overflow-hidden">
                                {(offer?.isLongTerm || offer?.isOnTop || offer?.isAddonsOnly) &&
                                  <div className="diagonal-text">
                                    <span className="diagonal-text-sm">
                                        {t(`brand.campaign.briefing.overview_card.${checkOfferType(offer?.isLongTerm, offer?.isOnTop, offer?.isAddonsOnly)}`)}
                                    </span>
                                  </div>
                                }

                                <CustomImageHandler
                                    photoPath={offer?.campaign?.campaignImages?.[0]?.path}
                                    altTag="Campaign Logo"
                                    classes="mr-2 default-radius img-cover"/>
                                <h6 className="font-weight-bold">
                                    {truncateString(offer?.campaign?.title ?? '-', 30)}
                                </h6>
                            </div>
                        </td>
                        <td>
                            {offer?.campaign?.company?.displayName ?? '-'}
                        </td>
                        <td>
                            {offer?.totalNbPosts ?? 0}
                        </td>
                        <td className="euro-symbol">
                            {priceSplitter(Math.round(offer?.price ?? 0))}
                        </td>
                        <td>
                    <span className={`status ${offer?.campaign?.status}`}>
                        {t(`general.campaign_status.${offer?.campaign?.status ?? offer?.status}`)}
                    </span>
                        </td>
                        <td>
                            <Button variant="outline-primary"
                                    onClick={() => openOfferModal(offer?.campaign?.id, offer.isLongTerm)}>
                                {t('general.button.show')}
                            </Button>
                        </td>
                        <td className="w-25">
                            <div className="d-flex align-items-center justify-content-between">
                                <div
                                    onClick={() => navigateHandler(isAgency ? '/agency/planner' : '/influencer/planner', offer?.campaign?.id)}
                                    className="cursor-pointer card py-3 d-flex align-items-center flex-column justify-content-center position-relative">
                                    <PlannerIcon width="20" height="20" color="#9ea5ab"/>
                                    <span className="font-10">
                                {t('influencer.campaign.tabs.planner')}
                            </span>
                                    {offer?.redDots?.plannerDot &&
                                        <div id="bullet" className="offer-bullet dashboard-dot"/>}

                                </div>
                                <div
                                    onClick={() => navigateHandler(isAgency ? '/agency/reporting' : '/influencer/reporting', offer?.campaign?.id)}
                                    className="cursor-pointer card py-3 mx-2 d-flex align-items-center flex-column justify-content-center position-relative">
                                    <ReportingIcon width="20" height="20" color="#9ea5ab"/>
                                    <span className="font-10">
                                {t('influencer.campaign.tabs.reporting')}
                            </span>
                                    {offer?.redDots?.reportingDot &&
                                        <div id="bullet" className="offer-bullet dashboard-dot"/>}

                                </div>
                                <div
                                    onClick={() => navigateHandler(isAgency ? '/agency/messages' : '/influencer/messages', offer?.campaign?.id)}
                                    className="cursor-pointer card py-3 d-flex align-items-center flex-column justify-content-center position-relative">
                                    <MessagesIcon width="20" height="20" color="#9ea5ab"/>
                                    <span className="font-10">
                                {t('influencer.campaign.tabs.messages')}
                            </span>
                                    {offer?.redDots?.messagesDot &&
                                        <div id="bullet" className="offer-bullet dashboard-dot"/>}
                                </div>
                            </div>
                        </td>
                    </tr>))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default TableViewOffer;
