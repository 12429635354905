import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {truncateString} from '../../../../shared/functions/Functions';
import {ISelectedEvent} from '../../../influencer/reporting/components/ReportingCampaignCard';

interface Props {
    selectedOfferItem: ISelectedEvent;
}

const ReportingHeader = ({selectedOfferItem}: Props) => {
    const {t} = useTranslation();
    const isLongTerm = selectedOfferItem?.offerItem?.offer?.isLongTerm;
    const isAddonsOnly = selectedOfferItem?.offerItem?.offer?.isAddonsOnly;
    return (
        <Link to={`/agency/campaign/${selectedOfferItem?.campaign?.id}/overview`}
              className="col-12 border-bottom my-3 pb-2 d-flex align-items-center justify-content-between flex-column flex-md-row">
            <div
                className="d-flex align-items-center justify-content-between w-100 flex-column flex-md-row pb-2 pb-md-0">
                <div className="d-flex align-items-center w-100">
                    <CustomImageHandler
                        altTag="Company logo"
                        classes="middle-rounded-logo cursor-pointer"
                        photoPath={selectedOfferItem?.campaign?.campaignImages?.[0]?.thumbnail as string}
                    />

                    <div className="ml-3">
                        <h6 className="mb-0 d-none d-md-block text-dark">
                            {selectedOfferItem?.campaign?.title ?? '-'}
                        </h6>
                        <h6 className="mb-0 d-md-none text-dark">
                            {truncateString(selectedOfferItem?.campaign?.title ?? '-', 40)}
                        </h6>
                        <div className="d-flex align-items-center">
                            {selectedOfferItem?.event?.status && <div className="d-flex align-items-center mt-1">
                                <div
                                    className={`status ${selectedOfferItem?.event?.status} w-fit-content text-dark`}>
                                    {selectedOfferItem?.event?.status}
                                </div>
                            </div>}
                            {isLongTerm &&
                                <div className={'custom-badge long-term ml-1 text-dark'}>
                                    {t(`brand.campaign.briefing.overview_card.linked`)}
                                </div>}
                            {isAddonsOnly &&
                                <div className={'custom-badge additional ml-1 text-dark'}>
                                    {t('influencer.offer_management.modal.additional')}
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ReportingHeader;
