const style = () => {  
  return `
    <style>
        @media print { 
          @page {
            margin: 0px;
          }
        }

        #pdf tfoot td {
          padding: 0px !important;
        }

        #pdf .avoid-brake {
          page-break-inside: avoid;
        }

        #pdf .description p:last-child {
          display: none;
        }

        #pdf .table-pricing td, .table-pricing th {
          font-size: 14px;
          text-align: center;
        }

        #pdf p {
          margin: 0;
          font-size: 14px;
        }

        #pdf li {
          font-size: 14px;
        }

        #pdf h4 {
          font-weight: 600;
        }

        #pdf footer {
          height: 100%;
          page-break-after: always;
          margin-top: 18px;
          width: 100%;
          padding: 20px 0px;
          border-top: 1px solid black;
        }

        #pdf header {
          margin-bottom: 18px;
          width: 100%;
          padding: 24px;
          border-bottom: 1px solid black;
        }

        #pdf .template-body {
          padding: 0px 24px 0px 24px;
        }     

        #pdf .template {
          padding: 120px 0px 150px 0px;
        }

        #pdf body {
          margin: 0px;
          font-family: "Montserrat", sans-serif;
          background: white;
        }

        #pdf .template-body {
          padding: 0px 24px 0px 24px;
        }

        #pdf .d-flex {
          display: flex;
        }

        #pdf .justify-content-between {
          justify-content: space-between;
        }

        #pdf .align-items-center {
          align-items: center;
        }

        #pdf .w-maxContent {
          width: max-content;
        }

        #pdf .w-100 {
          width: 100%;
        }

        #pdf .pt-1 {
          padding-top: 4px;
        }

        #pdf .pt-2 {
          padding-top: 8px;
        }

        #pdf .pt-3 {
          padding-top: 12px;
        }

        #pdf .pt-4 {
          padding-top: 16px;
        }

        #pdf .pt-5 {
          padding-top: 20px !important;
        }

        #pdf .py-1 {
          padding: 4px 0px;
        }

        #pdf .py-2 {
          padding: 8px 0px;
        }

        #pdf .m-0 {
          margin: 0;
        }

        #pdf .ml-1 {
          margin-left: 4px;
        }

        #pdf .ml-2 {
          margin-left: 8px;
        }

        #pdf .mr-1 {
          margin-right: 4px;
        }

        #pdf .mr-2 {
          margin-right: 8px;
        }

        #pdf .mb-1 {
          margin-bottom: 4px;
        }

        #pdf .mb-2 {
          margin-bottom: 8px;
        }

        #pdf .mb-3 {
          margin-bottom: 12px;
        }
        
        #pdf .mt-0 {
          margin-top: 0px;
        }

        #pdf .mt-1 {
          margin-top: 4px;
        }

        #pdf .mt-2 {
          margin-top: 8px;
        }

        #pdf .mt-3 {
          margin-top: 12px;
        }

        #pdf .mt-4 {
          margin-top: 16px;
        }

        #pdf .mt-5 {
          margin-top: 20px;
        }

        #pdf .mt-10 {
          margin-top: 40px;
        }
         
        #pdf .pt-10 {
          padding-top: 40px;
        }

        #pdf .border-top {
          border-top: 1px solid black;
        }

        #pdf .border-bottom {
          border-bottom: 1px solid black;
        }

        #pdf .euro-symbol::before {
          content: "€ ";
          font-weight: 400;
        }

        #pdf .border-box {
          box-sizing: border-box;
        }

        #pdf .country-flag {
          width: 26px;
          height: 26px;
        }

        #pdf .text-muted {
          opacity: 0.5;
        }

        #pdf .font-weight-normal {
          font-weight: 500;
        }

        #pdf .font-weight-bold {
          font-weight: 600;
        }

        #pdf .font-weight-bolder {
          font-weight: 700;
        }

        .text-upper {
          text-transform: uppercase;
        }

        .text-left { 
          text-align: left; 
        }

        .text-right {
          text-align: right; 
        }
        
        .text-center { 
          text-align: center; 
        }
        
        #pdf .align-top {
            vertical-align: top;
        }
        
        #pdf .align-middle {
            vertical-align: middle;
        }
        
        #pdf .align-bottom {
            vertical-align: bottom;
        }
        
        #pdf .align-text-bottom {
            vertical-align: text-bottom;
        }
        
        #pdf .align-text-top {
            vertical-align: text-top;
        }

        #pdf table td {
          padding: 4px;
        }
      </style>
      `;
  };

  export default style;