import React from 'react';
import {formatDate, getFirstPreviewImage} from '../../../../../shared/functions/Functions';
import {Button, Card, Col, Row} from 'react-bootstrap';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import Tooltip from '@mui/material/Tooltip';
import {ICampaignOverview} from '../../../../../model/briefing/overview/ICampaignOverview';
import {useTranslation} from 'react-i18next';
import {priceSplitter} from '../../../../../utils/helpers/priceSplitter';
import {User} from '../../../../../model/user/User';
import HtmlToPdf from '../../../../../shared/components/generate-pdf';

interface IBriefingDetailCardProps {
    currentInformations?: ICampaignOverview;
    openInitialOffer: (user?: User) => void;
}

export function AgencyBriefingDetailCard({currentInformations, openInitialOffer}: IBriefingDetailCardProps) {
    const {t} = useTranslation();

    return (
        <>
            <div className="position-relative">
                <Card className="mb-4 mt-md-0 overflow-hidden">
                    {!!currentInformations?.parent?.id &&
                      <div className="diagonal-text">
                            <span>
                                {t(`brand.campaign.briefing.overview_card.linked`)}
                            </span>
                      </div>
                    }
                    <Card.Body>
                        <Row>
                            <Col md={6} className="pl-md-0">
                                {getFirstPreviewImage('style_uploaded_image', currentInformations?.campaignImages)}
                            </Col>
                            <Col md={6}
                                 className="d-md-flex flex-column align-items-center align-items-md-start justify-content-between pl-md-0">
                                <h5 className="mb-2 mt-3 mt-md-0 w-100">
                                    {!!currentInformations?.isCharity && (
                                        <>
                                            <div className="d-inline-block">(</div>
                                            <div
                                                className="text-primary-dark d-inline-block">{t('general.button.barter_deal')}</div>
                                            <div className="d-inline-block">)</div>
                                        </>
                                    )} {currentInformations?.title ? currentInformations?.title : '-'}
                                </h5>
                                <div
                                    className="d-flex d-md-block justify-content-between mt-3 text-center text-md-left flex-wrap">
                                    <div className="col-6 col-md-12 p-md-0">
                                        <p className="mb-0 text-center text-primary text-md-left font-weight-semi-bold">
                                            {currentInformations?.postingDeadline ? formatDate(currentInformations?.postingDeadline as string) : 'DD.MM.YYYY.'}
                                        </p>
                                        <p>
                                            {t('influencer.offer_management.modal.reporting_deadline')}
                                        </p>
                                    </div>
                                    <div className="px-2 p-md-0 col-6 col-md-12">
                                        <p className="mb-0 text-primary text-center text-md-left font-weight-semi-bold">
                                            {currentInformations?.date ? formatDate(currentInformations?.date as string) : 'DD.MM.YYYY.'}
                                        </p>
                                        <p>
                                            {t('brand.campaign.briefing.application_deadline')}
                                        </p>
                                    </div>
                                    <div className="col-12 p-md-0">
                                        <p className="mb-0 text-primary font-weight-semi-bold">{currentInformations?.publishFrom ?
                                            formatDate(currentInformations?.publishFrom as string) : 'DD.MM.YYYY.'} -
                                            {currentInformations?.publishTo ? formatDate(currentInformations?.publishTo as string) : ' DD.MM.YYYY.'}
                                        </p>
                                        <p className="mb-0">
                                            {t('brand.campaign.briefing.publication_period')}
                                        </p>
                                    </div>
                                </div>
                            </Col>

                            <Row className="justify-content-between mt-md-4 mt-3 align-items-end">
                                <Col xs={6} md={4}
                                     className="px-md-1 d-flex flex-column align-items-center text-center">
                                    <div className="d-flex align-items-center">
                                        <h6 className="mb-0 text-primary">
                                            {currentInformations?.campaignGoal?.title ? currentInformations?.campaignGoal?.title : '-'}
                                        </h6>
                                    </div>
                                    <p className="my-2 mb-3 mb-md-2">
                                        {t('brand.campaign.briefing.campaign_goals')}
                                    </p>
                                </Col>
                                <Col xs={6} md={4}
                                     className="px-md-1 d-flex flex-column align-items-center text-center px-2">
                                    <h6 className="mb-0 text-primary">
                                        {currentInformations?.company?.displayName ?? '-'}
                                    </h6>
                                    <p className="my-2 mb-3 mb-md-2">
                                        {t('brand.campaign.briefing.overview_card.brand')}
                                    </p>
                                </Col>
                                {!!currentInformations?.offers.length &&
                                <Col xs={6} md={4}
                                     className="px-md-1 d-flex flex-column align-items-center text-center">
                                    {currentInformations?.isCharity ? <p className="my-2">-</p> :
                                        <>
                                            <h6 className="mb-0 text-primary">
                                                <div className="euro-symbol text-primary font-weight-bold">
                                                    {priceSplitter(currentInformations?.offers?.reduce((acc, item) => acc + (item?.price), 0) ?? 0)}
                                                </div>
                                            </h6>
                                            <p className="my-2">
                                                {t('influencer.briefing_detail.budget')}
                                            </p>
                                        </>}
                                </Col>
                                }
                                <Col xs={6} md={4}
                                     className={`${!!currentInformations?.offers.length && 'my-md-2'} px-md-1 d-flex flex-column align-items-center`}>
                                    <div className="d-none d-md-flex align-items-center">
                                        {currentInformations?.mainPlatforms?.length ? currentInformations?.mainPlatforms?.slice(0, 2)?.map(platform => (
                                            <div className="mx-1" key={platform?.id}>
                                                <CustomImageHandler
                                                    photoPath={platform?.path as string}
                                                    altTag={`${platform?.id}`}/>
                                            </div>
                                        )) : '-'}
                                        {(currentInformations?.mainPlatforms?.length ?? 0) > 2 &&
                                            <Tooltip id="white-tooltip" title={<>
                                                {currentInformations?.mainPlatforms?.slice(2)?.map(platform => (
                                                    <div className="mx-1 my-2" key={platform?.id}>
                                                        <CustomImageHandler
                                                            photoPath={platform?.path as string}
                                                            altTag={`${platform?.id}`}/>
                                                    </div>
                                                ))}
                                            </>} placement="right" className="cursor-pointer">
                                                <span className="icon-round bg-dark font-weight-semi-bold"
                                                      style={{width: 33}}>
                                                    + {(currentInformations?.mainPlatforms?.length ?? 0) - 2}
                                                </span>
                                            </Tooltip>
                                        }
                                    </div>
                                    <div className="d-flex d-md-none flex-wrap" style={{gap: '4px'}}>
                                        {currentInformations?.mainPlatforms?.length ? currentInformations?.mainPlatforms?.map(platform => (
                                            <div className="mx-1" key={platform?.id}>
                                                <CustomImageHandler
                                                    photoPath={platform?.path as string}
                                                    altTag={`${platform?.id}`}/>
                                            </div>
                                        )) : '-'}
                                    </div>

                                    <p className="my-2 text-center">
                                        {t('brand.campaign.briefing.overview_card.platforms')}
                                    </p>
                                </Col>
                                <Col xs={6} md={4} className="my-2 px-md-1 d-flex flex-column align-items-center px-2">
                                    <h6 className="mb-0 text-primary">
                                        {!!currentInformations?.offers?.length ?
                                            (currentInformations?.offers ? currentInformations?.offers.reduce((sum: number, item) => sum + item.nbPosts, 0) : '-')
                                            :
                                            (currentInformations?.platforms.reduce((sum: number, item) => sum + item.nbPosts, 0))
                                        }
                                    </h6>
                                    <p className="my-2 text-center">
                                        {t('influencer.briefing_detail.content_pieces')}
                                    </p>
                                </Col>
                                <Col xs={6} md={4} className="my-2 px-md-1 d-flex flex-column align-items-center">
                                    <h6 className="mb-0 d-flex text-primary flex-column text-center">
                                        {currentInformations?.brandedContent?.id ? 'Branded Content' : ''}
                                        {(!currentInformations?.brandedContent?.id && !currentInformations?.usageRights?.id) ? '-' : ''}
                                        <br/>
                                        {currentInformations?.usageRights?.id ? 'Usage rights' : ''}
                                    </h6>
                                    <p className="my-2 text-center">
                                        {t('influencer.briefing_detail.additional_items')}
                                    </p>
                                </Col>
                                <Col xs={12} className="p-0">
                                    <Button
                                        className="w-100"
                                        variant="primary"
                                        size="sm"
                                        disabled={!currentInformations?.initialUser?.id}
                                        onClick={() => openInitialOffer(currentInformations?.initialUser)}>
                                        {!currentInformations?.initialUser?.id ?
                                            t('general.button.no_matched_inf') :
                                            t('general.button.submit_offer')}
                                    </Button>
                                    <HtmlToPdf styleContent='mt-2' isBriefing/>
                                </Col>
                            </Row>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}
