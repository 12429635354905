import {useLocation} from 'react-router-dom';
import ForbiddenPage from '../../authentication/403';
import {ErrorBoundary} from '../../shared/components/ErrorBoundary';
import LoadingScreen from '../../shared/components/LoadingScreen';
import {Layout} from '../Layout';
import {Route, Switch, Redirect} from 'react-router';
import React, {Suspense} from 'react';
import ContentCreators from './content-creators';
import {AgencyInfluencers} from './influencers';
import UserAccount from '../shared/user';
import {AgencyExplore} from './explore';
import AgencyInvoices from './invoices';
import {AgencyOfferManagement} from './offer';
import {AgencyPlanner} from './planner';
import {AgencyReporting} from './reporting';
import {AgencyDashboard} from './dashboard';
import {AgencyAllCampaigns} from './campaigns';
import DeclinedOffers from '../influencer/declined-offers';
import {AgencyBriefingDetails} from './campaigns/briefing/AgencyBriefingOverview';
import InfluencerProfile from '../influencer/profile/profile-view';
import {AgencyMessages} from './messages';

export function AgencyRoutes() {
    const {pathname} = useLocation();
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Layout>
                <ErrorBoundary key={pathname}>
                        <Switch>
                            <Route path="/settings/" component={UserAccount}/>
                            <Route path="/agency/invoices" component={AgencyInvoices}/>
                            <Route path="/agency/influencers" component={AgencyInfluencers}/>
                            <Route path="/agency/dashboard" component={AgencyDashboard}/>
                            <Route path="/agency/explore" component={AgencyExplore}/>
                            <Route path="/agency/offers" component={AgencyOfferManagement}/>
                            <Route path="/agency/planner" component={AgencyPlanner}/>
                            <Route path="/agency/reporting" component={AgencyReporting}/>
                            <Route path="/agency/messages" component={AgencyMessages}/>
                            <Route path="/agency/campaigns" component={AgencyAllCampaigns}/>
                            <Route path="/agency/campaign/:id/overview" component={AgencyBriefingDetails}/>
                            <Route path="/agency/content-creators" component={ContentCreators}/>
                            <Route path="/agency/offer/declined" component={DeclinedOffers}/>
                            <Route path="/agency/influencer/profile/:id" component={InfluencerProfile}/>
                            <Route path="/agency/influencer/edit/:id" component={InfluencerProfile}/>
                            <Route path="/403" component={ForbiddenPage}/>
                            <Redirect from="/public/campaign/briefing/:uuid" to="/agency/explore"/>
                            <Redirect from="/" to="/agency/influencers"/>
                            <Route path="/agency/dashboard" component={AgencyInfluencers}/>
                            <Route path="/settings/" component={UserAccount}/>
                            <Route path="/403" component={ForbiddenPage}/>
                            <Redirect from="/" to="/agency/dashboard"/>
                        </Switch>
                </ErrorBoundary>
            </Layout>
        </Suspense>
    );
}