import React, {useEffect, useState} from 'react';
import {AgencyContractService} from '../../../service';
import {IAllStates} from '../../../../../store/rootReducer';
import {useSelector} from 'react-redux';
import {ErrorToast} from '../../../../../utils/toasters';
import ContractHistoryDetail from '../../../../brand/campaign/contracts/contract/modal/tabs/history/ContractHistoryDetail';
import {IContract} from '../../../../../model/shared/IContract';

export function AgencyContractHistoryTab() {
    const [historyContracts, setHistoryContracts] = useState<IContract[]>([]);
    const {
        campaignId,
        selectedMatchedUser,
        activeTabModal,
        campaign
    } = useSelector((state: IAllStates) => state.contractAgency);

    useEffect(() => {
        if (activeTabModal === 6 && campaign?.contractPlaceholder?.flags?.hasHistory) {
            AgencyContractService.getContractHistory(campaignId, selectedMatchedUser?.id)
                .then((response) => {
                    setHistoryContracts(response);
                }).catch(err => ErrorToast(err));
        }
        // eslint-disable-next-line
    }, [selectedMatchedUser]);

    return (
        <>
            {historyContracts.map(contract => (
                <ContractHistoryDetail contract={contract}/>
            ))
            }
        </>
    );
};