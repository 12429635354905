import React from 'react';
import {Table} from 'react-bootstrap';
import {priceSplitter} from '../../../../../../../../utils/helpers/priceSplitter';
import {useTranslation} from 'react-i18next';
import {IAdditionalItem} from '../../../../../../../../model/offer/IAdditionalItem';
import {countriesTooltip, getCountriesArray} from '../../../../../../../../shared/functions/Functions';

interface IHistoryAdditionalItemsProps {
    additionalItems?: IAdditionalItem[];
}

export function HistoryAdditionalItemsTable({additionalItems}: IHistoryAdditionalItemsProps) {
    const {t} = useTranslation();
    return (
        <Table responsive className={`table-head-bg mt-3`}>
            <thead className="table-head-bg">
                <tr>
                    <th>{t('brand.campaign.book.offer.modal.additional_items')}</th>
                    <th>{t('influencer.offer_management.modal.details')}</th>
                    <th>{t('brand.campaign.book.offer.modal.duration')}</th>
                    <th>{t('brand.campaign.book.offer.modal.location')}</th>
                    <th className="text-right">
                        {t('brand.campaign.book.offer.modal.price')}
                    </th>
                </tr>
            </thead>
            <tbody>
            {additionalItems?.map((item, index) => (
                <tr key={`${item.type}-${index}`}>
                    <td>
                        <span>
                            {t(`influencer.offer_management.modal.${item?.type}`)}
                        </span>
                    </td>
                    <td>{item?.typeDescription ? item?.typeDescription : t(`brand.campaign.book.offer.modal.${item?.type}`)}</td>
                    <td>{(item?.duration === '' || !item?.duration) ? '-' : t(`brand.campaign.briefing.step8.${item?.duration}`)} </td>
                    <td className="d-flex align-items-center">
                      {getCountriesArray(item.location, t)}
                      {item.location?.length > 1 ? countriesTooltip(item.location?.slice(1), t) : ''}
                    </td>
                    <td className="font-weight-bold d-flex align-items-center justify-content-end">
                        <p>{`${priceSplitter(Math.round(item?.price))}`} {`${(item?.price > 0) ? '€' : ''}`}</p>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
}
