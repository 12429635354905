import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Form, Modal, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {IPicture} from '../../../../model/shared/IPicture';
import {User} from '../../../../model/user/User';
import {ConfirmationModal} from '../../../../shared/components/alert/ConfirmationModal';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {iManageAttachments} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {InfluencerPlannerService} from '../../../influencer/planner/service';
import {reportingFields} from '../../../influencer/reporting/components/modals/components/ReportingBody';
import {IReportingUpdate, ISelectedEvent} from '../../../influencer/reporting/components/ReportingCampaignCard';
import {AgencyReportingService} from '../service';
import LeftReportingCard from './LeftReportingCard';
import ReportingHeader from './ReportingHeader';
import RightReportingCard from './RightReportingCard';


interface Props {
    isAdditional: boolean;
    isModalOpened: boolean;
    handleCloseModal: () => void;
    selectedOfferItem: ISelectedEvent;
    setSelectedOfferItem: React.Dispatch<SetStateAction<ISelectedEvent | undefined>>;
    setUpdateReports: React.Dispatch<SetStateAction<boolean>>;
}

const initUpdateState = {
    clicks: 0,
    campaignId: 0,
    comments: 0,
    eventId: 0,
    likes: 0,
    offerItemId: 0,
    reach: 0,
    saves: 0,
    shares: 0,
    postingDate: '',
    postingLink: '',
    stickerEngagements: 0,
    swipeUpLinks: 0,
    views: 0,
    watchTime: 0,
};

const AdditionalReportingModal = ({
                                      isModalOpened,
                                      handleCloseModal,
                                      selectedOfferItem,
                                      isAdditional,
                                      setSelectedOfferItem,
                                      setUpdateReports,
                                  }: Props) => {
    const {t} = useTranslation();
    const [updateData, setUpdateData] = useState<IReportingUpdate>(initUpdateState);
    const [selectedFile, setSelectedFile] = useState<IPicture>();
    // console.log(updateData.file)
    const [isAttachmentClicked, setIsAttachmentClicked] = useState(false);
    const handleSubmitData = async (event: React.FormEvent) => {
        event.preventDefault();
        handleCloseModal();
        const formData = new FormData();
        formData.append('body', JSON.stringify(updateData));
        if (!!updateData?.file?.length) {
            for (const item of updateData?.file) {
                formData.append('file', (item as string | Blob));
            }
        }
        if (!!updateData?.attachments?.length) {
            for (const item of updateData?.attachments) {
                formData.append('attachments', (item as string | Blob));
            }
        }
        AgencyReportingService.updateAdditionalReporting(formData, updateData?.offerItemId ?? 0).then(response => {
            handleCloseModal();
            setUpdateReports(prev => !prev);
            setSelectedOfferItem(undefined);
            setUpdateData(initUpdateState);
            SuccessToast(t('influencer.reporting.successUpdatedReporting'));
        }).catch(error => ErrorToast(error));
    }
    const getReportItemValue = () => {
        const storage: any = {};
        reportingFields.forEach(reportingKey => {
            storage[reportingKey] = (selectedOfferItem?.event as any)?.[reportingKey];
        });
        return storage;
    };
    // console.log(selectedOfferItem?.event)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, isNumber = true) => {
        const {name, valueAsNumber, value} = event.target;
        setUpdateData((prev) => ({...prev, [name]: isNumber ? valueAsNumber : value}));
    };

    const deleteImageHandler = async () => {
        if (isAttachmentClicked) {
            await InfluencerPlannerService.deleteAttachment(selectedFile?.id ?? 0).then(response => {
                SuccessToast(t('influencer.reporting.successDeleteImg'));
                setIsAttachmentClicked(false);
                setUpdateReports(prev => !prev);
                setSelectedOfferItem((prev: any) => ({
                    ...prev,
                    event: {
                        ...prev?.event,
                        attachments: prev?.event?.attachments?.filter((attachment: iManageAttachments) => attachment.id !== selectedFile?.id),
                    },
                }));
            }).catch(error => ErrorToast(error));
        } else {
            await AgencyReportingService.deleteReportingImage(selectedFile?.id ?? 0).then(response => {
                SuccessToast(t('influencer.reporting.successDeleteImg'));
                setUpdateReports(prev => !prev);
                setSelectedOfferItem((prev: any) => ({
                    ...prev,
                    event: {
                        ...prev?.event,
                        reportItems: [
                            {
                                reportImages: prev?.event?.reportItems?.[0]?.reportImages?.filter((img: IPicture) => img.id !== selectedFile?.id)
                            }
                        ]
                    },
                }));
            }).catch(error => ErrorToast(error));
        }

    };

    useEffect(() => {
        if (!!selectedOfferItem?.event?.id && isAdditional) {
            setUpdateData(prev => ({
                ...prev,
                campaignId: selectedOfferItem?.campaign?.id,
                eventId: selectedOfferItem?.event?.id,
                offerItemId: selectedOfferItem?.offerItem?.id,
                postingDate: selectedOfferItem?.event?.postingDate,
                postingLink: selectedOfferItem?.event?.postingLink,
                ...getReportItemValue(),
            }));
        }
        // eslint-disable-next-line
    }, [selectedOfferItem?.event?.id, isAdditional]);
    return (
        <>
            <Modal
                show={isModalOpened}
                centered
                size="xl"
                onHide={handleCloseModal}
                keyboard={false}
            >
                <Form onSubmit={handleSubmitData}>
                    <Modal.Header className={'py-0 row'}>
                        <ReportingHeader selectedOfferItem={selectedOfferItem}/>
                    </Modal.Header>
                    {selectedOfferItem?.event && <Modal.Body>
                        <Row>
                            <LeftReportingCard selectedOfferItem={selectedOfferItem}
                                               user={selectedOfferItem?.event?.user as User}
                                               postingLink={selectedOfferItem?.event?.postingLink}
                                               postingDate={selectedOfferItem?.event?.postingDate}/>
                            <RightReportingCard selectedOfferItem={selectedOfferItem}
                                                setSelectedOfferItem={setSelectedOfferItem}
                                                setUpdateData={setUpdateData}
                                                setIsAttachmentClicked={setIsAttachmentClicked}
                                                setSelectedFile={setSelectedFile as React.Dispatch<SetStateAction<IPicture>>}
                                                isFormDisabled={false}
                                                handleChange={handleChange}
                                                updateData={updateData}
                            />
                        </Row>
                    </Modal.Body>}
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={handleCloseModal}>
                            {t('general.button.cancel')}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t(`general.button.updateReport`)}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ConfirmationModal
                title={t('influencer.planner.deleteImage')}
                description={`${t('influencer.planner.deleteImageDesc')} ${selectedFile?.externalLink ?? selectedFile?.title ?? selectedFile?.originalName}`}
                action={deleteImageHandler}
                show={!!selectedFile?.id} closeModal={() => setSelectedFile(undefined)}
            />
        </>
    );
};

export default AdditionalReportingModal;
