import {useEffect, useState} from 'react';
import {AgencyService} from '../../app/agency/service';
import {User} from '../../model/user/User';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../store/rootReducer';

export const useAgencyCreators = () => {
    const [users, setUsers] = useState<User[]>();
    const [totalPages, setTotalPages] = useState(0);
    const [count, setCount] = useState(0);
    const [filterParams, setFilterParams] = useState({
        page: 1,
        perPage: 12,
        displayName: '',
    });
    const {updateInfluencers} = useSelector((state: IAllStates) => state.agency);

    useEffect(() => {
        AgencyService.getAgencyUsersHeader(filterParams)
            .then((response) => {
                setUsers(response.data);
                setTotalPages(Math.ceil(response.count / response.perPage));
                setCount(response.count);
            });
    }, [JSON.stringify(filterParams), updateInfluencers]);

    return {
        users,
        setCount,
        count,
        totalPages,
        filterParams,
        setFilterParams,
    };
};
