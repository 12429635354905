import React, {useEffect, useState} from 'react';
import {TextField} from '@material-ui/core';
import {Form, Spinner} from 'react-bootstrap';
import {StepperButtons} from '../../brand/components/StepperButtons';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {IRegisterStepperProps} from '../dto/IRegisterStepperProps';
import {AuthService} from '../../../service';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import {IAllStates} from '../../../../store/rootReducer';
import {setRegisterUser} from '../../../../store/register/influencer/influencerRegistrationSlice';
import {IRegistrationStepTwoRequest} from '../../brand/dto/IRegistrationStepTwoRequest';

export function InfluencerStep2({handleBackStep, stepIdNumber}: IRegisterStepperProps) {
    const [isLoading, setIsLoading] = useState(false);
    const utmCampaign = localStorage.getItem('utmCampaign') as string;
    const [confirmationToken, setConfirmationToken] = useState<IRegistrationStepTwoRequest>({
        confirmationToken: '',
        utmCampaign,
    });
    const influencerData = useSelector((state: IAllStates) => state.influencerRegistration);
    const token = useSearchQuery('token') as string;
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        AuthService.getInfluencerData(token).then(response => {
            dispatch(setRegisterUser(response));
            if (response?.isEnabled) {
                history.push(`${InternalRoutes.INFLUENCER_REGISTRATION}/${stepIdNumber + 1}?token=${token}`);
            }
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, []);

    const handleNextStep = () => {
        AuthService.verifyInfluencer(token, confirmationToken).then(response => {
            if (response) {
                history.push(`${InternalRoutes.INFLUENCER_REGISTRATION}/${stepIdNumber + 1}?token=${response?.verificationUUID}`);
                localStorage.removeItem('utmCampaign');
                dispatch(setRegisterUser(response));
                SuccessToast(t('auth.register.brand.step2.successCode'));
            }
        }).catch(error => ErrorToast(error));
    };
    const resendVerify = () => {
        setIsLoading(true);
        AuthService.resetVerifyCodeInfluencer(influencerData?.user?.email ?? '').then(response => {
            SuccessToast(t('auth.register.brand.step1.pleaseCheckEmail'));
        }).catch(error => ErrorToast(error)).finally(() => setIsLoading(false));
    };

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setConfirmationToken(prev => ({...prev, [name]: value}));
    };
    return (
        <>
            <Form className="w-100" onSubmit={(e) => {
                e.preventDefault();
                handleNextStep();
            }}>
                <div className="row justify-content-center">
                    <div className="col-md-7">
                        <TextField
                            label="Code"
                            onChange={changeHandler}
                            name="confirmationToken"
                            variant="outlined"
                            size="small"
                            required
                            className="w-100 my-2"
                        />
                        <div className="d-flex align-items-center justify-content-between">
                    <span className="text-muted">
                        {t('auth.register.brand.step2.checkSpamFolder')}
                    </span>
                            <a className="d-flex align-items-center justify-content-center"
                               onClick={resendVerify}>
                                {isLoading ? <>
                                    Loading... <Spinner animation="border" size="sm" role="status" className="ml-1"/>
                                </> : t('auth.register.brand.step2.reSend')}
                            </a>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep}
                                        isBackHidden={true}
                                        handleBackStep={handleBackStep}/>
                    </div>
                </div>
            </Form>
        </>
    );
};
