import React, {ChangeEvent, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import OfferPlatformTable from './OfferPlatformTable';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../../store/rootReducer';
import {OptionalItemsTable} from './OptionalItemsTable';
import {AdditionalItemsTable} from './AdditionalItemsTable';
import {Form} from 'react-bootstrap';
import _debounce from 'lodash/debounce';
import {setPersonalOfferDescription} from '../../../../../../../../store/brand/offerBrandSlice';
import {OfferHeader} from './OfferHeader';
import {DeclineOffer} from '../../../../../../../influencer/offer/modal/offer-modal/tabs/offer/DeclineOffer';
import {Totals} from './Totals';
import {getGrandTotal, getSubtotalAdditionalItems} from '../../../../../../../../shared/functions/Functions';
import {TotalTypes} from '../../../../../../../../enum/TotalTypes';
import {AdditionalItemToggle} from './AdditionalItemToggle';

export const Offer = () => {
    const {
        campaign,
        initialOffer,
        offerModalStatus,
        isTransparentPricing,
        isCharity,
        isEditable,
        canToggleAddons,
    } = useSelector((state: IAllStates) => state.offerBrand);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isNegotiate = offerModalStatus === 'negotiate';
    const feePercentage = Math.round((1 - Number(campaign?.feePercentage ?? 0.0)) * 100);
    const subTotalSum = initialOffer.isAddonsOnly ? getSubtotalAdditionalItems(initialOffer.distribution) : getGrandTotal(initialOffer.offerItems, initialOffer.distribution);
    const totalLiveFeeSum = Math.ceil(subTotalSum / (campaign?.feePercentage ?? 1)) - subTotalSum;
    const transparentGrandTotal = isNegotiate ? Math.ceil(subTotalSum / (campaign?.feePercentage ?? 1)) : campaign?.offers[0]?.totalWithFee ?? 0;
    const changeHandler = _debounce(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = event?.target?.value;
            dispatch(setPersonalOfferDescription(value))
        },
        300,
    );


    return (
        <>
            <DeclineOffer declineReason={campaign?.offers[0]?.declineReason}
                          declineComment={campaign?.offers[0]?.declineComment} status={campaign?.offers[0]?.status}/>
                <OfferHeader createdAt={campaign?.offers[0]?.createdAt ?? ''}
                             offerItemId={campaign?.offers[0]?.id ?? 0}
                             isEditable={isEditable}
                             isRequested={campaign?.offers[0]?.isRequested}
                             offerModalStatus={offerModalStatus}
                />
            {canToggleAddons &&
                <AdditionalItemToggle offerItemId={campaign?.spendingOffer?.id ?? 0}/>
            }
            <div className="p-2">
                {!initialOffer.isAddonsOnly &&
                    <OfferPlatformTable/>
                }
                <AdditionalItemsTable/>
                {(offerModalStatus === 'negotiate' && (campaign?.offerPlaceholder?.distribution && campaign?.offerPlaceholder?.distribution?.length > 0)) &&
                    <OptionalItemsTable/>
                }
                {(isTransparentPricing && !isCharity && !initialOffer.isAddonsOnly) &&
                    <div className="w-100 mt-2">
                        <Totals
                            title={TotalTypes.SUBTOTAL}
                            price={subTotalSum}
                            feePercentage={100 - feePercentage}
                        />
                    </div>
                }
                {(isTransparentPricing) ?
                    <div className="w-100 mt-2">
                        <Totals
                            title={TotalTypes.FEE}
                            price={offerModalStatus === 'negotiate' ? totalLiveFeeSum : campaign?.offers[0]?.feeAmount ?? 0}
                            feePercentage={feePercentage}
                            displayPlus={true}
                        />
                    </div> : <div className='my-3'></div>
                }
                {(isTransparentPricing && !isCharity) &&
                    <div className="w-100 mt-2">
                        <Totals
                            title={TotalTypes.GRAND}
                            price={transparentGrandTotal}
                            feePercentage={100}
                        />
                    </div>
                }
                {offerModalStatus === 'negotiate' &&
                    <>
                        <h6 className="mt-3">{t('influencer.offer_management.modal.add_personal_offer')}</h6>
                        <Form.Control
                            onChange={changeHandler}
                            defaultValue={initialOffer.personalOffer}
                            as="textarea"
                            name="personalOffer"
                            rows={3}
                            placeholder={t('influencer.offer_management.modal.add_personal_offer_placeholder')}/>
                    </>
                }
                {(offerModalStatus !== 'negotiate' && campaign?.offers[0]?.personalOffer) &&
                    <div className="mt-4">
                        <div>
                            <h5>{t('influencer.offer_management.modal.add_personal_offer')}</h5>
                        </div>
                        <h5 className="text-muted mb-0">{campaign?.offers[0]?.personalOffer}</h5>
                    </div>
                }
            </div>
        </>
    );
};

export default Offer;
