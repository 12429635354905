import {TextField} from '@material-ui/core';
import {Tabs} from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, {SetStateAction, useEffect, useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BiCopy} from 'react-icons/bi';
import {useSelector} from 'react-redux';
import {AccessRightEnum} from '../../../../../../../enum/AccessRightEnum';
import {CustomImageHandler} from '../../../../../../../shared/components/CustomImageHandler';
import {MessageBody} from '../../../../../../../shared/components/message/MessageBody';
import ToggleSwitch from '../../../../../../../shared/components/ToggleSwitch';
import {
    downloadFile,
    formatDate,
    getCorrectImageThumbnail,
    getExternalLink,
    hasAccessRights
} from '../../../../../../../shared/functions/Functions';
import {IAllStates} from '../../../../../../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../../../../../../utils/toasters';
import {ManageService} from '../../../service';
import {iManageList} from '../../dto/IManageCalendar';
import {statusesToHideApproveButton} from './CalendarUpdateModal';
import {a11yProps, TabPanel} from '../../../../../../../shared/components/MaterialTabsComponents';
import FilePlaceholder from '../../../../../../../shared/components/placeholder/FilePlaceholder';
import { Fancybox } from '../../../../../../../shared/components/Fancybox';

interface IProps {
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
    setCurrTab: React.Dispatch<SetStateAction<number>>;
    currTab: number;
    handleCopy: (val: string) => void;
    lastMessageRef: React.RefObject<HTMLInputElement>;
}

const CalendarManageRightCard = ({state, handleCopy, setState, lastMessageRef, setCurrTab, currTab}: IProps) => {
    const [locks, setLocks] = useState<{isStorylineLocked: boolean, isCaptionLocked: boolean}>({
        isStorylineLocked: false,
        isCaptionLocked: false,
    })
    const {user} = useSelector((states: IAllStates) => states.auth);
    const isCaptionHidden = !['instagram_story'].includes(state?.offerItem?.socialPlatform?.name);
    const {t} = useTranslation();
    const showInfluencerFeedback = !hasAccessRights(AccessRightEnum.INFLUENCER_FEEDBACK_HIDE);
    const showClientComments = hasAccessRights(AccessRightEnum.MANAGE_CAN_VIEW_CLIENT_COMMENTS);
    const handleChangeTab = (event: React.SyntheticEvent<Element, Event>, value: number) => {
        setCurrTab(value);
        setTimeout(() => {
            lastMessageRef?.current?.scrollIntoView({
                behavior: 'smooth',
            });
        }, 0);
    };
    const handleSwitch = (attachmentId: number, isApproved: boolean) => {
        const copy = state;
        const indexToReplace = copy?.attachments?.findIndex(attachment => attachment?.id === attachmentId);
        copy.attachments[indexToReplace] = {
            ...copy.attachments[indexToReplace],
            isApproved: !isApproved,
        };
        ManageService.updateFileApprovement(state?.id, copy?.attachments?.map(attachment => ({
            id: attachment?.id,
            isApproved: attachment?.isApproved,
        }))).then(response => {
            setState(response);
            SuccessToast(t('general.toasts.successUpdated'));
        }).catch(error => ErrorToast(error));
    };

    const handleLocks = (checked: boolean, lock: 'isStorylineLocked' | 'isCaptionLocked') => {   
        const service = lock === 'isStorylineLocked' ? ManageService.putLockStoryline(state?.id) : ManageService.putLockCaption(state?.id)
        service.then(() => {
            setState((prev) => ({...prev, [lock]: checked}));
            SuccessToast(t('general.toasts.successUpdated'));
        }).catch(error => ErrorToast(error));
    }

    useEffect(() => {
        setLocks({
            isStorylineLocked: state?.isStorylineLocked,
            isCaptionLocked: state?.isCaptionLocked
        })
    }, [state])
    
    return (
        <>
            <div className="my-3 ">
               <div className='d-flex align-items-center mb-2'>
                    <h6 className='w-100 mb-0'>Storyline</h6>
                    
                    <div>
                        <ToggleSwitch
                                onChange={(e: any) => handleLocks(e, 'isStorylineLocked')}
                                small={true}
                                id={`Lock Storyline`}
                                checked={locks?.isStorylineLocked}
                                disabled={statusesToHideApproveButton?.includes(state?.status)}
                            />
                    </div>
               </div>
               <div>
                    <TextField 
                        label="Storyline"
                        variant="outlined"
                        multiline
                        InputProps={{
                            endAdornment: (
                                <BiCopy className="copy-icon pr-1"
                                        onClick={() => handleCopy(state?.storyline ?? '')}/>
                            ),
                        }}
                        className="w-100"
                        size="small"
                        disabled={locks?.isStorylineLocked}
                        value={state?.storyline ?? ''}
                    />
                </div>
            </div>
            {isCaptionHidden && <>
                <div className="d-flex align-items-center">
                    <div className="d-md-flex align-items-center mt-3 mb-2 w-100">
                        <h6 className="mb-0">
                            {t('influencer.planner.caption')} -
                        </h6>
                        <div className="text-muted font-weight-normal ml-md-1 mt-1">
                            {t('influencer.planner.copyDescription')}
                        </div>
                    </div>
                        
                    <ToggleSwitch
                        onChange={(e: any) => handleLocks(e, 'isCaptionLocked')}
                        small={true}
                        id={`Lock Caption`}
                        checked={locks?.isCaptionLocked}
                        disabled={statusesToHideApproveButton?.includes(state?.status)}
                    />
                </div>
                <div>
                    <TextField label="Caption"
                            variant="outlined"
                            multiline
                            InputProps={{
                                endAdornment: (
                                    <BiCopy className="copy-icon pr-1"
                                            onClick={() => handleCopy(state?.caption ?? '')}/>
                                ),
                            }}
                            className="w-100"
                            size="small"
                            disabled={locks?.isCaptionLocked}
                            value={state?.caption ?? ''}/>
                </div>
            </>}

            <h6 className="mb-0 mt-3">
                {t('influencer.planner.contentFiles')}
            </h6>
            <Fancybox delegate='[data-fancybox="gallery-attachments"]'>
                <Row>
                    {!!state?.attachments?.length ? state?.attachments?.map((attachment, index) => {
                        return (
                            <Col md="6" className={`my-2 ${index % 2 !== 0 ? 'pr-0 pl-md-2 pl-0' : 'pr-md-2 pr-0 pl-0'}`}
                                key={attachment?.id}>
                                <Card className="h-100">
                                    <Card.Body className="d-flex align-items-center flex-row">
                                        {!!attachment?.externalLink ? (
                                            <a href={getExternalLink(attachment?.externalLink)} target='_blank' className="cursor-pointer">
                                                <CustomImageHandler
                                                    photoPath={getCorrectImageThumbnail(!!attachment?.externalLink, attachment?.originalName ?? '', attachment?.thumbPath ?? '', attachment?.path)}
                                                    altTag="Event"
                                                    thumbnailPath={attachment?.thumbnail}
                                                    placeholder={getCorrectImageThumbnail(!!attachment?.externalLink, attachment?.originalName ?? '', attachment?.thumbPath ?? '', attachment?.path)}
                                                    style={{width: 85, aspectRatio: '1/1'}}
                                                    classes="default-radius img-cover"
                                                />
                                            </a>
                                        ) : (
                                            <FilePlaceholder file={attachment} 
                                                hideText 
                                                classContent='default-radius'
                                                classImage='img-cover' 
                                                styleContent={{height: '85px', width: '85px'}}
                                                nameFancybox='attachments'
                                            />  
                                        )}
                                        <div className="ml-3 w-100">
                                            <a target="_blank" className="text-dark font-weight-semi-bold word-break"
                                            href={getExternalLink(attachment?.externalLink) ?? attachment?.path ?? '/icons/filePlaceholder.png'}
                                            rel="noreferrer">
                                                {t('general.file')} {index + 1}/{state?.attachments?.length} - <span
                                                className="text-muted">{state?.id}</span>
                                            </a>
                                            <p className="text-muted mb-0">{formatDate(attachment?.createdAt)}</p>
                                            {!!attachment?.externalLink ? (
                                                <a
                                                    href={getExternalLink(attachment?.externalLink)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t('general.externalLink')}
                                                </a>
                                            ) : (
                                                <span className="cursor-pointer text-primary"
                                                    onClick={() => downloadFile(attachment?.externalLink ?? attachment?.path ?? '/icons/filePlaceholder.png', attachment?.originalName)}>
                                                        {t('general.download')}
                                                </span>
                                            )}
                                            <div className="d-flex align-items-center justify-content-between">
                                                <p className="font-weight-semi-bold mb-0">{t('general.button.approve')}</p>
                                                <ToggleSwitch
                                                    onChange={() => handleSwitch(attachment?.id, attachment?.isApproved)}
                                                    small={true}
                                                    key={attachment?.id}
                                                    disabled={statusesToHideApproveButton?.includes(state?.status)}
                                                    id={`Attachment - ${attachment?.id}`}
                                                    checked={attachment?.isApproved}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    ) : <p>{t('influencer.planner.no_files_uploaded')}</p>}
                </Row>
            </Fancybox>
            <Box className="d-flex flex-column" sx={{height: '400px'}}>
                <Box className="mb-3">
                    <Tabs defaultValue={currTab} value={currTab} onChange={handleChangeTab}
                          aria-label="basic tabs example"
                          TabIndicatorProps={{style: {background: 'var(--primary)'}}}>
                        <Tab label="Influencer Feedback"
                             className={`${!showInfluencerFeedback && 'd-none'}`} {...a11yProps(0)} />
                        <Tab label="Management" className={`${!showClientComments && 'd-none'}`}{...a11yProps(1)} />
                    </Tabs>
                </Box>
                <div className="overflow-auto pr-2 w-100 h-100">
                    <TabPanel value={currTab} index={0}>
                        {!!state?.comments?.length ? state?.comments?.map((comment) => (
                            <MessageBody comment={comment as any} key={comment?.id} haveFiles={false}
                                         senderData={comment?.sentBy}
                                         isMyMessage={+comment?.sentBy?.id === +user?.id}/>
                        )) : <p>{t('influencer.planner.noComments')}</p>}
                    </TabPanel>
                    <TabPanel value={currTab} index={1}>
                        {!!state?.clientComments?.length ? state?.clientComments?.map((comment) => (
                            <MessageBody comment={comment as any} key={comment?.createdAt + comment.id}
                                         haveFiles={false}
                                         senderData={comment?.sentBy}
                                         isMyMessage={+comment?.sentBy?.id === +user?.id}/>
                        )) : <p>{t('influencer.planner.noComments')}</p>}
                    </TabPanel>
                    <div ref={lastMessageRef}/>
                </div>
            </Box>
        </>
    );
};

export default CalendarManageRightCard;
