import { ICampaignOverview } from "../../../../../model/briefing/overview/ICampaignOverview";
import { priceSplitter } from "../../../../../utils/helpers/priceSplitter";

const briefingCampaignPrices = (t: any, campaignOverview: ICampaignOverview, isClient: boolean) => {    
  return `
    <div class="pt-10 avoid-brake">
        <div>
            <div class="d-flex" style="gap: 28px">
                ${!isClient ? `
                    <div>
                        <h4 class="">${t("brand.campaign.briefing.planned_budget")}</h4>
                        <p class="font-weight-normal euro-symbol">${
                        priceSplitter(campaignOverview?.grossBudget ?? 0) ?? ""
                        }</p>
                    </div>
                ` : ''}
                
                ${!!campaignOverview?.brandedContent ?
                    `<div>
                        <h4>
                            ${t("brand.campaign.book.offer.modal.branded_content")}
                        </h4>
                        <p class="font-weight-normal euro-symbol">
                            ${priceSplitter(campaignOverview?.brandedContent?.budget ?? 0) ?? ""}
                        </p>
                    </div> `
                : ''}
                
                ${!isClient ? `
                    <div>
                        <h4>${t("brand.campaign.briefing.total_budget")}</h4>
                        <p class="font-weight-normal euro-symbol">${priceSplitter(
                        (campaignOverview?.brandedContent
                            ? +campaignOverview?.brandedContent?.budget
                            : 0) +
                            (campaignOverview?.grossBudget
                            ? +campaignOverview?.grossBudget
                            : 0) || 0
                        )}</p>
                    </div>
                ` : ''}

                <div>
                    <h4>${t(`brand.campaign.briefing.publishing`)}</h4>
                    <p class="font-weight-normal">
                        ${t(`brand.campaign.briefing.${
                            campaignOverview?.status === "published" ? "public" : "private"
                        }`)}
                    </p>
                </div>
            </div>
        </div>
    </div>
`;};

export default briefingCampaignPrices;