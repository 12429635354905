import React from 'react';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {IMyCampaignItem} from '../dto/IMyCampaigns';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';

export interface IInfluencerCampaignListProps {
    campaign: IMyCampaignItem;
    isContracts?: boolean;
}

export function InfluencerCampaignListItem({campaign, isContracts}: IInfluencerCampaignListProps) {
    const {title, id, campaignImages, company, offers, contracts} = campaign;
    const {t} = useTranslation();
    const history = useHistory();
    const goToOverview = () => {
        history.push(`/influencer/campaign/${id}/overview`);
    };
    const offer = offers?.[0] || contracts?.[0];
    const isLongTerm = offer?.isLongTerm;
    const isAddonsOnly = offer?.isAddonsOnly;
    const isOnTop = offer?.isOnTop;
    const hasBadge = offer?.isLongTerm || offer?.isAddonsOnly || offer?.isOnTop;

    return (
      <tr>
        <td className="text-left">
          <div onClick={goToOverview} className="d-flex align-items-center cursor-pointer pr-3">
            <CustomImageHandler photoPath={campaignImages?.[0]?.thumbnail as string}
                                altTag={campaignImages?.[0]?.title as string}
                                classes="mr-2 default-radius img-cover"/>
            <h6 className="font-weight-bold">{title}</h6>
          </div>
        </td>
        {!isContracts && (
          <td>
            {hasBadge && <div className={'custom-badge long-term mx-auto'}>
              {isLongTerm ? t(`brand.campaign.briefing.overview_card.linked`) : isOnTop ? t(`brand.campaign.briefing.overview_card.on_top`)
                : isAddonsOnly ? t(`brand.campaign.briefing.overview_card.addons_only`) : null
              }
            </div>
            }
          </td>
        )}
        <td className="text-primary font-weight-semi-bold">{company?.displayName ?? '-'}</td>
        <td className="font-weight-semi-bold">{(isAddonsOnly ? '-' : offer?.totalPostings) ?? 0}</td>
        <td className="font-weight-semi-bold euro-symbol"> {priceSplitter(Math.round(offer?.price ?? 0))}</td>
        <td>
          <span className={`status ${offer?.status}`}>
            {t(`general.offerStatus.${offer?.status}`)}
          </span>
        </td>
        <td>
          <Button onClick={goToOverview} className="mx-3" size="sm">
            {t('general.open')}
          </Button>
        </td>
      </tr>
    );
}
