import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {ICampaignOverview} from '../../../model/briefing/overview/ICampaignOverview';
import EmptyState from '../../../shared/components/placeholder/EmptyState';
import RocketModal from '../../../shared/components/RocketModal';
import {useSearchQuery} from '../../../shared/hooks/useSearchQuery';
import {OffersIcon} from '../../../shared/Icons';
import {ErrorToast} from '../../../utils/toasters';
import {OffersCardItem} from './components/OffersCardItem';
import {OffersFilter} from './components/OffersFilter';
import {OffersMobileTabs} from './components/OffersMobileTabs';
import {IOfferManagementFilter} from './dto/IOfferManagementFilter';
import {InfluencerOfferModal} from './modal/offer-modal/InfluencerOfferModal';
import {ManagementOfferService} from './service';
import {InfluencerContractModal} from './modal/contract-modal/InfluencerContractModal';
import {ContractCardItem} from './components/ContractCardItem';
import {ENotificationType} from '../../../model/campaign/Campaign';

interface ILocationProps {
    selected: 'to-do' | 'in-progress'
}

export function OfferManagement() {
    const {state} = useLocation();
    const history = useHistory();
    const [todoOffers, setTodoOffers] = useState<ICampaignOverview[]>([]);
    const [inProgressOffers, setInProgressOffers] = useState<ICampaignOverview[]>([]);
    const [newDeclined, setNewDeclined] = useState(false);
    const [tab, selectTab] = useState((state as ILocationProps)?.selected as string ?? 'to-do');
    const [isMobile, setIsMobile] = useState(false);
    const [showOfferModal, setShowOfferModal] = useState(false);
    const [showContractModal, setShowContractModal] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const [isRocketShown, setIsRocketShown] = useState(false);
    const [campaignId, setCampaignId] = useState('');
    const [campaignTitle, setCampaignTitle] = useState('');
    const [offerFilterParams, setOfferFilterParams] = useState<IOfferManagementFilter>({
        name: '',
        status: '',
        isCharity: false,
        sortBy: 'date',
        sortType: 'DESC',
        isContract: false,
    });
    const campaignQueryId = useSearchQuery('campaignId');
    const notificationTypeQuery = useSearchQuery('notificationType');
    const [countOffer, setCountOffer] = useState({
        toDo: 0,
        inProgress: 0,
    });
    const {t} = useTranslation();

    useEffect(() => {
        ManagementOfferService.getOfferList(offerFilterParams)
            .then(((response) => {
                setTodoOffers(response.todoOffers.campaigns);
                setCountOffer({
                    inProgress: response?.inProgressOffers?.campaignsCount ?? 0,
                    toDo: response?.todoOffers?.campaignsCount ?? 0,
                });
                setInProgressOffers(response.inProgressOffers.campaigns);
                setNewDeclined(response.declinedOffersDot);
            })).catch(err => ErrorToast(err));
    }, [offerFilterParams, updateList]);

    useEffect(() => {
        if (window.innerWidth < 540) {
            setIsMobile(true);
        }
        if (state) {
            history.replace({});
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (campaignQueryId) {
            if (notificationTypeQuery === ENotificationType.CONTRACT || notificationTypeQuery === ENotificationType.CONTRACT_COMMENT) {
                setShowContractModal(true);
            } else {
                setShowOfferModal(true);
            }
            setCampaignId(campaignQueryId);

        }
    }, [campaignQueryId, notificationTypeQuery]);

    const handleTab = (event: React.SyntheticEvent, newValue: string) => {
        selectTab(newValue);
    };
    const mapData = (tabName: string) => {
        if (tabName === 'to-do') {
            if (!todoOffers.length) return [<EmptyState iconPath="/icons/received.png" key="emptytodo"
                                                        addClass="mb-4 col-8 text-center mx-auto"
                                                        desc={t('influencer.offer_management.empty_to_do')}
                                                        btnText={t('influencer.dashboard.explore')}
                                                        navigateTo="/influencer/explore"/>]
            return (
                todoOffers.map((campaign) => (
                    !!campaign?.contracts?.length ?
                        <ContractCardItem key={campaign?.id} setCampaignId={setCampaignId}
                                          setCampaignTitle={setCampaignTitle}
                                          setShowModal={setShowContractModal}
                                          isTodo={true}
                                          campaign={campaign}/>
                        :
                        <OffersCardItem key={campaign?.id} setCampaignId={setCampaignId}
                                        setCampaignTitle={setCampaignTitle}
                                        setShowModal={setShowOfferModal}
                                        setUpdateList={setUpdateList}
                                        isTodo={true}
                                        campaign={campaign}/>
                ))
            );
        } else if (tabName === 'in-progress') {
            if (!inProgressOffers.length) return [<EmptyState iconPath="/icons/sent.png" key="emptyinprogress"
                                                              addClass="mb-4 col-8 text-center mx-auto"
                                                              desc={t('influencer.offer_management.empty_in_progress')}
                                                              btnText={t('influencer.dashboard.explore')}
                                                              navigateTo="/influencer/explore"/>];
            return (
                inProgressOffers.map((campaign) => (
                    !!campaign?.contracts?.length ?
                        <ContractCardItem key={campaign?.id} setCampaignId={setCampaignId}
                                          setCampaignTitle={setCampaignTitle}
                                          setShowModal={setShowContractModal}
                                          campaign={campaign}/>
                        :
                        <OffersCardItem key={campaign?.id} setCampaignId={setCampaignId}
                                        setCampaignTitle={setCampaignTitle}
                                        setShowModal={setShowOfferModal}
                                        campaign={campaign}/>
                ))
            );
        }
    };
    return (
        <>
            <RocketModal show={isRocketShown} closeModal={() => setIsRocketShown(false)} campaignTitle={campaignTitle}/>
            <InfluencerOfferModal show={showOfferModal}
                                  setShowModal={setShowOfferModal}
                                  campaignId={campaignId}
                                  setIsRocketShown={setIsRocketShown}
                                  updateList={setUpdateList} isLongTerm={false}/>
            <InfluencerContractModal show={showContractModal}
                                     setShowModal={setShowContractModal}
                                     campaignId={campaignId}
                                     setIsRocketShown={setIsRocketShown}
                                     updateList={setUpdateList}/>
            <OffersFilter offerFilter={offerFilterParams} setOfferFilter={setOfferFilterParams}
                          newDeclined={newDeclined}/>
            {!isMobile ?
                <>
                    <Row>
                        <Col lg={6}>
                            <div className="d-flex align-items-center mb-4">
                                <OffersIcon width="30" height="30" color="light-gray"/>
                                <h4 className="mb-0 ml-2 text-uppercase">
                                    {t('influencer.offer_management.to_do', {counter: countOffer?.toDo})}
                                </h4>
                            </div>
                            {!!mapData('to-do')?.length ? mapData('to-do') : <div className="card">
                                <div className="card-body"><EmptyState/></div>
                            </div>}
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex align-items-center mb-4">
                                <OffersIcon width="30" height="30" color="light-gray"/>
                                <h4 className="mb-0 ml-2 text-uppercase">
                                    {t('influencer.offer_management.in_progress', {counter: countOffer?.inProgress})}
                                </h4>
                            </div>
                            {!!mapData('in-progress')?.length ? mapData('in-progress') : <div className="card">
                                <div className="card-body"><EmptyState/></div>
                            </div>}
                        </Col>
                    </Row>
                </> :
                <>
                    <OffersMobileTabs handleTab={handleTab} countOffer={countOffer} tab={tab}/>
                    <Row className="mt-2">
                        {mapData(tab)}
                    </Row>
                </>
            }
        </>
    );
};
