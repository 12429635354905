import {createSlice} from '@reduxjs/toolkit';
import {ICampaignOverview} from '../../model/briefing/overview/ICampaignOverview';
import {IInviteContractInfluencer} from '../../model/book/Invites';
import {IOfferItemsRequest} from '../../model/offer/IOfferItems';
import {IInfluencerDistribution} from '../../app/influencer/offer/dto/IInfluencerDistribution';
import {WarningToast} from '../../utils/toasters';
import {IBundle} from '../../model/options/IBundle';
import {OfferStatusEnum} from '../../enum/OfferStatusEnum';

export interface IContractInfluencer {
    activeTabModal: number;
    campaignId: string;
    campaign?: ICampaignOverview;
    isDistributionChecked: boolean;
    isLegalChecked: boolean;
    isStepperFinished: boolean;
    isEditable?: boolean;
    canCounter?: boolean;
    contractModalStatus: string;
    initialContract: IInviteContractInfluencer;
    additionalItemId: number;
    distributionArr: IInfluencerDistribution[];
    hasLegal: boolean;
    hasDistribution: boolean;
    requiredFieldChangeColor?: boolean;
    canWriteComment?: boolean;
    isCharity: boolean;
    bundle: IBundle;
    hasUnreadComments: boolean;
}

const initialContractState: IContractInfluencer = {
    activeTabModal: 0,
    campaignId: '',
    bundle: {
        comments: [],
        updatedAt: '',
        feedback: '',
        files: [],
        id: 0,
        isEnabled: false,
        sharedAt: null,
        budget: 0,
    },
    canCounter: undefined,
    campaign: undefined,
    distributionArr: [],
    contractModalStatus: 'preview',
    isDistributionChecked: false,
    isLegalChecked: false,
    isStepperFinished: false,
    additionalItemId: 1,
    isEditable: undefined,
    initialContract: {
        description: '',
        personalOffer: '',
        price: 0,
        contractItems: [],
        distribution: [],
    },
    hasLegal: false,
    hasDistribution: false,
    requiredFieldChangeColor: false,
    canWriteComment: false,
    isCharity: false,
    hasUnreadComments: false,
};

export const contractInfluencer = createSlice({
    name: 'contract',
    initialState: initialContractState,
    reducers: {
        setActiveTab: (state, action) => {
            const currentStep = action.payload;
            if (!state.isDistributionChecked && currentStep > 2) {
                WarningToast('Please check the required fields');
                return {...state, activeTabModal: 2, requiredFieldChangeColor: true};
            } else if (!state.isLegalChecked && currentStep > 3 && state.hasLegal) {
                WarningToast('Please check the required fields');
                return {...state, activeTabModal: 3, requiredFieldChangeColor: true};
            } else if (!state.isStepperFinished && currentStep > 3) {
                return {
                    ...state,
                    isStepperFinished: true,
                    activeTabModal: 4,
                };
            }
            if (currentStep === 5) {
                return {
                    ...state,
                    requiredFieldChangeColor: false,
                    activeTabModal: action.payload,
                    hasUnreadComments: false
                };
            }
            return {...state, requiredFieldChangeColor: false, activeTabModal: action.payload};
        },
        setContractModalStatus: (state, action) => {
            return {...state, contractModalStatus: action.payload};
        },
        setContractModalDetails: (state, action) => {
            const campaign: ICampaignOverview = action.payload;
            const contractItems: IOfferItemsRequest[] = [];
            let additionalItems: IInfluencerDistribution[] = [];
            campaign.contractPlaceholder?.totalPlatforms?.map((platform => (
                platform.contractItems.map((item) => (
                    contractItems.push(
                        {
                            mainPlatformId: platform.id,
                            socialPlatformId: item.socialPlatform.id,
                            nbPosts: item.nbPosts ?? 0,
                            price: item.price,
                            oldPrice: item.oldPrice,
                        })
                ))
            )));
            if (campaign?.contractPlaceholder?.additionalContractItems?.length) {
                campaign?.contractPlaceholder?.additionalContractItems.map((item) => additionalItems.push(item));
            }
            if (!campaign.contracts.length) {
                additionalItems = [...campaign?.contractPlaceholder?.distribution];
            }
            return {
                ...state,
                campaign: action.payload,
                activeTabModal: action.payload?.activeTabModal ?? (campaign?.contracts?.length && 4),
                contractModalStatus: campaign?.contracts?.length ? 'preview' : 'negotiate',
                distributionArr: campaign?.contractPlaceholder?.distribution,
                isStepperFinished: !!campaign?.contracts?.length,
                isDistributionChecked: !!campaign?.contracts?.length,
                isLegalChecked: !!campaign?.contracts?.length,
                isEditable: campaign?.contractPlaceholder?.flags?.isEditable,
                canCounter: campaign?.contractPlaceholder?.flags?.canCounter,
                initialContract: {
                    ...state.initialContract,
                    contractItems,
                    distribution: additionalItems,
                    description: campaign?.contracts[0]?.description ?? '',
                    personalOffer: campaign?.contracts[0]?.personalOffer ?? '',

                },
                hasLegal: (!campaign.hasExclusivity && !campaign.hasByLaws) ? false : true,
                hasDistribution: !campaign.brandedContent && !campaign.usageRights ? false : true,
                canWriteComment: [OfferStatusEnum.NEGOTIATE, OfferStatusEnum.PENDING].includes(campaign?.contracts[0]?.status),
                isCharity: campaign?.isCharity,
                hasUnreadComments: campaign?.contracts[0]?.hasUnreadComments,
            };
        },
        setInitialState: (state, action) => {
            return initialContractState;
        },
        setDistributionItems: (state, action) => {
            const distributionArr = action.payload;
            return {...state, distributionArr};
        },
        setDistributionCheck: (state, action) => {
            return {...state, isDistributionChecked: action.payload};
        },
        setLegalCheck: (state, action) => {
            return {...state, isLegalChecked: action.payload};
        },
        setFinishStepper: (state, action) => {
            return {...state, isStepperFinished: true};
        },
        setContractItems: (state, action) => {
            const contractItems = action.payload;
            return {...state, initialContract: {...state.initialContract, contractItems}};
        },
        setAdditionalItems: (state, action) => {
            const distribution = action.payload;
            return {...state, initialContract: {...state.initialContract, distribution}};
        },
        addAdditionalItem: (state, action) => {
            const distributionItem = action.payload;
            const distributionArr: IInfluencerDistribution[] = [...state?.initialContract?.distribution];
            distributionArr.push({...distributionItem});
            if (distributionItem?.type === 'additional') {
                return {
                    ...state,
                    additionalItemId: state.additionalItemId + 1,
                    initialContract: {...state.initialContract, distribution: distributionArr},
                };
            }
            return {...state, initialContract: {...state.initialContract, distribution: distributionArr}};
        },
        deleteAdditionItem: (state, action) => {
            const index = action.payload;
            const distributionArr = [...state?.initialContract?.distribution];
            distributionArr.splice(index, 1);
            return {...state, initialContract: {...state.initialContract, distribution: distributionArr}};
        },
        setPersonalOfferDescription: (state, action) => {
            return {...state, initialContract: {...state.initialContract, personalOffer: action.payload}};
        },
        setDescription: (state, action) => {
            return {
                ...state,
                initialContract: {...state.initialContract, description: action.payload},
                requiredFieldChangeColor: false,
            };
        },
        setCampaignId: (state, action) => {
            return {...state, campaignId: action.payload};
        },
        setBundle: (state, action) => {
            return {...state, bundle: action.payload};
        },
    },
});

export const {
    setActiveTab,
    setContractModalStatus,
    setContractModalDetails,
    setInitialState,
    setLegalCheck,
    setDistributionCheck,
    setFinishStepper,
    setContractItems,
    addAdditionalItem,
    deleteAdditionItem,
    setDistributionItems,
    setAdditionalItems,
    setDescription,
    setPersonalOfferDescription,
    setCampaignId,
    setBundle,
} = contractInfluencer.actions;
