import {requests} from '../../../utils';
import {INFLUENCER_DASHBOARD} from '../../../utils/endpoints';
import {IMyCampaignsFilterProps} from '../campaign/dto/IMyCampaigns';
import {ICampaignOverview} from '../../../model/briefing/overview/ICampaignOverview';
import {BaseModel} from '../../../model/shared/BaseModel';

export const InfluencerDashboardApi = {
    getSummary: () => requests.get(`${INFLUENCER_DASHBOARD}/summary`),
    getContracts: (): Promise<BaseModel<ICampaignOverview[]>> => requests.get(`${INFLUENCER_DASHBOARD}/summary/contracts`),
    getCampaigns: (pagination: IMyCampaignsFilterProps) => requests.get(`${INFLUENCER_DASHBOARD}/summary/campaigns`, pagination),
    getOffers: (pagination: IMyCampaignsFilterProps) => requests.get(`${INFLUENCER_DASHBOARD}/summary/offers`, pagination),
};
