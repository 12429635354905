import ClickAwayListener from '@mui/material/ClickAwayListener';
import {Tooltip as MuiTooltip} from '@mui/material';
import {CustomImageHandler} from './CustomImageHandler';
import React, {useState} from 'react';
import {ICompanyUser} from '../../app/shared/user/dto/ICompanyUser';
import {User} from '../../model/user/User';
import {ICompany} from '../../app/shared/user/dto/ICompany';

interface IAssignedUserTooltipProps {
    users?:  ICompanyUser[] |  User[] | ICompany[];
    numberVisibleUsers: number;
}

export function AssignedUserTooltip({users, numberVisibleUsers = 3}: IAssignedUserTooltipProps) {
    const [openTooltip, setOpenTooltip] = useState(false);

    return (
        <>
            {(users?.length ?? 0) > (numberVisibleUsers) &&
                <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                    <MuiTooltip id="white-tooltip"
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={() => setOpenTooltip(false)}
                                open={openTooltip}
                                title={<>
                                    {users?.slice(numberVisibleUsers)?.map(item => (
                                        <div className="mx-1 my-2" key={item?.id}>
                                            <div className="d-flex align-items-center">
                                                <CustomImageHandler
                                                    classes="user-img-rounded"
                                                    photoPath={item?.profilePhoto?.thumbnail ?? '/icons/UserPlaceholder.png'}
                                                    altTag={`${item?.profilePhoto?.name}`}/>
                                                <h6 className="ml-1 text-dark mb-0">{item?.firstName ?? item?.displayName}</h6>
                                            </div>
                                        </div>
                                    ))}
                                </>}
                                placement="right-end"
                                className="cursor-pointer ">
                                                <span
                                                    onClick={() => setOpenTooltip(prevState => !prevState)}
                                                    className="icon-round bg-white text-primary shadow font-weight-semi-bold min-width-max-content cursor-pointer"
                                                    style={{width: 33}}>
                                                    + {(users?.length ?? 0) - numberVisibleUsers}
                                                </span>
                    </MuiTooltip>
                </ClickAwayListener>
            }
        </>
    );
};