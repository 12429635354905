import {requests} from '../../../utils';
import {IOfferManagement} from './dto/IOfferManagement';
import {
    INFLUENCER_CONTRACT,
    INFLUENCER_INITIAL_OFFER,
    INFLUENCER_OFFER,
    INFLUENCER_OFFER_MANAGEMENT,
    INFLUENCER_OFFER_MODAL,
} from '../../../utils/endpoints';
import {IOfferManagementFilter} from './dto/IOfferManagementFilter';
import {ICampaignOverview} from '../../../model/briefing/overview/ICampaignOverview';
import {IInviteContractInfluencer, IInviteInfluencer} from '../../../model/book/Invites';
import {IOffer} from '../../../model/offer/IOffer';
import {IDeclineOffer} from './dto/IDeclineOffer';
import {IContract} from '../../../model/shared/IContract';

export const ManagementOfferService = {
    getOfferList: (params: IOfferManagementFilter): Promise<IOfferManagement> => requests.get(INFLUENCER_OFFER_MANAGEMENT, params),
    getOfferModalDetail: (id: string, isLongTerm: boolean): Promise<ICampaignOverview> => requests.get(`${INFLUENCER_OFFER_MODAL}/${id}`,
      {isLongTerm}),
    sendInitialOffer: (id: string, body: IInviteInfluencer) => requests.post(`${INFLUENCER_INITIAL_OFFER}/${id}`, body),
    sendCounterOffer: (id: string, body: IInviteInfluencer) => requests.put(`${INFLUENCER_OFFER}/${id}`, body),
    acceptOffer: (id: string) => requests.put(`${INFLUENCER_OFFER}/accept/${id}`, {}),
    withdrawOffer: (id: string) => requests.put(`${INFLUENCER_OFFER}/withdraw/${id}`, {}),
    declineOffer: (id: string, body: IDeclineOffer) => requests.put(`${INFLUENCER_OFFER}/decline/${id}`, body),
    getOfferHistory: (id?: string): Promise<IOffer[]> => requests.get(`${INFLUENCER_OFFER_MODAL}/history/${id}`),
};
export const InfluencerContractService = {
    getContractModalDetail: (id: string): Promise<ICampaignOverview> => requests.get(`${INFLUENCER_CONTRACT}/modal/${id}`),
    sendInitialContract: (id: string, body: IInviteContractInfluencer) => requests.post(`${INFLUENCER_CONTRACT}/initial/${id}`, body),
    sendCounterContract: (id: string, body: IInviteContractInfluencer) => requests.put(`${INFLUENCER_CONTRACT}/${id}`, body),
    acceptContract: (id: string) => requests.put(`${INFLUENCER_CONTRACT}/accept/${id}`, {}),
    withdrawContract: (id: string) => requests.put(`${INFLUENCER_CONTRACT}/withdraw/${id}`, {}),
    declineContract: (id: string, body: IDeclineOffer) => requests.put(`${INFLUENCER_CONTRACT}/decline/${id}`, body),
    getContractHistory: (id?: string): Promise<IContract[]> => requests.get(`${INFLUENCER_CONTRACT}/modal/history/${id}`),
};
