import {Button, Card} from 'react-bootstrap';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {useTranslation} from 'react-i18next';
import {User} from '../../../../../model/user/User';
import React from 'react';
import {IContract} from '../../../../../model/shared/IContract';

interface IAcceptedContractCardProps {
    contract: IContract;
    openContractAction: (user?: User) => void;
}

export function AcceptedContractCard({contract, openContractAction}: IAcceptedContractCardProps) {
    const {t} = useTranslation();
    return (
        <>
            <Card className="mb-2">
                <Card.Body className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <CustomImageHandler classes="user-img-rounded mr-2"
                                            photoPath={contract?.user?.profilePhoto?.thumbnail}
                                            altTag="User Profile"/>
                        <div>
                            <h6 className="mb-0">{!!contract.user.displayName ? contract.user.displayName : (contract?.user?.firstName + ' ' + contract?.user?.lastName)}</h6>
                            <p className="mb-0 text-primary euro-symbol">{contract?.price}</p>
                        </div>
                    </div>
                    {!!contract?.declineReason?.length &&
                        <div className="d-flex align-items-center">
                            <h6 className="mx-2 text-muted">
                                <span>Declined reasons:</span> {contract?.declineReason?.map((item, index) => (
                                <span key={index}>{t(`general.decline_reasons.${item}`)}
                                    {index === contract?.declineReason?.length - 1 ? '' : ', '}
                        </span>
                            ))}
                            </h6>
                        </div>
                    }
                    <div className="d-flex align-items-center">
                        <Button onClick={() => openContractAction(contract?.user)} variant="outline-primary">
                            {t('general.button.open')}
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};
