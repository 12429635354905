import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {BsBarChartLineFill} from 'react-icons/bs';
import {downloadFile, formatDate} from '../../../../../shared/functions/Functions';
import {SocialPlatformPostings} from '../../../../../shared/components/social/SocialPlatformPostings';
import {BrandedContent} from './BrandedContent';
import {UsageRights} from './UsageRights';
import {ICampaignOverview} from '../../../../../model/briefing/overview/ICampaignOverview';
import {useTranslation} from 'react-i18next';
import DOMPurify from 'dompurify';
import FilePlaceholder from '../../../../../shared/components/placeholder/FilePlaceholder';
import { Fancybox } from '../../../../../shared/components/Fancybox';

interface IBriefingDetailProps {
    currentInformations?: ICampaignOverview;
}

export function BriefingDetailLeftCard({currentInformations}: IBriefingDetailProps) {
    const {t} = useTranslation();
    return (
        <>
            <Card className="mt-2 mt-md-0">
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <h6 className="mb-3">
                                {t('influencer.briefing_detail.campaign')}
                            </h6>
                        </Col>
                        <Col md={3}>
                            <CustomImageHandler classes="img-cover default-radius w-100 h-100"
                                                thumbnailPath={currentInformations?.campaignGoal?.goalImage?.thumbnail ?? ''}
                                                photoPath={currentInformations?.campaignGoal?.goalImage?.path ?? ''}
                                                altTag={currentInformations?.campaignGoal?.goalImage?.name ?? ''}/>
                        </Col>
                        <Col md={9}>
                            <h6 className="mt-3 mt-md-0">
                                {currentInformations?.title}
                            </h6>
                            <div className="d-flex align-items-center my-3">
                                <BsBarChartLineFill
                                    className="text-dark"
                                    size={28}/>
                                <h6 className="mb-0 ml-2 text-primary">{currentInformations?.campaignGoal?.title}</h6>
                            </div>
                            <p>{currentInformations?.campaignGoal?.story}
                            </p>
                        </Col>
                        <Col className="mt-3" xs={12}>
                            <h6>
                                {t('influencer.briefing_detail.timeline')}
                            </h6>
                        </Col>
                        <Col className="my-2" xs={6} md={4}>
                            <div>
                                <h6 className="text-muted">{t('influencer.offer_management.modal.publication_period')}</h6>
                                <p>{currentInformations?.publishFrom ? formatDate(currentInformations?.publishFrom as string) : 'DD.MM.YYYY.'}
                                    -
                                    {currentInformations?.publishTo ? formatDate(currentInformations?.publishTo as string) : ' DD.MM.YYYY.'}
                                </p>
                            </div>
                        </Col>
                        <Col className="my-2" xs={6} md={4}>
                            <div>
                                <h6 className="text-muted">{t('influencer.offer_management.modal.content_approval_deadline')}</h6>
                                <p>
                                    {currentInformations?.isReviewed ? currentInformations.reviewDays : '-'}
                                    {currentInformations?.isReviewed ? t('general.days') : ''}
                                </p>
                            </div>
                        </Col>
                        <Col className="my-2" xs={6} md={4}>
                            <div>
                                <h6 className="text-muted">{t('influencer.offer_management.modal.application_deadline')}</h6>
                                <p>{currentInformations?.date ? formatDate(currentInformations?.date as string) : 'DD.MM.YYYY.'}</p>
                            </div>
                        </Col>
                        <Col className="mb-2" xs={12}>
                            <h6>{t('influencer.offer_management.modal.placements')}</h6>
                        </Col>
                        {!!currentInformations?.platforms?.length ?
                            currentInformations?.platforms?.map((platform, i) => (
                                <Col className="mb-2" xs={6} md={4} xl={3} key={`${platform?.socialPlatform?.name}-${i}`}>
                                    <SocialPlatformPostings key={platform.id}
                                                            platformName={t(`general.platforms.${platform?.socialPlatform?.name}`)}
                                                            image={platform?.socialPlatform?.path ?? ''}
                                                            numberPosts={platform?.nbPosts}/>
                                </Col>
                            ))
                            : '-'
                        }
                        <Col className="my-2" xs={12}>
                            <h6 className="text-muted">{t('influencer.offer_management.modal.product_description')}</h6>
                            <p className="remove-html-styling"
                               dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(currentInformations?.details ?? '')}}>
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {/*CONTENT CARD*/}
            <Card className="my-3">
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <h6 className="mb-3">
                                {t('influencer.briefing_detail.content')}
                            </h6>
                        </Col>
                        <Col xs={12}>
                            <h6 className="text-muted">{t('influencer.offer_management.modal.campaign_description')}</h6>
                            <p className="remove-html-styling"
                               dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(currentInformations?.adDescription ?? '-')}}/>
                        </Col>
                        {!!currentInformations?.profileTags && (
                            <Col md={6}>
                                <h6 className="text-muted">Tagging</h6>
                                <p>{currentInformations?.profileTags ?? '-'}</p>
                            </Col>
                        )}
                        <Col md={6}>
                            <h6 className="text-muted">{t('influencer.offer_management.modal.relevant_hash')}</h6>
                            <p>{currentInformations?.hashtags ?? '-'}</p>
                        </Col>
                        <Col>
                            <h6 className="text-muted">{t('brand.campaign.briefing.step2.moodboard')}</h6>
                            <Row>
                                <Fancybox>
                                {!!currentInformations?.campaignImages?.length ? currentInformations?.campaignImages
                                    ?.filter(image => image?.purpose === 'style_mood_image')
                                    ?.map(moonBoard => {
                                        return (
                                            <div className="col-md-3 my-2 pl-0" key={moonBoard?.id}>
                                                <FilePlaceholder file={moonBoard} styleContent={{height: '130px'}}
                                                                nameFancybox='moonboard'
                                                                handleDownload={() => downloadFile(moonBoard?.path, moonBoard?.title)}
                                                />    
                                            </div>
                                        )
                                    }) : '-'}
                                </Fancybox>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <h6 className="text-muted">{t('influencer.offer_management.modal.inspiration_links')}</h6>
                            <a href={currentInformations?.url} target="_blank" rel="noreferrer">
                                {currentInformations?.url ?? '-'}
                            </a>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {/*DISTRIBUTION CARD*/}
            <Card>
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <h6 className="mb-3">
                                {t('influencer.briefing_detail.distribution')}
                            </h6>
                        </Col>
                        <Col md={3}>
                            <CustomImageHandler photoPath="/tempAssets/img-organic-reach.png" altTag="Image"
                                                classes="w-100 default-radius"/>
                        </Col>
                        <Col md={9}>
                            <h5 className="mt-2 mt-md-0">{t('influencer.briefing_detail.organic_reach')}</h5>
                            <p className="text-muted">{t('influencer.briefing_detail.organic_reach_description')}</p>
                        </Col>

                        <Col className="my-3" xs={12}>
                            <h6 className="text-muted mb-0">{t('influencer.offer_management.modal.branded_content')}</h6>
                        </Col>
                        <BrandedContent brandedContentData={currentInformations?.brandedContent}/>
                        <Col className="my-3" xs={12}>
                            <h6 className="text-muted mb-0">{t('influencer.briefing_detail.usage_rights')}</h6>
                        </Col>
                        <UsageRights usageRightsData={currentInformations?.usageRights}/>
                    </Row>
                </Card.Body>
            </Card>
            {/*LEGAL CARD*/}
            <Card className="mt-3 mb-5">
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <h6 className="mb-3">
                                {t('influencer.briefing_detail.legal')}
                            </h6>
                        </Col>
                        <Col xs={12}>
                            <h6 className="text-muted">
                                {t('influencer.briefing_detail.exclusivity')}
                            </h6>
                            <p className="mb-4">
                                {currentInformations?.exclusivityDescription ?? '-'}
                            </p>
                        </Col>
                        <Col xs={6}>
                            <h6>{t('influencer.offer_management.modal.exc_duration')}</h6>
                            <p>{currentInformations?.exclusivityDuration ? t(`brand.campaign.briefing.step8.${currentInformations?.exclusivityDuration}`) : '-'}</p>
                        </Col>
                        <Col xs={6}>
                            <h6>{t('influencer.briefing_detail.competitors')}</h6>
                            <p>{currentInformations?.exclusivityCompetitors?.length ? currentInformations?.exclusivityCompetitors
                                ?.map((competitor, index) => (
                                    <span key={competitor?.id}>
                                                        {competitor?.description}
                                        {(currentInformations?.exclusivityCompetitors?.length - 1) > index ? ', ' : ''}
                                                    </span>
                                )) : '-'}</p>
                        </Col>
                        <Col className="my-3" xs={12}>
                            <h6 className="text-muted">{t('influencer.offer_management.modal.bylaws')}</h6>
                            <p>{currentInformations?.byLawsExplanation ?? '-'}</p>
                        </Col>
                        <Col xs={12}>
                            <h6 className="text-muted">{t('influencer.offer_management.modal.bylaws_uploads')}</h6>
                            <Row>
                                <Fancybox>
                                {!!currentInformations?.bylaws?.length ? currentInformations?.bylaws?.map(bylaws => {
                                    return (
                                        <div className="col-md-3 my-2 pl-0" key={bylaws?.id}>
                                            <FilePlaceholder file={bylaws} styleContent={{height: 130}}
                                                            nameFancybox='bylaws'
                                                            handleDownload={() => downloadFile(bylaws?.path, bylaws?.title)}
                                            />
                                        </div>
                                )}) : '-'}
                                </Fancybox>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};