import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {CustomAutocomplete} from '../../../../../../shared/components/Autocomplete';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import CustomDatePicker from '../../../../../../shared/components/DatePicker';
import {calculateUploadUntil, formatDate, removeTimeZoneFromDate} from '../../../../../../shared/functions/Functions';
import {ErrorToast, SuccessToast} from '../../../../../../utils/toasters';
import {IOfferUpdate} from '../../../book/offer/dto/IUpdateOffer';
import {ManageService} from '../../service';
import {iManageList} from '../dto/IManageCalendar';

interface IProposalModalProps {
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
    setTriggerUpdate?: React.Dispatch<SetStateAction<boolean>>;
    isModalOpened: boolean;
    handleCloseModal: () => void;

}

const BrandProposalModal = ({
                                state,
                                setState,
                                isModalOpened,
                                setTriggerUpdate,
                                handleCloseModal,
                            }: IProposalModalProps) => {
    const {t} = useTranslation();
    const [selectedOffer, setSelectedOffer] = useState<IOfferUpdate>();
    const selectedPlatform = state?.offerItem;
    const isProposalEdited = formatDate(state?.postingDate) !== formatDate(state?.date);
    const handleChangeTimepicker = (newValue: Date | null, name: string) => {
        setState(prev => ({...prev, [name]: newValue}));
    };

    const numReviewsDays = selectedOffer?.offer?.campaign?.isReviewed ? selectedOffer?.offer?.campaign?.reviewDays : 0;
    const changeOfferHandler = (paramKey: string, paramValue: string | number) => {
        setState(prev => ({...prev, [paramKey]: +paramValue}));
    };
    const handleCreateEvent = async () => {
        await ManageService.updateProposalDate(state?.id, state?.postingDate ?? state?.date).then(response => {
            if (response?.id) {
                setTriggerUpdate && setTriggerUpdate(prev => !prev);
                handleCloseModal();
                SuccessToast(t('influencer.planner.successUpdated'));
            }
        }).catch(err => ErrorToast(err));
    };
    useEffect(() => {
        if (state?.postingDate) {
            setSelectedOffer(state?.offerItem as unknown as IOfferUpdate);
        }
    }, [state]);

    return (
        <Modal
            show={isModalOpened}
            centered
            onHide={handleCloseModal}
            keyboard={false}
        >
            {state?.id && <Modal.Body>
                <div className="border-bottom mb-3">
                    <h5>
                        {t('influencer.planner.newPosting')}
                    </h5>
                </div>
                <div className="my-4 d-flex align-items-center">
                    <CustomImageHandler photoPath={selectedOffer?.offer?.user?.profilePhoto?.path as string}
                                        classes="small-rounded-logo"
                                        altTag="Social Platform icon"/>
                    <div className="ml-2">
                        <span className="font-weight-semi-bold">
                            {selectedOffer?.offer?.campaign?.title ?? '-'}
                        </span>
                        <div className={'d-flex align-items-center'}>
                            <div className="span text-primary">
                                {selectedOffer?.offer?.user?.displayName ?? '-'}
                            </div>
                            {selectedOffer?.offer?.isLongTerm && <div className={'custom-badge long-term ml-1'}>
                                {t(`brand.campaign.briefing.overview_card.long_term`)}
                            </div>}
                            {selectedOffer?.offer?.isAddonsOnly && <div className={'custom-badge additional ml-1'}>
                                {t('influencer.offer_management.modal.additional')}
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="my-4">
                    <CustomAutocomplete
                        dataArray={[]}
                        defaultArray={state?.offerItem as any}
                        isWithImage={true} inputLabel="socialPlatform.name" isMultiple={false}
                        photoLabel="socialPlatform.path"
                        isDisabled={true}
                        selectedStateImage={selectedPlatform?.socialPlatform?.path as string}
                        queryFilterKey="offerItemId"
                        inputLabelTranslation={t('brand.campaign.manage.postingType')}
                        handleChangeParams={changeOfferHandler}/>
                </div>
                <div className="my-3">
                    <CustomDatePicker label={t('brand.campaign.manage.postingDate')}
                                      value={state?.postingDate}
                                      disableFrom={removeTimeZoneFromDate(state?.offerItem.offer?.campaign?.publishFrom ?? '')}
                                      disableTo={removeTimeZoneFromDate(state?.offerItem.offer?.campaign?.publishTo ?? '')}
                                      onChange={(event: Date | null) => handleChangeTimepicker(event, 'postingDate')}/>
                </div>
                <p>{t('influencer.planner.uploadContentUntil')}: {calculateUploadUntil(numReviewsDays, state?.postingDate as string)}</p>
            </Modal.Body>}
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleCloseModal}>
                    {t('general.button.cancel')}
                </Button>
                {isProposalEdited && <Button variant="primary" onClick={handleCreateEvent}>
                    {t('general.button.reschedule')}
                </Button>}
            </Modal.Footer>
        </Modal>
    );
};


export default BrandProposalModal;
