import DOMPurify from 'dompurify';
import React from 'react'
import { Button, Card, Col, Image } from 'react-bootstrap';

interface IAlertBox {
    classCol: string;
    variant: string;
    hasButton?: boolean;
    textButton?: string;
    functionBtn?: (...args:any) => void;
    image: string;
    title: string;
    desc: string;
}

export default function AlertBAlertBoxox({classCol, image, variant, hasButton, title, desc, textButton, functionBtn}: IAlertBox) {
    return (
      <Col className={classCol ?? ''}>
        <Card className={`border border-${variant} p-2`}>
          <div className="d-flex align-items-center">
            <Image src={image} alt="" height={40}/>
            <div className="ml-3">
              <h5 className="mb-0">{title}</h5>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(desc)}} className="text-muted"></div>
            </div>
            {!!hasButton && (
              <div className="d-flex justify-content-end align-items-center col pr-1">
                <Button className={`btn-outline-${variant} w-maxContent`} onClick={functionBtn}>{textButton}</Button>
              </div>
            )}
          </div>
        </Card>
      </Col>
    );
}
