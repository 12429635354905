import moment from 'moment';
import React from 'react';
import {Button, Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ICampaignOverview} from '../../../../model/briefing/overview/ICampaignOverview';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {SocialPlatformPostings} from '../../../../shared/components/social/SocialPlatformPostings';
import {checkOfferType, formatDate, getFirstPreviewImage} from '../../../../shared/functions/Functions';
import {BestPicks} from '../../../../shared/Icons';
import {redDotsSlice} from '../../../../store/influencer/redDotsSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {getAdditionalItems} from '../../explore/components/ExploreCard';
import {OriginalOfferCard} from './OriginalOfferCard';
import {setSelectMatchedUser} from '../../../../store/agency/agencyOfferSlice';
import {useDispatch, useSelector} from 'react-redux';

interface IOffersCardItemProps {
    setUpdateList?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setCampaignId: React.Dispatch<React.SetStateAction<string>>;
    setCampaignTitle: React.Dispatch<React.SetStateAction<string>>;
    campaign: ICampaignOverview;
    isTodo?: boolean;
    isAgencyCard?: boolean;
}

export function OffersCardItem({
                                   setUpdateList,
                                   isTodo = false,
                                   setShowModal,
                                   campaign,
                                   setCampaignId,
                                   setCampaignTitle,
                                   isAgencyCard,
                               }: IOffersCardItemProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const hasBylaws = campaign?.hasByLaws ? 'bylaws' : false;
    const hasExclusivity = campaign?.hasExclusivity ? 'exclusivity' : false;
    const hasBrandedContent = !!campaign?.brandedContent ? 'branded_content' : false;
    const hasUsageRights = !!campaign?.usageRights ? 'usage_right' : false;
    const contentToDisplay = [hasUsageRights, hasExclusivity, hasBrandedContent, hasBylaws]?.filter(item => !!item);
    const {redDots} = useSelector((state: IAllStates) => state.redDots);
    const offer = campaign?.offers[0];
    const hasStripe = offer?.isLongTerm || offer?.isAddonsOnly || offer?.isOnTop;

    const onSubmit = () => {
        setCampaignId(String(campaign?.id));
        setCampaignTitle(campaign?.title);
        setShowModal(true);
        // User for agency offer modal
        if (isAgencyCard && campaign?.offers[0]?.user) {
            dispatch(setSelectMatchedUser(campaign?.offers[0].user));
        }
    };
    const goToOBriefingOverview = () => {
        if (isAgencyCard) {
            history.push(`/agency/campaign/${campaign?.id}/overview`);
        } else {
            history.push(`/influencer/campaign/${campaign?.id}/overview`);
        }
    };
    return (
        <div className="mb-4 w-100 ">
            <Card className={`offer-card-item border-${campaign?.offers[0]?.status} overflow-hidden`}>
                {hasStripe &&
                    <div className="diagonal-text">
                        <span className="diagonal-text-sm">
                            {t(`brand.campaign.briefing.overview_card.${checkOfferType(offer?.isLongTerm, offer?.isOnTop, offer?.isAddonsOnly)}`)}
                        </span>
                    </div>
                }
                <Card.Body>
                    <div className="d-flex flex-column flex-md-row mb-3">
                        <div className="position-relative w-md-30 cursor-pointer" onClick={goToOBriefingOverview}>
                            {getFirstPreviewImage('style_uploaded_image',
                                campaign?.campaignImages, 'w-100 default-radius img-cover h-150px')}
                            <p className={`offer-status-left status ${campaign?.offers[0]?.status} d-md-none`}>
                                {campaign?.offers[0]?.status}
                            </p>
                            <div className="d-md-none image-top-right">
                                {campaign?.isPicked ? <BestPicks width="35" color="orange"/> : null}
                            </div>
                        </div>
                        <div className="d-flex flex-column ml-md-2 mt-3 mt-md-0 w-md-70">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className={`${isAgencyCard && 'mb-0'} cursor-pointer`}
                                    onClick={goToOBriefingOverview}>{campaign?.isCharity && (
                                    <>
                                        <div className="d-inline-block">(</div>
                                        <div
                                            className="text-primary-dark d-inline-block">{t('general.button.barter_deal')}</div>
                                        <div className="d-inline-block">)</div>
                                    </>
                                )} {campaign?.title}
                                </h5>
                                {campaign?.isPicked ?
                                    <BestPicks width="30" color="orange" classes="d-none d-md-flex"/> : null}
                            </div>
                            {isAgencyCard &&
                                <p className="text-primary mb-2">by {campaign?.company?.displayName}</p>
                            }
                            <div>
                                <p className="text-muted mb-0">
                                    {t('brand.campaign.briefing.application_deadline')}: <span
                                    className="text-primary">{formatDate(campaign?.date ?? '')}</span>
                                </p>
                                <p className="text-muted mb-0">
                                    {t('brand.campaign.briefing.publication_period')}: <span
                                    className="text-primary">{formatDate(campaign?.publishFrom ?? '')} - {formatDate(campaign?.publishTo ?? '')}</span>
                                </p>
                            </div>
                            <div className="d-flex flex-wrap mt-2">
                                {!campaign?.offers[0]?.isAddonsOnly ? campaign?.offers[0]?.offerItems?.map(item => (
                                  item?.socialPlatform ?
                                    <SocialPlatformPostings key={item?.id}
                                                            platformName={t(`general.platforms.${item?.socialPlatform?.name}`)}
                                                            image={item?.socialPlatform?.path ?? ''}
                                                            numberPosts={item?.nbPosts}/>
                                : null)) :
                                <p className="mb-0 text-primary">
                                    {t(`brand.campaign.briefing.overview_card.addons_only`)}
                                </p>
                                }
                            </div>
                        </div>
                    </div>
                    <OriginalOfferCard
                        displayName={isTodo ? (campaign.offers[0]?.counterOfferBy?.isSupervisor ? 'Customer Success by i.v.' : `${campaign.offers[0]?.counterOfferBy?.firstName} ${campaign.offers[0]?.counterOfferBy?.lastName}`)
                            : campaign?.offers?.[0]?.user?.displayName ?? '-'}
                        image={campaign.offers[0]?.counterOfferBy ? (campaign.offers[0]?.counterOfferBy.isSupervisor ? '/icons/logoSmall.svg' : campaign.offers[0]?.counterOfferBy?.profilePhoto?.path ?? '') :
                            campaign.offers[0].user?.profilePhoto?.path ?? ''}
                        date={`${formatDate(campaign?.offers[0]?.createdAt)} (${moment(campaign?.offers[0]?.createdAt).format('HH:mm')})`}
                        price={campaign?.offers[0]?.price} isCharity={campaign?.isCharity}/>
                    <h6 className="mt-3 mb-2">{t('brand.campaign.book.offer.modal.additional_items')}</h6>
                    <div className="d-flex overflow-auto">
                        {!!contentToDisplay?.length ? getAdditionalItems(contentToDisplay) :
                            <div className="my-1 d-flex align-items-center">
                                <CustomImageHandler photoPath="/icons/additionalItems/no_aditional_offers.svg"
                                                    altTag="Empty-additional"
                                                    classes="social-icon m-1"
                                />
                                <p className="mb-0 text-muted">{t('influencer.offer_management.no_additional_items')}</p>
                            </div>
                        }
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        {isAgencyCard ?
                            <div className="d-flex align-items-center">
                                <CustomImageHandler photoPath={campaign?.offers[0]?.user?.profilePhoto?.path ?? ''}
                                                    altTag="User Profile"
                                                    classes="middle-rounded-logo"/>
                                <h6 className="mb-0 ml-2">
                                    {!!campaign?.offers[0]?.user?.displayName ?
                                        campaign?.offers[0]?.user?.displayName :
                                        (campaign?.offers[0]?.user?.firstName + ' ' + campaign?.offers[0]?.user?.lastName)
                                    }
                                </h6>
                                <p className={`status ${campaign?.offers[0]?.isRequested ? 'invite' : campaign?.offers[0]?.status} ml-3 d-none d-md-inline-block`}>
                                    {t(`general.campaign_status.${campaign?.offers[0]?.isRequested ? 'invite' : campaign?.offers[0]?.status}`)}
                                </p>
                            </div> :
                            <div className="d-flex align-items-center">
                                <CustomImageHandler photoPath={campaign?.company?.profilePhoto?.path ?? ''}
                                                    altTag="User Profile"
                                                    classes="middle-rounded-logo"/>
                                <h6 className="mb-0 ml-2">{campaign?.company?.displayName}</h6>
                                <p className={`status ${campaign?.offers[0]?.isRequested ? 'invite' : campaign?.offers[0]?.status} ml-3 d-none d-md-inline-block`}>
                                    {t(`general.campaign_status.${campaign?.offers[0]?.isRequested ? 'invite' : campaign?.offers[0]?.status}`)}
                                </p>
                            </div>
                        }
                        <div className="position-relative">
                            <Button
                                className="ml-2"
                                variant="primary"
                                size="sm"
                                onClick={() => {
                                    onSubmit();
                                    if (campaign.isNew && setUpdateList) { // if new campaign, update list
                                        setTimeout(() => {
                                            setUpdateList(prev => !prev);
                                        }, 500);
                                        dispatch(redDotsSlice.actions.setRedDots({
                                            ...redDots,
                                            offers: redDots?.offers > 0 ? redDots?.offers - 1 : 0,
                                        }));
                                    }
                                }}
                            >
                                {t('general.open')}
                            </Button>
                            {campaign?.isNew && <span className="notification-bullet offer"></span>}
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
