import React, {SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {CalendarSwitcher} from '../../../../shared/components/CalendarSwitcher';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {getCorrectFilterTitle} from '../../../../shared/functions/Functions';
import {ErrorToast} from '../../../../utils/toasters';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {InfluencerPlannerService} from '../../../influencer/planner/service';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';

interface IPreviewHeaderProps {
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
}

const PreviewHeader = ({state, setState}: IPreviewHeaderProps) => {
    const {offerItem: {offer}} = state;
    const {t} = useTranslation();
    const {selectedInfluencer} = useSelector((newState: IAllStates) => newState.agency);
    const changeStep = async (isIncrease: boolean) => {
        const stepToGo = isIncrease ? state?.nextId : state?.previousId;
        await InfluencerPlannerService.getPlannerModalData(stepToGo as unknown as string, selectedInfluencer?.id).then(response => {
            setState(response);
        }).catch(error => ErrorToast(error));
    };
    return (
        <div className="col-12 border-bottom mb-3">
            <div className="d-md-flex align-items-center justify-content-between w-100 py-3 ">
                <Link to={`/agency/campaign/${offer?.campaign?.id}/overview`} className="d-flex align-items-center">
                    <CustomImageHandler
                        altTag="Influencer logo"
                        classes="middle-rounded-logo cursor-pointer"
                        thumbnailPath={offer?.campaign?.campaignImages?.[0]?.thumbnail as string}
                        photoPath={offer?.campaign?.campaignImages?.[0]?.path as string}
                    />

                    <div className="ml-3">
                        <h6 className="mb-0 text-dark">
                            {offer?.campaign?.title ?? '-'}
                        </h6>
                        <div className="d-flex justify-content-between align-items-center mt-1 flex-wrap"
                             style={{'gap': '8px'}}>
                            <div className={`status mr-1 ${state?.status} w-fit-content text-dark`}>
                                {getCorrectFilterTitle(state?.status, t)}
                            </div>
                            {offer?.isLongTerm && <div className={'custom-badge long-term text-dark'}>
                                {t(`brand.campaign.briefing.overview_card.linked`)}
                            </div>}
                            {offer?.isAddonsOnly && <div className={'custom-badge additional ml-1 text-dark'}>
                                {t('influencer.offer_management.modal.additional')}
                            </div>}
                            <CalendarSwitcher increaseStep={() => changeStep(true)}
                                              reduceStep={() => changeStep(false)}
                                              isReduceDisabled={!state?.previousId}
                                              isIncreaseDisabled={!state?.nextId}
                                              classes={{'holder': 'm-0 d-md-none', 'btn-l': 'ml-0'}}
                            />
                        </div>
                    </div>
                </Link>
                <CalendarSwitcher increaseStep={() => changeStep(true)} reduceStep={() => changeStep(false)}
                                  isReduceDisabled={!state?.previousId}
                                  isIncreaseDisabled={!state?.nextId}
                                  classes={{'holder': 'd-none d-md-block'}}
                />
            </div>
        </div>
    );
};

export default PreviewHeader;
