import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Campaign} from '../../model/campaign/Campaign';

export interface ISelectedCampaign {
  campaign?: Partial<Campaign>;
  activeCampaignId?: number | undefined;
  isChildCampaign?: boolean;
};

const initialSelectedCampaignSlice: ISelectedCampaign = {
  campaign: undefined,
  activeCampaignId: undefined,
  isChildCampaign: false,
};

export const selectedCampaignSlice = createSlice({
  name: 'selectedCampaign',
  initialState: initialSelectedCampaignSlice,
  reducers: {
    setActiveCampaign: (state: ISelectedCampaign, {payload: campaign}: PayloadAction<Campaign>) => {
      state.campaign = campaign;
      state.isChildCampaign = !!campaign?.parent;
      return state;
    },
    setActiveCampaignId: (state, {payload: id}: PayloadAction<string | undefined> ) => {
      state.activeCampaignId = id ? +id : undefined;
      return state;
    },
    setIsReviewed: (state, action) => {
      const isReviewed = action.payload;
      state.campaign = {...state.campaign, isReviewed};
      return state;
    },
  },
});
