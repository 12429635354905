import ToggleSwitch from '../../../../../../../../shared/components/ToggleSwitch';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {setIsAddonsOnly} from '../../../../../../../../store/brand/offerBrandSlice';
import {IAllStates} from '../../../../../../../../store/rootReducer';

interface IAdditionalItemToggleProps {
    offerItemId: number;
}

export function AdditionalItemToggle({offerItemId}: IAdditionalItemToggleProps) {
    const {offerModalStatus, initialOffer: {isAddonsOnly}} = useSelector((state: IAllStates) => state.offerBrand);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const onChange = (checked: boolean) => {
        dispatch(setIsAddonsOnly(checked));
    };

    return (
        <>
            <div className="d-flex align-items-center px-md-3 px-2 pt-2">
                <div>
                    <ToggleSwitch small={true}
                                  id="content-approval"
                                  onChange={(checked: boolean) => onChange(checked)}
                                  checked={isAddonsOnly}
                                  defaultChecked={isAddonsOnly}
                                  disabled={offerModalStatus !== 'negotiate'}
                    />
                </div>
                <h6 className="ml-md-2 ml-1 mb-0">
                    {t(`brand.campaign.briefing.overview_card.addons_only`)}
                </h6>
                <p className="mb-0 ml-1">
                    - {t(`brand.campaign.briefing.overview_card.add-ons-only-desc`)}
                    <span className="ml-1 font-weight-bold">Offer ID: #{offerItemId}</span>
                </p>
            </div>
        </>
    );
}
