import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Campaign} from '../../../../model/campaign/Campaign';
import {IOffer} from '../../../../model/offer/IOffer';
import {IOfferItems} from '../../../../model/offer/IOfferItems';
import {User} from '../../../../model/user/User';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import CustomDatePicker from '../../../../shared/components/DatePicker';
import {
    calculateUploadUntil,
    formatDate,
    isSelectedDateValid,
    removeTimeZoneFromDate
} from '../../../../shared/functions/Functions';
import usePagination from '../../../../shared/hooks/usePagination';
import {plannerAgency} from '../../../../store/agency/plannerSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../utils/toasters';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {IPlannerPlatforms} from '../../../influencer/campaign/dto/IMyCampaigns';
import {INewEvent} from '../../../influencer/planner/components/CreateEventModal';
import {AgencyPlannerService} from '../service';
import {setUpdateInfluencerList} from '../../../../store/agency/agencySlice';

interface ICreateEventModalProps {
    createEvent: INewEvent;
    setCreateEvent: React.Dispatch<SetStateAction<INewEvent>>;
    setSelectedEvent?: React.Dispatch<SetStateAction<iManageList>>;
    plannerPlatforms: IPlannerPlatforms[];
    setIsEditModalOpened?: React.Dispatch<SetStateAction<boolean>>;
    isCreateModalOpened: boolean;
    isDisabled?: boolean;
    contentCreators: User[];
    handleCloseModal: () => void;
}

const CreateEventModal = ({
                              createEvent,
                              contentCreators,
                              plannerPlatforms,
                              setCreateEvent,
                              isCreateModalOpened,
                              setIsEditModalOpened,
                              setSelectedEvent,
                              handleCloseModal,
                              isDisabled = false,
                          }: ICreateEventModalProps) => {
    const dispatch = useDispatch();
    const {events} = useSelector((reduxState: IAllStates) => reduxState.plannerAgency);
    const {filterParams, setFilterParams} = usePagination({});
    const {t} = useTranslation();
    const {selectedCampaign: selectedFilterCampaign} = useSelector((state: IAllStates) => state.plannerAgency);
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
    const [selectedOffer, setSelectedOffer] = useState<IOffer>();
    const isOfferSelected = !!selectedOffer?.offerItems?.length;
    const [selectedPlatform, setSelectedPlatform] = useState<IOfferItems>();
    const dataArrPostingType = selectedOffer?.offerItems?.filter(offer => offer.nbPosts > (offer?.eventNbPosts ?? 0))?.sort((a, b) => b.socialPlatform.name > a.socialPlatform.name ? 1 : -1) ?? [];
    const handleChangeTimepicker = (newValue: Date | null, name: string) => {
        setCreateEvent(prev => ({...prev, [name]: newValue}));
    };
    const numReviewsDays = selectedCampaign?.isReviewed ? selectedCampaign?.reviewDays : 0;
    const changeCampaignHandler = (paramKey: string, paramValue: string | number) => {
        const selected = campaigns?.find(campaign => campaign?.offers?.[0]?.id === +paramValue);
        setSelectedOffer(selected?.offers?.[0]);
        setSelectedCampaign(selected);
    };
    const changeContentCreatorHandler = (paramKey: string, paramValue: string | number) => {
        setCreateEvent(prev => ({...prev, [paramKey]: +paramValue}))
    }
    const changeOfferHandler = (paramKey: string, paramValue: string | number) => {
        if (paramKey === 'offerItemId' && paramValue === '') {
            setSelectedPlatform(undefined);
        }
        setCreateEvent(prev => ({...prev, [paramKey]: +paramValue}));
    };
    const handleCreateEvent = async () => {
        if (!selectedCampaign?.id) {
            return WarningToast(t('influencer.planner.chose_campaign'));
        }

        if (!selectedOffer?.user?.id) {
            return WarningToast(t('influencer.planner.choose_influencer'));
        }
        if (createEvent.offerItemId === 0) {
            WarningToast(t('influencer.planner.chose_platform'));
        } else {
            await AgencyPlannerService.createNewEvent(createEvent, Number(selectedOffer?.user?.id)).then(response => {
                dispatch(plannerAgency.actions.setEvents(events?.concat(response)));
                setCreateEvent({
                    caption: null,
                    postingDate: new Date().toISOString(),
                    offerItemId: 0,
                });
                setSelectedCampaign(undefined);
                setSelectedOffer(undefined);
                SuccessToast(t('influencer.planner.successCreated'));
                dispatch(setUpdateInfluencerList({}));
                getPlannerCampaigns();
                handleCloseModal();
                if (setSelectedEvent && setIsEditModalOpened) {
                    const data = AgencyPlannerService.getPlannerModalData(response?.[0]?.id);
                    data?.then(res => {
                        setSelectedEvent(res);
                        setIsEditModalOpened(prev => !prev);
                    });
                }

            }).catch(error => ErrorToast(error));
        }
    };
    const getPlannerCampaigns = async () => {
        await AgencyPlannerService.getPlannerData({...filterParams, perPage: 0}).then(response => {
            setCampaigns(response.data?.map((campaign: Campaign) => ({
                ...campaign,
                offerId: campaign?.offers?.[0]?.id
            })));
        }).catch(error => ErrorToast(error));
    };

    useEffect(() => {
        getPlannerCampaigns();
        // eslint-disable-next-line
    }, [JSON.stringify(filterParams)]);
    useEffect(() => {
        if (Number(createEvent?.offerItemId) && selectedOffer) {
            setSelectedPlatform(selectedOffer?.offerItems?.find(offer => offer?.id === +createEvent?.offerItemId));
        }
    }, [createEvent?.offerItemId, selectedOffer]);
    useEffect(() => {
        if (createEvent?.campaign?.id && !!campaigns?.length) {
            const specificCampaignUsers = campaigns?.find(campaign => campaign.id === createEvent?.campaign?.id)?.offers?.[0]?.user;
            setSelectedCampaign(createEvent?.campaign);
            const currentOffer = campaigns
                ?.find(campaign => campaign?.offers?.[0].offerItems
                    ?.find(offer => offer.id === createEvent?.offerItemId))
            setSelectedOffer({
                ...currentOffer?.offers?.[0],
                user: createEvent?.acceptedUser || specificCampaignUsers || createEvent?.campaign?.offers?.[0]?.user
            } as IOffer)

        }
        // eslint-disable-next-line
    }, [createEvent?.campaign?.id, JSON.stringify(campaigns)]);
    useEffect(() => {
        if (selectedFilterCampaign?.id && !!campaigns.length) {
            changeCampaignHandler('campaignId', selectedFilterCampaign?.id);
        }
        // eslint-disable-next-line
    }, [selectedFilterCampaign?.id, campaigns?.length]);
    useEffect(() => {
        if (createEvent?.contentCreatorId) {
            setSelectedOffer(undefined);
            changeCampaignHandler('campaignId', 0);
        }
    }, [createEvent?.contentCreatorId]);
    return (
        <Modal
            show={isCreateModalOpened}
            centered
            onHide={handleCloseModal}
            keyboard={false}
        >
            <Modal.Body key={(selectedOffer?.id)}>
                <div className="border-bottom mb-3">
                    <h5>
                        {t('influencer.planner.schedulePosting')}
                    </h5>
                </div>
                <div className="my-3">
                    <CustomAutocomplete dataArray={contentCreators}
                                        isWithImage={true}
                                        selectedStateImage={selectedOffer?.user?.profilePhoto?.path}
                                        defaultArray={selectedOffer?.user as unknown as [] || plannerPlatforms?.map(platform => platform.acceptedUser)?.find(user => user.id === createEvent?.contentCreatorId)}
                                        inputLabel="firstName" photoLabel="profilePhoto.path"
                                        setFilterState={setFilterParams}
                                        key={selectedOffer?.user?.id}
                                        disableClearable
                                        isDisabled={(!!selectedOffer?.user?.id && !createEvent?.contentCreatorId) || isDisabled}
                                        changeEventKey="title"
                                        queryFilterKey="contentCreatorId"
                                        inputLabelTranslation={t('influencer.planner.selectContentCreator')}
                                        handleChangeParams={changeContentCreatorHandler}/>
                </div>
                {(!!selectedOffer?.user?.id || !!createEvent?.contentCreatorId) && <div className="my-3">
                    <CustomAutocomplete
                        dataArray={campaigns?.filter(campaign => campaign.offers?.find(offer => offer.user.id === createEvent?.contentCreatorId))}
                        isWithImage={false}
                        inputLabel="title"
                        defaultArray={campaigns?.filter(campaign => campaign.id === (selectedCampaign?.id))[0] as unknown as []}
                        // defaultArray={createEvent?.campaign as unknown as Campaign[]}
                        isLongSelected={selectedOffer?.isLongTerm}
                        key={selectedOffer?.id}
                        setFilterState={setFilterParams}
                        isDisabled={(!!selectedOffer?.user?.id && !createEvent?.contentCreatorId) || isDisabled}
                        changeEventKey="title" queryFilterKey="offerId"
                        inputLabelTranslation={t('influencer.planner.selectCampaign')}
                        handleChangeParams={changeCampaignHandler}/>
                </div>}
                {isOfferSelected && <>
                    <div className="my-3">
                        <CustomAutocomplete
                            dataArray={dataArrPostingType}
                            isWithImage={true} inputLabel="socialPlatform.name" isDisabled={isDisabled}
                            photoLabel="socialPlatform.path"
                            defaultArray={selectedOffer?.offerItems?.find(offer => offer.id === Number(createEvent?.offerItemId)) as unknown as IOfferItems[]}
                            selectedStateImage={selectedPlatform?.socialPlatform?.path as string}
                            queryFilterKey="offerItemId"
                            key={selectedOffer?.user?.id}
                            inputLabelTranslation={t('brand.campaign.manage.postingType')}
                            handleChangeParams={changeOfferHandler}/>
                        {selectedPlatform?.id && <span className="text-warning d-flex mt-2 justify-content-end">
                            Posts {selectedPlatform?.eventNbPosts ?? 0} / {selectedPlatform?.nbPosts}
                        </span>}
                        {!dataArrPostingType.length && (
                            <p className="text-danger">
                                {t('influencer.planner.error_posting_type')}
                            </p>
                        )}
                    </div>
                    <div className="my-3">
                        <CustomDatePicker label={t('brand.campaign.manage.postingDate')}
                                          value={createEvent?.postingDate}
                                          disableFrom={removeTimeZoneFromDate(selectedCampaign?.publishFrom ?? '')}
                                          disableTo={removeTimeZoneFromDate(selectedCampaign?.publishTo ?? '')}
                                          onChange={(event: Date | null) => handleChangeTimepicker(event, 'postingDate')}/>
                        {!isSelectedDateValid(createEvent?.postingDate ?? '', selectedCampaign?.publishFrom, selectedCampaign?.publishTo) &&
                            <p className="text-danger">
                                {t('influencer.planner.notInRange')}{formatDate(selectedCampaign?.publishFrom as string)} - {formatDate(selectedCampaign?.publishTo as string)}
                            </p>}
                    </div>
                    <p>
                        {t('influencer.planner.uploadContentUntil')}:
                        {calculateUploadUntil(numReviewsDays, createEvent?.postingDate as string)}
                    </p>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                    handleCloseModal();
                    setSelectedOffer(undefined);
                }}>
                    {t('general.button.cancel')}
                </Button>
                <Button variant="primary" onClick={handleCreateEvent}
                        disabled={!isSelectedDateValid(createEvent?.postingDate ?? '', selectedCampaign?.publishFrom, selectedCampaign?.publishTo)}>
                    {t('general.button.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default CreateEventModal;
