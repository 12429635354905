import React from 'react';
import { IconBook } from '../../Icons';
import {checkCampaignTabsVisibility} from "../../functions/Functions";
import {useSelector} from "react-redux";
import {IAllStates} from "../../../store/rootReducer";
import {UserRoles} from "../../../enum/UserRoles";

interface IMobileMenuProps {
    tabs: string[];
    classes: string;
    handleChange: (event: React.SyntheticEvent, newValue: string) => void;
    tabKey: string;
}

export function MobileMenu({tabs, classes, handleChange, tabKey}: IMobileMenuProps) {
    const {userType} = useSelector((state: IAllStates) => state.auth.user);
    const isBrandType = userType === UserRoles.BRAND || userType === UserRoles.BRAND_MASTER;
    const getCorrectBrandImg = (key:string) => {
        switch (key) {
            case 'book':
                return 'explore';
            case 'manage':
                return 'planner';
            case 'measure':
                return 'reporting';
            default:
                return key;
        }
    }
    return (
        <>
            <div className={`tabs-mobile ${classes}`}>
                {tabs?.map((tab: string, i: number) => {
                    return (
                    <div
                        className="tab"
                        onClick={(e) => {
                            handleChange(e, tab?.split('-')[1]);
                        }}
                        hidden={isBrandType ? !checkCampaignTabsVisibility(tab?.split('-')[1]) : false}
                        key={i}
                    >
                        <span>
                           {['book', 'contracts'].includes(tab?.split('-')[1]) ?
                                <div className={`p-2 rating ${tabKey !== tab?.split('-')[1] ? "bg-white" : "bg-primary"}`}>
                                    <IconBook width="24" height="24" color={`${tabKey !== tab?.split('-')[1] ? "#9ea5ab" : "#fff"}`} ></IconBook>
                                </div>
                            :    <img
                                    alt=''
                                    width="40"
                                    height="40"
                                    src={`/icons/img-${getCorrectBrandImg(tab?.split('-')[1])}${tabKey !== tab?.split('-')[1] ? ".png" : "-active.png" }`}
                                />
                            }
                            <p>{tab?.split('-')[0]}</p>
                        </span>
                    </div>
                    );
                })}
            </div>
        </>
    );
}