import React, {useEffect, useState} from 'react';
import {InfluencerContractService} from '../../../../service';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../store/rootReducer';
import {ErrorToast} from '../../../../../../../utils/toasters';
import {IContract} from '../../../../../../../model/shared/IContract';
import ContractHistoryDetail from '../../../../../../brand/campaign/contracts/contract/modal/tabs/history/ContractHistoryDetail';

export function ContractHistoryTab() {
    const {campaignId} = useSelector((state: IAllStates) => state.contractInfluencer);
    const [historyContracts, setHistoryContracts] = useState<IContract[]>([]);
    useEffect(() => {
        InfluencerContractService.getContractHistory(campaignId)
            .then((response) => {
                setHistoryContracts(response);
            }).catch(err => ErrorToast(err));
        // eslint-disable-next-line
    }, []);
    return (
        <>
            {historyContracts.map(contract => (
                <ContractHistoryDetail key={contract.id} contract={contract}/>
            ))
            }
        </>
    );
};