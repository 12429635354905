import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../../../../../enum/InternalRoutes';
import {BriefingService} from '../service';
import {ErrorToast, SuccessToast} from '../../../../../utils/toasters';
import {
    createCampaignInitialData,
    ICreateCampaign,
    ICreateCampaignRequest,
    IEditCampaignModalProps,
} from '../dto/ICreateCampaign';
import {multipartFormData} from '../../../../../utils';
import {BRIEFING_URL_MODAL} from '../../../../../utils/endpoints';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {actions} from '../../../../../store/authentication/authRedux';
import {ClientService, IClientParams} from '../../../client/service';
import {Company} from '../../../../../model/user/Company';
import {CampaignCreateStepOne} from './CampaignCreateStepOne';
import {CampaignCreateStepTwo} from './CampaignCreateStepTwo';
import {ILongTermCampaign, ILongTermFilter} from '../dto/ILongTermCamaign';
import { ConfirmationModal } from '../../../../../shared/components/alert/ConfirmationModal';

interface ICampaignModalProps {
    show: boolean;
    closeModal: () => void;
    editDetail?: IEditCampaignModalProps;
    campaignId?: number;
    longTermCampaignId?: number;
}

export function CampaignCreateModal({
                                        show,
                                        closeModal,
                                        editDetail,
                                        campaignId,
                                        longTermCampaignId,
                                    }: ICampaignModalProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const [filterParams, setFilterParams] = useState<IClientParams>({
        page: 1,
        perPage: 10,
        type: 'supplier',
        campaignId: String(campaignId ?? 0),
        displayName: '',
        parentId: longTermCampaignId,
    });
    const [longTermFilterParams, setLongTermFilterParams] = useState<ILongTermFilter>({
        page: 1,
        perPage: 10,
        campaignTitle: '',
    });
    const [companies, setCompanies] = useState<Company[]>([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [campaignData, setCampaignData] = useState<ICreateCampaign>();
    const [longTermCampaigns, setLongTermCampaigns] = useState<ILongTermCampaign[]>([]);
    const [createCampaign, setCreateCampaign] = useState<ICreateCampaignRequest>(createCampaignInitialData);
    const [files, setFiles] = useState<Blob[] | MediaSource[] | undefined>();
    const [createCampaignStep, setCreateCampaignStep] = useState(1);
    const {user: {favoriteCampaigns, company}} = useSelector((state: IAllStates) => state.auth);
    const [selectedLongCampaign, setSelectedLongCampaign] = useState<ILongTermCampaign>();
    const canDoLongTerm = company?.package?.canDoLongTerm;
    const isStepperVisible = (campaignData?.hasSuppliers || campaignData?.hasClients) || canDoLongTerm;

    const dispatch = useDispatch();
    useEffect(() => {
        show && BriefingService.getBriefingModalData(editDetail?.isEdit ? campaignId : 0)
            .then((data) => {
                setCampaignData(data);
                if (editDetail?.isEdit) {
                    setCreateCampaign({
                        ...createCampaign,
                        title: data?.info?.title,
                        campaignSharingType: undefined,
                    });
                } else if (!!longTermCampaignId) {
                    setCreateCampaign({
                        ...createCampaign,
                        parentId: longTermCampaignId,
                    });
                }
            })
            .catch(err => ErrorToast(err));
        return () => {
            setCreateCampaign(createCampaignInitialData);
            setCreateCampaignStep(1);
            setCampaignData(undefined);
            setFiles(undefined);
        };
        // eslint-disable-next-line
    }, [show]);

    useEffect(() => {
        show && ClientService.getClientsOrSuppliers(filterParams)
            .then((response) => {
                setCompanies(response.data);
            });
        return () => setCompanies([]);
    }, [JSON.stringify(filterParams), show]);

    useEffect(() => {
        show && BriefingService.getLongTermContracts(longTermFilterParams)
            .then((response) => {
                setLongTermCampaigns(response.data);
                setSelectedLongCampaign(response?.data?.find(campaign => campaign.id === longTermCampaignId));
            });
        return () => setLongTermCampaigns([]);
    }, [show, JSON.stringify(longTermFilterParams)]);

    const startCampaignBriefing = (id: number) => {
        history.push(`${InternalRoutes.BRAND_CAMPAIGN_CREATE}/${id}/step1`);
    };
    const editCampaign = () => {
        setIsSubmitted(true);
        if (!(!!createCampaign?.title)) {
            return;
        }
        multipartFormData(files, `${BRIEFING_URL_MODAL}/${campaignId}`, JSON.stringify(createCampaign))
            .then((res) => {
                SuccessToast(t('general.toasts.successEditCampaign'));
                if (closeModal) {
                    const avoidMutation = JSON.parse(JSON.stringify((favoriteCampaigns)));
                    const copy = [...avoidMutation ?? []];
                    const index = copy?.findIndex((campaign) => campaign.id === res.data.id);
                    if (index >= 0) {
                        copy[index].campaignImages = res?.data?.campaignImages;
                        copy[index].title = res?.data?.title;
                        dispatch(actions.updateFavoriteCampaign(copy));
                    }
                    closeModal();
                }
                setIsSubmitted(false);
                setIsLoading(false);
            }).catch(error => {
            ErrorToast(error);
            setIsLoading(false);
        });
    };

    const createNewCampaign = () => {
        setIsSubmitted(true);
        if (!(!!createCampaign?.title) || !files) {
            return;
        }
        if (campaignData?.hasSuppliers || campaignData?.hasClients || canDoLongTerm) {
            if (createCampaignStep === 1) {
                setIsSubmitted(false);
                return setCreateCampaignStep(2);
            }
        }
        setIsLoading(true);
        multipartFormData(files, BRIEFING_URL_MODAL, JSON.stringify(createCampaign), 'post')
            .then((res) => {
                SuccessToast(t('general.toasts.successCreatedCampaign'));
                if (closeModal) {
                    closeModal();
                }
                setIsSubmitted(false);
                setIsLoading(false);
                setCreateCampaign(createCampaignInitialData);
                setFiles(undefined);
                const {data} = res;
                startCampaignBriefing(data?.id);
            }).catch(error => {
            ErrorToast(error);
            setIsLoading(false);
        });
    };

    const nextStep = () => {
        if (editDetail?.isEdit) {
            if (!!filterParams.parentId ) {
                setConfirmationModal(true);
            } else {
                editCampaign();
            }
        } else {
           if ( createCampaignStep === 1) {
            createNewCampaign();
           } else {
                if (!!filterParams.parentId ) {
                    setConfirmationModal(true);
                } else {
                    createNewCampaign();
                }
           }
        }
    };

    return (
        <div onClick={e => e.stopPropagation()}>
            <ConfirmationModal title={t('brand.campaign.book.offer.modal.are_you_sure')}
                               description={''}
                               htmlDescription={<>
                                    {t('brand.campaign.briefing.overview_card.are_you_sure_linking')}
                                    <div className='mt-2'>
                                        {t('brand.campaign.briefing.overview_card.are_you_sure_linking_text')}
                                    </div>
                               </>}
                               show={confirmationModal}
                               action={() => {
                                   editDetail?.isEdit ? editCampaign() : createNewCampaign();
                                   setConfirmationModal(false);
                               }}
                               closeModal={() => setConfirmationModal(false)}/>
            <Modal show={show}
                   onHide={closeModal}
                   centered
                   id="create-campaign-modal"
            >
                <Modal.Body>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>{t(`brand.campaign.briefing.${editDetail?.isEdit ? 'edit_campaign' : 'starting_new_campaign'}`)}</h4>
                        {!editDetail?.isEdit && isStepperVisible &&
                            <p className="text-muted">Step {createCampaignStep}/2</p>
                        }
                    </div>
                    {(createCampaignStep === 1 && !editDetail?.isContractType) ?
                        <CampaignCreateStepOne campaignData={campaignData}
                                               createCampaign={createCampaign}
                                               setCreateCampaign={setCreateCampaign}
                                               isSubmitted={isSubmitted}
                                               setFiles={setFiles}
                                               files={files}
                        />
                        :
                        <CampaignCreateStepTwo campaignData={campaignData}
                                               createCampaign={createCampaign}
                                               setCreateCampaign={setCreateCampaign}
                                               isSubmitted={isSubmitted}
                                               setFilterParams={setFilterParams}
                                               companies={companies}
                                               longTermCampaigns={longTermCampaigns}
                                               setLongTermFilterParams={setLongTermFilterParams}
                                               selectedLongCampaign={selectedLongCampaign}
                                               setSelectedLongCampaign={setSelectedLongCampaign}
                        />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={closeModal}>
                        {t('general.button.cancel')}
                    </Button>
                    <Button disabled={isLoading} variant="primary" onClick={() => nextStep()}>
                        {editDetail?.isEdit ? t('general.button.edit') : t('general.button.continue')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
