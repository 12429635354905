import {useTranslation} from 'react-i18next';
import {Button, Card} from 'react-bootstrap';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {formatDate} from '../../../../../shared/functions/Functions';
import {ILinkedCampaigns} from '../../../../../model/briefing/overview/ICampaignOverview';

interface ILinkedCampaignsCardProps {
    campaign: ILinkedCampaigns;
    openBriefingAction: (id: string) => void;
}
export function LinkedCampaignsCard({campaign, openBriefingAction}: ILinkedCampaignsCardProps) {
    const {t} = useTranslation();

    return (
        <>
            <Card className="mb-2">
                <Card.Body className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <CustomImageHandler classes="user-img-rounded mr-2"
                                            photoPath={campaign?.campaign?.campaignImages?.[0]?.thumbnail}
                                            thumbnailPath={campaign?.campaign?.campaignImages?.[0]?.thumbnail}
                                            altTag="Campaign photo"/>
                        <div>
                            <h6 className="mb-0">{campaign?.campaign?.title ?? '-'}</h6>
                            <p className="mb-0 text-primary">
                                {campaign?.campaign?.publishFrom ?
                                    formatDate(campaign?.campaign?.publishFrom as string) : 'DD.MM.YYYY.'} -
                                {campaign?.campaign?.publishTo ? formatDate(campaign?.campaign?.publishTo as string) : ' DD.MM.YYYY.'}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <Button onClick={() => openBriefingAction(String(campaign?.campaign?.id ?? 0))} variant="outline-primary">
                            {t('general.button.open')}
                        </Button>
                    </div>
                </Card.Body>
            </Card>

        </>
    )
}
