import React, {useEffect, useState} from 'react';
import {SelectContentCreator} from '../components/SelectContentCreator';
import {Campaign} from '../../../model/campaign/Campaign';
import usePagination from '../../../shared/hooks/usePagination';
import {IMyOffers} from '../../influencer/dashboard/dto/IDashboard';
import {ErrorToast} from '../../../utils/toasters';
import {IMyCampaignsFilterProps} from '../../influencer/campaign/dto/IMyCampaigns';
import {gtmService} from '../../../utils/gtm.service';
import {AgencyService} from '../service';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import MobileDashboard from '../../influencer/dashboard/MobileDashboard';
import DesktopDashboard from '../../influencer/dashboard/DesktopDashboard';
import {Helmet} from 'react-helmet';
import {IOfferWithCampaign} from '../../influencer/profile/profile-view';
import {ICampaignOverview} from '../../../model/briefing/overview/ICampaignOverview';

export function AgencyDashboard() {
    const [contractCampaigns, setContractCampaigns] = useState<ICampaignOverview[]>([]);
    const [offers, setOffers] = useState<IOfferWithCampaign[]>([]);
    // const [isBillingModalOpened, setIsBillingModalOpened] = useState(false);
    // const [isWarningOpened, setIsWarningOpened] = useState(false);
    const {totalPages, setTotalPages, filterParams, handlePages, setFilterParams} = usePagination({perPage: 10});
    const [myOffers, setMyOffers] = useState<IMyOffers>();
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);

    useEffect(() => {
        if (selectedInfluencer?.id) {
            AgencyService.getDashSummary(selectedInfluencer?.id ?? 0).then(response => {
                setMyOffers(response.myOffers);
            }).catch(error => ErrorToast(error));
        }
    }, [selectedInfluencer?.id]);

    useEffect(() => {
        if (selectedInfluencer?.id) {
            (filterParams as IMyCampaignsFilterProps)?.sortType && AgencyService.getDashOffers({
                ...filterParams,
                perPage: 9,
            }, selectedInfluencer?.id ?? 0).then(response => {
                setTotalPages(Math.ceil(response.count / response.perPage));
                setOffers(response.data);
                gtmService('search');
            }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [filterParams, selectedInfluencer?.id]);

    useEffect(() => {
        if (selectedInfluencer?.id) {
             AgencyService.getDashContracts({
            }, selectedInfluencer?.id ?? 0).then(response => {
                setContractCampaigns(response.data);
            }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [selectedInfluencer?.id]);

    useEffect(() => {
        if (!(filterParams as IMyCampaignsFilterProps)?.sortType) {
            setFilterParams((prev: any) => ({...prev, sortType: 'DESC', sortBy: 'date'}))
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Helmet>
                <title>App | influence.vision</title>
            </Helmet>
            <SelectContentCreator>
                <>
                    <MobileDashboard filterParams={filterParams} setFilterParams={setFilterParams}
                                     handlePages={handlePages} totalPages={totalPages}
                                     myOffers={myOffers as IMyOffers} offers={offers} contractCampaigns={contractCampaigns}/>
                    <DesktopDashboard filterParams={filterParams}
                                      setFilterParams={setFilterParams}
                                      handlePages={handlePages} totalPages={totalPages}
                                      myOffers={myOffers as IMyOffers} isAgency={true} offers={offers}
                                      contractCampaigns={contractCampaigns}/>
                </>
            </SelectContentCreator>
        </>
    );
};
