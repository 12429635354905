import * as React from 'react';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import {Badge} from '@mui/material';
import {ChangeEvent, useEffect} from 'react';
import {IoAddSharp, IoRemoveSharp} from 'react-icons/io5';

interface ICount {
    defaultCount: number;
    setNumberItems?: (key: number, event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, nbPosts?: number, itemId?: number) => void;
    id: number;
    itemId?: number;
    maxCount?: number;
}

export default function Counter({defaultCount, setNumberItems, id, itemId, maxCount}: ICount) {
    const [count, setCount] = React.useState(defaultCount ?? 0);

    useEffect(() => {
        if (setNumberItems) {
            setNumberItems(id, undefined, count, itemId);
        }
        // eslint-disable-next-line
    }, [count]);

    useEffect(() => {
        if (defaultCount === 0) {
            setCount(0);
        }
    }, [defaultCount]);


    return (
        <Box
            sx={{
                color: 'action.active',
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    marginBottom: 2,
                },
                '& .MuiBadge-root': {
                    marginRight: 4,
                },
            }}
        >
            <div>
                <ButtonGroup className="d-flex align-items-center">
                    <Button
                        disabled={count === 0}
                        aria-label="reduce"
                        onClick={() => {
                            setCount(prevState => (prevState > 0) ? prevState - 1 : prevState);
                        }}
                    >
                        <IoRemoveSharp/>
                    </Button>
                    <Badge className="mb-0" color="secondary" badgeContent={`${count}`}/>
                    {(!!maxCount) &&
                        <>
                            <span className="mx-2">/</span>
                            <Badge className="mb-0" color="secondary" badgeContent={`${maxCount}`}/>
                        </>
                    }
                    <Button
                        disabled={!!maxCount && (count >= maxCount)}
                        aria-label="increase"
                        onClick={() => {
                            setCount(prevState => (prevState >= 0) ? prevState + 1 : prevState);
                        }}
                    >
                        <IoAddSharp/>
                    </Button>
                </ButtonGroup>
            </div>
        </Box>
    );
}
