import {Col, Dropdown, DropdownButton, Row} from 'react-bootstrap';
import {CampaignFilter} from '../../influencer/campaign/component/CampaignFilter';
import {InfluencerCampaignsList} from '../../influencer/campaign/component/InfluencerCampaignsList';
import EmptyState from '../../../shared/components/placeholder/EmptyState';
import {Pagination} from '../../../shared/components/Pagination';
import React, {useEffect, useState} from 'react';
import {IMyCampaignItem, IMyCampaignsFilterProps} from '../../influencer/campaign/dto/IMyCampaigns';
import usePagination from '../../../shared/hooks/usePagination';
import {AgencyCampaignListItem} from './components/AgencyCampaignListItem';
import {ErrorToast} from '../../../utils/toasters';
import {AgencyService} from '../service';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';

export function AgencyAllCampaigns() {
    const [campaigns, setCampaigns] = useState<IMyCampaignItem[]>([]);
    const {totalPages, setTotalPages, filterParams, handlePages, setFilterParams} = usePagination({
        perPage: 10, otherKeys: {
            sortBy: 'date', sortType: 'DESC',
        },
    });
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);

    useEffect(() => {
        if ((filterParams as IMyCampaignsFilterProps)?.sortBy) {
            AgencyService.getMyCampaigns({...filterParams, influencerId: selectedInfluencer?.id}).then(response => {
                setCampaigns(response.data);
                setTotalPages(Math.ceil(response.count / response.perPage));
            }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [filterParams, selectedInfluencer?.id]);

    const handlePerPage = (numberPerPage: number) => {
        setFilterParams((prev: any) => ({...prev, perPage: numberPerPage}));
    };

    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className={`${!!campaigns?.length ? 'myCampaigns' : ''} pr-2`}>
                        <CampaignFilter 
                            filterParams={filterParams} 
                            setFilterParams={setFilterParams} 
                            isAgency={true}
                            isMyCampaigns
                        />
                        {!!campaigns?.length ? (
                            <InfluencerCampaignsList isAgency={true} isContracts={filterParams?.isContracts}>
                                <>
                                    {campaigns?.map((campaign, index) => (
                                        <AgencyCampaignListItem key={`${index}-${campaign?.title}`}
                                                                isContracts={filterParams?.isContracts}
                                                                campaign={campaign}/>
                                    ))}
                                </>
                            </InfluencerCampaignsList> 
                        ) : (
                            <div className="card mt-3">
                                <div className="card-body ">
                                    <EmptyState/>
                                </div>
                            </div>
                        )}
                    </div>
                    {/*<div className="d-block d-md-none">*/}
                    {/*    {!!campaigns?.length ? <CardView isSeeYourCampaign={true} campaigns={campaigns}/> :*/}
                    {/*        <EmptyState iconPath="/icons/add-button.png"/>}*/}
                    {/*</div>*/}
                    {/*<div className="d-block d-md-none">*/}
                    {/*    {!!campaigns?.length ? campaigns?.map(campaign => (*/}
                    {/*        <InfluencerCampaignCard key={campaign?.id} campaign={campaign}/>*/}
                    {/*    )) : <EmptyState iconPath="/icons/add-button.png"/>}*/}
                    {/*</div>*/}
                    <div className="d-flex justify-content-between align-items-start flex-wrap mt-3 mt-md-0">
                        {totalPages > 1 &&
                            <div id="influencer-pagination"
                                 className="d-flex justify-content-end mb-md-5 mb-2 pb-2 pb-lg-0 mb-lg-0 col-12 col-md-auto">
                                <Pagination page={filterParams?.page} totalPages={totalPages}
                                            handlePagination={handlePages}/>
                            </div>
                        }
                        <DropdownButton
                            className="pl-1 font-weight-normal d-flex justify-content-end col-12 col-md-auto"
                            variant="transparent"
                            title={`Campaigns per page : ${filterParams?.perPage}`}
                        >
                            {filterParams?.perPage !== 10 && <Dropdown.Item onClick={() => handlePerPage(10)}>
                                10
                            </Dropdown.Item>}
                            {filterParams?.perPage !== 30 && <Dropdown.Item onClick={() => handlePerPage(30)}>
                                30
                            </Dropdown.Item>}
                            {filterParams?.perPage !== 60 && <Dropdown.Item onClick={() => handlePerPage(60)}>
                                60
                            </Dropdown.Item>}
                            {filterParams?.perPage !== 90 && <Dropdown.Item onClick={() => handlePerPage(90)}>
                                90
                            </Dropdown.Item>}
                        </DropdownButton>
                    </div>
                </Col>
            </Row>
        </>
    );
}
