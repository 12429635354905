import {User} from '../../model/user/User';
import {requests} from '../../utils';
import {
    AGENCY_DASH_URL,
    AGENCY_URL, AUTH_AGENCY_URL,
    IMPERSONATE_URL,
} from '../../utils/endpoints';
import {BaseModel} from '../../model/shared/BaseModel';
import {IAssignAgencyUser} from './influencers/dto/IAssignAgencyUser';
import {ICompanyUser} from '../shared/user/dto/ICompanyUser';
import {IRegisterAgencyInvite} from './influencers/dto/IRegisterAgency';
import {IExploreCompanyFilterParams, IExploreFilterParams, IExploreItem} from '../influencer/explore/dto/Explore';
import {ICompany} from '../shared/user/dto/ICompany';
import {IMyCampaignItem, IMyCampaignsFilterProps} from '../influencer/campaign/dto/IMyCampaigns';
import {IOfferManagementFilter} from '../influencer/offer/dto/IOfferManagementFilter';
import {IOfferManagement} from '../influencer/offer/dto/IOfferManagement';
import {ICampaignOverview} from '../../model/briefing/overview/ICampaignOverview';
import {IInviteContractInfluencer, IInviteInfluencer} from '../../model/book/Invites';
import {IDeclineOffer} from '../influencer/offer/dto/IDeclineOffer';
import {IOffer} from '../../model/offer/IOffer';
import {IAgencyDashFilter} from './influencers/dto/IAgencyDashFilter';
import {IContract} from '../../model/shared/IContract';

export const AgencyService = {
    getAgencyUsers: (params?: IAgencyDashFilter): Promise<BaseModel<User[]>> => requests.get(`${AGENCY_DASH_URL}/influencers`, params),
    getAgencyUsersHeader: (params?: any): Promise<BaseModel<User[]>> => requests.get(`${AGENCY_DASH_URL}/header`, params),
    getAgencyCompanyUsers: (params?: any): Promise<ICompanyUser[]> => requests.get(`${AGENCY_URL}/company/users`, params),
    assignAgencyUser: (body: IAssignAgencyUser): Promise<User> => requests.post(`${AGENCY_DASH_URL}/assign/user`, body),
    unAssignAgencyUser: (body: IAssignAgencyUser): Promise<User> => requests.post(`${AGENCY_DASH_URL}/un-assign/user`, body),
    inviteUsers: (body: IRegisterAgencyInvite) => requests.post(`${AUTH_AGENCY_URL}/invite-influencers`, body),
    getExploreCampaigns: (params?: IExploreFilterParams): Promise<BaseModel<IExploreItem[]>> => requests.get(`${AGENCY_URL}/explore/campaigns`, params),
    getAllCompanies: (params: IExploreCompanyFilterParams): Promise<BaseModel<ICompany[]>> => {
        return requests.get(`${AGENCY_URL}/explore/company`, params);
    },
    getMyCampaigns: (params: IMyCampaignsFilterProps): Promise<BaseModel<IMyCampaignItem[]>> => {
        return requests.get(`${AGENCY_URL}/agency-campaigns`, params);
    },
    getOfferList: (params: IOfferManagementFilter): Promise<IOfferManagement> => requests.get(`${AGENCY_URL}/offer/management`, params),
    getAgencyOfferModalDetail: (campaignId: string, isLongTerm: boolean,  userId?: number): Promise<ICampaignOverview> => requests.get(`${AGENCY_URL}/offer/modal/${campaignId}/${userId}`
      , {isLongTerm}),
    sendInitialAgencyOffer: (campaignId: string, body: IInviteInfluencer, userId?: number) => requests.post(`${AGENCY_URL}/offer/initial/${campaignId}/${userId}`, body),
    sendAgencyCounterOffer: (campaignId: string, body: IInviteInfluencer, userId?: number) => requests.put(`${AGENCY_URL}/offer/${campaignId}/${userId}`, body),
    withdrawAgencyOffer: (campaignId: string, userId?: number) => requests.put(`${AGENCY_URL}/offer/withdraw/${campaignId}/${userId}`, {}),
    declineAgencyOffer: (campaignId: string, body: IDeclineOffer, userId?: number) => requests.put(`${AGENCY_URL}/offer/decline/${campaignId}/${userId}`, body),
    acceptAgencyOffer: (campaignId: string, userId?: number) => requests.put(`${AGENCY_URL}/offer/accept/${campaignId}/${userId}`, {}),
    getOfferHistory: (campaignId: string, userId?: number): Promise<IOffer[]> => requests.get(`${AGENCY_URL}/offer/modal/history/${campaignId}/${userId}`),
    getMatchedInfluencers: (campaignId: string, filterParams?: any): Promise<BaseModel<User[]>> => requests.get(`${AGENCY_URL}/explore/matched/${campaignId}`, filterParams),
    getMyCampaignsDeclined: (params: IMyCampaignsFilterProps): Promise<BaseModel<IMyCampaignItem[]>> => requests.get(`${AGENCY_URL}/agency-campaigns/declined`, params),
    getBriefingDetails: (id: string): Promise<ICampaignOverview> => requests.get(`${AGENCY_URL}/briefing/summary/${id}`),
    getDashSummary: (id: number) => requests.get(`${AGENCY_URL}/influencer-dashboard/summary/${id}`),
    getDashCampaigns: (pagination: IMyCampaignsFilterProps, id: number) => requests.get(`${AGENCY_URL}/influencer-dashboard/summary/campaigns/${id}`, pagination),
    getDashOffers: (pagination: IMyCampaignsFilterProps, id: number) => requests.get(`${AGENCY_URL}/influencer-dashboard/summary/offers/${id}`, pagination),
    getDashContracts: (pagination: any, id: number) => requests.get(`${AGENCY_URL}/influencer-dashboard/summary/contracts/${id}`, pagination),
    removeInfluencer: (id: number) => requests.delete(`${AGENCY_URL}/company/influencer/${id}/remove`),
    toggleBannerSeen: (id: number, params?: any) => requests.put(`${IMPERSONATE_URL}/toggle/banner/seen/${id}`, {}),
};

export const AgencyContractService = {
    getAgencyContractModalDetail: (campaignId: string, userId?: number): Promise<ICampaignOverview> => requests.get(`${AGENCY_URL}/contract/modal/${campaignId}/${userId}`),
    sendInitialAgencyContract: (campaignId: string, body: IInviteContractInfluencer, userId?: number) => requests.post(`${AGENCY_URL}/contract/initial/${campaignId}/${userId}`, body),
    sendAgencyCounterContract: (campaignId: string, body: IInviteContractInfluencer, userId?: number) => requests.put(`${AGENCY_URL}/contract/${campaignId}/${userId}`, body),
    withdrawAgencyContract: (campaignId: string, userId?: number) => requests.put(`${AGENCY_URL}/contract/withdraw/${campaignId}/${userId}`, {}),
    declineAgencyContract: (campaignId: string, body: IDeclineOffer, userId?: number) => requests.put(`${AGENCY_URL}/contract/decline/${campaignId}/${userId}`, body),
    acceptAgencyContract: (campaignId: string, userId?: number) => requests.put(`${AGENCY_URL}/contract/accept/${campaignId}/${userId}`, {}),
    getContractHistory: (campaignId: string, userId?: number): Promise<IContract[]> => requests.get(`${AGENCY_URL}/contract/modal/history/${campaignId}/${userId}`),
}
