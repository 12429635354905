import { ICampaignOverview } from "../../../../model/briefing/overview/ICampaignOverview";

const headerPdf = async (campaign: ICampaignOverview) => {
  return `
        <header class="d-flex justify-content-between">
            <div style="margin-right: 40px">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 909 213.4" style="enable-background:new 0 0 909 213.4;" xml:space="preserve" width="150">
                    <g>
                        <path d="M249.3,29.2c5,0,9.2-4.1,9.2-9.2c0-5-4.1-9.2-9.2-9.2c-5,0-9.2,4.1-9.2,9.2C240.2,25.1,244.3,29.2,249.3,29.2z"/>
                        <path d="M403.2,0c-7.9,0-14.5,2.4-18.9,6.9c-5,5-7.7,12.8-7.7,22.5v6.1H365v13.3h11.6V122h15V48.9h26.6V35.7h-26.7V30
                            c0-11.4,4.3-16.7,13.4-16.7c3.9,0,7.1,0.7,11.9,2.1l1.6,0.4V2.5l-0.8-0.3C413.1,0.6,409,0,403.2,0z"/>
                        <rect x="432.9" y="0.6" width="14.8" height="121.3"/>
                        <path d="M500.7,123.8c11.6,0,20.7-4.5,27.6-13.7V122h14.8V35.3h-14.8v49.4c0,14.8-9.9,25.2-24,25.2c-13.8,0-22.1-9-22.1-24.2V35.3
                            h-15v53.5C467.3,110.1,480.4,123.8,500.7,123.8z"/>
                        <path d="M599.3,33.6c-23.7,0-41.6,19.4-41.6,45.2c0,25.8,18.6,45.2,43.2,45.2c14.6,0,25.6-5,35.6-16.2l0.8-0.9l-9.6-8.6l-0.8,0.9
                            c-7.5,7.9-15.7,11.5-25.7,11.5c-15,0-26.2-10.2-28.3-25.6h66.5l0.1-1.1c0.2-2,0.2-3.3,0.2-4.5C639.8,52,623.5,33.6,599.3,33.6z
                            M573,72.5c2.2-15.3,12.7-25.9,26-25.9c16.4,0,23.9,13.1,25.5,25.9H573z"/>
                        <path d="M695.9,33.6c-11.6,0-20.9,4.6-27.6,13.7V35.3h-15V122h15V72.6c0-14.8,9.9-25.2,24-25.2c13.8,0,22.1,9,22.1,24.2V122h15
                            V68.5C729.4,47.3,716.2,33.6,695.9,33.6z"/>
                        <path d="M812.3,98.1c-7.6,8.2-15.8,12.2-24.8,12.2c-16.7,0-29.7-13.8-29.7-31.5v-0.3c0-17.6,12.7-31.4,28.9-31.4
                            c11.4,0,18.6,5.9,24.7,12.3l0.9,0.9l10-10.6l-0.8-0.8c-6.7-7.2-16.8-15.4-34.6-15.4c-24.5,0-44.4,20.3-44.4,45.2v0.3
                            c0,24.7,19.9,44.9,44.4,44.9c18,0,28.3-8.9,35.3-16.7l0.8-0.9l-9.7-9.1L812.3,98.1z"/>
                        <path d="M868.5,33.6c-23.7,0-41.6,19.4-41.6,45.2c0,25.8,18.6,45.2,43.2,45.2c14.6,0,25.6-5,35.6-16.2l0.8-0.9l-9.6-8.6l-0.8,0.9
                            c-7.5,7.9-15.7,11.5-25.7,11.5c-15,0-26.2-10.2-28.3-25.6h66.5l0.1-1.1c0.2-2,0.2-3.3,0.2-4.5C909,52,892.8,33.6,868.5,33.6z
                            M842.2,72.5c2.2-15.3,12.7-25.9,26-25.9c16.4,0,23.9,13.1,25.5,25.9H842.2z"/>
                        <polygon points="272.2,196.1 247.2,139.6 239.9,139.6 269.8,205.2 274.6,205.2 304.3,139.6 297.2,139.6 	"/>
                        <rect x="315.5" y="139.6" width="6.5" height="65.1"/>
                        <path d="M362,168.4c-10.3-2.9-18.1-5.6-18.1-12.4v-0.2c0-6.5,6.6-11.1,15.9-11.1c6.5,0,13.5,2.3,19.2,6.4l1,0.7l3.6-5.4l-1-0.7
                            c-6.6-4.5-15.1-7.2-22.7-7.2c-13.1,0-22.6,7.4-22.6,17.6v0.2c0,11.7,11.5,15,22.7,18.2c10.6,2.9,18.1,5.5,18.1,13v0.2
                            c0,6.9-7,11.8-16.7,11.8c-7.8,0-15.8-3-22.6-8.3l-1-0.8l-3.8,5l0.9,0.7c7,5.9,17.1,9.5,26.3,9.5c13.6,0,23.5-7.7,23.5-18.4v-0.2
                            C384.7,175.5,374,171.6,362,168.4z"/>
                        <rect x="399.3" y="139.6" width="6.5" height="65.1"/>
                        <path d="M453.5,138.1c-19,0-33.8,15-33.8,34.1v0.2c0,19,14.8,33.8,33.7,33.8c18.9,0,33.7-15,33.7-34.1v-0.3
                            C487.1,152.9,472.3,138.1,453.5,138.1z M453.5,200c-15.1,0-27-12.2-27-27.8V172c0-15.8,11.5-27.8,26.8-27.8
                            c15.2,0,27.1,12.2,27.1,27.8v0.2C480.4,188.1,468.8,200,453.5,200z"/>
                        <path d="M529.5,138.1c-11.2,0-18.7,5.8-22.9,12.5v-11h-6.4v65.1h6.4v-36.5c0-13.4,9.8-23.9,22.4-23.9c13.3,0,21.2,8.7,21.2,23.2
                            v37.3h6.4v-37.9C556.6,149.1,546.2,138.1,529.5,138.1z"/>
                        <polygon points="241.8,122 256.8,122 256.8,53.6 241.8,38.6 	"/>
                        <path d="M338.6,122h15V68.5c0-21.2-13.1-34.9-33.5-34.9c-11.6,0-20.9,4.6-27.6,13.7V35.3h-15v62.9l15,15V72.6
                            c0-14.8,9.9-25.2,24-25.2c13.8,0,22.1,9,22.1,24.2V122z"/>
                        <rect x="41.6" y="59.7" width="10.1" height="120"/>
                        <polygon points="71.6,178.6 171.1,123.4 171.1,115.9 71.6,60.9 71.6,71.8 159.8,119.6 71.6,167.2 	"/>
                        <polygon points="69.8,23.7 189.7,23.7 189.7,206.1 7.3,206.1 7.3,23.7 23.7,23.7 23.7,16.4 0,16.4 0,213.4 197.1,213.4 197.1,16.4 
                            69.8,16.4 	"/>
                        <path d="M46.7,28.6c4.7,0,8.6-3.9,8.6-8.6c0-4.7-3.9-8.6-8.6-8.6s-8.6,3.9-8.6,8.6C38.1,24.7,42,28.6,46.7,28.6z"/>
                    </g>
                </svg>
            </div>
            <div class="d-flex w-100 justify-content-between">
                <div class="text-left d-flex">
                    <div>
                        <p class="m-0 mb-2 font-14 font-weight-extra-bold">Booking Confirmation:</p>
                         ${campaign?.parent?.id ? `
                            <h6 class="m-0 font-weight-normal">${campaign?.title}</h6>
                         ` : `
                            <h4 class="m-0 font-weight-normal">${campaign?.title}</h4>
                         `}
                    </div>
                    ${campaign?.parent?.id ? `
                        <div class="ml-4">
                            <p class="mb-2 font-weight-extra-bold">Part of:</p>
                            <h6 class="m-0 font-weight-normal">${campaign?.parent?.title}</h6>
                        </div>
                    ` : ''}
                </div>
                <div class="text-right">
                    <p class="m-0 mb-2 font-14">Campaign ID:</p>
                    <h4 class="m-0 font-weight-normal">${campaign?.id}</h4>
                </div>
            </div>
        </header>
  `;
};

export default headerPdf;
