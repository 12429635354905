import { ICampaignOverview } from "../../../../../model/briefing/overview/ICampaignOverview";

const briefingCampaignCreatorsAudience = (t: any, campaignOverview: ICampaignOverview) => {
  return `
    <div class="">
        <div class="avoid-brake pt-10">
            <h3 class="text-upper">${t("brand.campaign.briefing.audience")}</h3>
            <div class="d-flex" style="gap: 24px">
                <div>
                    <h4>${t("brand.campaign.book.offer.modal.location")}</h4>
                    <p>${
                    !!campaignOverview?.audienceCountriesArray?.length
                        ? campaignOverview?.audienceCountriesArray
                            ?.map((country) => `${t(`general.countries.${country}`)}`)
                            .join(", ")
                        : "-"
                    }</p>
                </div>

                <div>
                    <h4>${t("brand.campaign.book.offer.modal.gender")}</h4>
                    <p>${t(
                    `general.gender.${
                        campaignOverview?.audienceGender
                        ? campaignOverview?.audienceGender
                        : "undefined"
                    }`
                    )}</p>
                </div>

                <div>
                    <h4>${t("brand.campaign.briefing.age_range")}</h4>
                    <p>${campaignOverview?.ageMin ? campaignOverview?.ageMin : ""}-${
                        campaignOverview?.ageMax ? campaignOverview?.ageMax : ""
                    }</p>
                </div>

            </div>
        </div>
        <div class="pt-4 avoid-brake">
            <h4>${t("brand.campaign.briefing.audience_desc")}</h4>
            <p>${
            campaignOverview?.audienceDesc ? campaignOverview?.audienceDesc : "-"
            }</p>
        </div>
    </div>
`;
};

export default briefingCampaignCreatorsAudience;
