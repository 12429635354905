import React from 'react';
import {useTranslation} from 'react-i18next';
import {formatDate} from '../../../../../../../../shared/functions/Functions';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../../store/rootReducer';

interface IHeaderProps {
    createdAt: string;
    offerItemId: number;
    status?: string;
    isRequested?: boolean;
    offerModalStatus?: string;
    isEditable?: boolean;
}

export function OfferHeader({createdAt, offerItemId, isRequested, isEditable, offerModalStatus}: IHeaderProps) {
    const {t} = useTranslation();
    return (
        <div className="px-3 d-flex justify-content-between">
            <p className="text-muted mb-0">
                Offer ID: #{offerItemId}
            </p>
            <div className="d-flex justify-content-center">
                <div>
                    {isRequested &&
                        <p className="mr-2 status invite text-dark">
                            {t(`general.campaign_status.invite`)}
                        </p>
                    }
                </div>
                {offerModalStatus !== 'negotiate' &&
                    <p className="text-muted text-right mb-0">
                        {isEditable ?
                            t('brand.campaign.book.offer.modal.received_at') :
                            t('brand.campaign.book.offer.modal.sent_at')}
                        - {formatDate(createdAt)} - {createdAt.substring(11, 16)}
                    </p>
                }
            </div>
        </div>
    );
};
