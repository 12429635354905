import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import React from 'react';
import {IInfluencerCampaignListProps} from '../../../influencer/campaign/component/InfluencerCampaignListItem';
import {checkOfferType} from '../../../../shared/functions/Functions';

export function AgencyCampaignListItem({campaign, isContracts}: IInfluencerCampaignListProps) {
    const {title, id, campaignImages, company, offers, contracts} = campaign;
    const {t} = useTranslation();
    const history = useHistory();
    const goToOverview = () => {
        history.push(`/agency/campaign/${id}/overview`);
    };
    const offer = offers?.[0] || contracts?.[0];
    const isLongTerm = offer?.isLongTerm;
    const isAddonsOnly = offer?.isAddonsOnly;
    const isOnTop = offer?.isOnTop;
    const hasBadge = offer?.isLongTerm || offer?.isAddonsOnly || offer?.isOnTop;

  return (
      <tr>
        <td className="text-left">
          <div onClick={goToOverview} className="d-flex align-items-center cursor-pointer pr-3">
            <CustomImageHandler photoPath={campaignImages?.[0]?.thumbnail as string}
                                altTag={campaignImages?.[0]?.title as string}
                                classes="mr-2 default-radius img-cover"/>
            <h6 className="font-weight-bold ">{title}</h6>
          </div>
        </td>
        {!isContracts && (
          <td>
            {hasBadge && <div className={'custom-badge long-term'}>
              {isLongTerm ? t(`brand.campaign.briefing.overview_card.linked`) : isOnTop ? t(`brand.campaign.briefing.overview_card.on_top`)
                : isAddonsOnly ? t(`brand.campaign.briefing.overview_card.addons_only`) : null
              }
            </div>}
          </td>
        )}

        <td>
          <OverlayTrigger
            key={id}
            placement="bottom"
            overlay={
              <Tooltip id={`${id}`}>
                {offer?.user?.displayName}
              </Tooltip>
            }
          >
            <div>
              <CustomImageHandler photoPath={offer?.user?.profilePhoto?.thumbnail as string}
                                  altTag={offer?.user?.profilePhoto?.thumbnail as string}
                                  style={{width: '40px', height: '40px'}}
                                  classes="user-img-rounded cursor-pointer"/>
            </div>
          </OverlayTrigger>
        </td>
        <td className="text-primary font-weight-semi-bold">{company?.displayName ?? '-'}</td>
        <td className="font-weight-semi-bold">{(isAddonsOnly ? '-' : offer?.totalPostings) ?? 0}</td>
        <td className="font-weight-semi-bold euro-symbol"> {priceSplitter(Math.round(offer?.price ?? 0))}</td>
        <td>
                <span className={`status ${offer?.status}`}>
                {t(`general.offerStatus.${offer?.status}`)}
                </span>
        </td>
        <td>
          <Button onClick={goToOverview} className="mx-3" size="sm">
            {t('general.open')}
          </Button>
        </td>
      </tr>
    );
}
