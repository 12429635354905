import React from 'react';
import {Card, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../enum/InternalRoutes';

interface IRegistrationTypeModalProps {
    show: boolean;
    closeModal: () => void;
}

export function RegistrationTypeModal({show, closeModal}: IRegistrationTypeModalProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const handleNavigate = (route: string) => {
        history.push(route);
        closeModal();
    };

    return (
        <>
            <Modal
                keyboard={false}
                centered show={show} onHide={() => closeModal()}>
                <Modal.Header closeButton className="pt-0"/>
                <Modal.Body>
                    <h3 className="text-center mb-3">{t('auth.createProfile')}</h3>

                    <Card className="registration-card cursor-pointer shadow-none mb-3"
                        //   onClick={() => handleNavigate(`${InternalRoutes.BRAND_REGISTER}/1`)}>
                        onClick={() => window.location.href = 'https://www.influencevision.com/en/book-a-demo/'}>
                        <div className="d-flex align-items-center">
                            <img className="registration-img" src="/icons/registration/brand-reg.jpeg"
                                 alt="registration-img"/>
                            <div className="ml-3">
                                <h5 className="text-primary">{t('auth.createProfileBrand')}</h5>
                                <p className="text-muted mb-0">
                                    {t('auth.createProfileBrandDesc')}
                                </p>
                            </div>
                        </div>
                    </Card>
                    <Card className="registration-card cursor-pointer shadow-none mb-3"
                          onClick={() => handleNavigate(`${InternalRoutes.INFLUENCER_REGISTRATION}/1`)}>
                        <div className="d-flex align-items-center">
                            <img className="registration-img" src="/icons/registration/influencer-reg.jpeg"
                                 alt="registration-img"/>
                            <div className="ml-3">
                                <h5 className="text-warning">{t('auth.createProfileInfluencer')}</h5>
                                <p className="text-muted mb-0">
                                    {t('auth.createProfileInfluencerDesc')}
                                </p>
                            </div>
                        </div>
                    </Card>
                    <Card className="registration-card cursor-pointer shadow-none "
                          onClick={() => window.location.href = 'https://calendly.com/creator-success-team/creator-management-onboarding-call?month=2024-03'}>
                        <div className="d-flex align-items-center">
                            <img className="registration-img" src="/icons/registration/agency-reg.jpeg"
                                 alt="registration-img"/>
                            <div className="ml-3">
                                <h5 className="text-info">{t('auth.createProfileAgency')}</h5>
                                <p className="text-muted mb-0">
                                    {t('auth.createProfileAgencyDesc')}
                                </p>
                            </div>
                        </div>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <p className="text-muted">
                            {t('auth.already_have_profile')}
                        </p>
                        <h6 className="cursor-pointer" onClick={closeModal}>
                            {t('general.button.login')}
                        </h6>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
