import React, {ChangeEvent, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../store/rootReducer';
import _debounce from 'lodash/debounce';
import {DeclineOffer} from '../../../../../../influencer/offer/modal/offer-modal/tabs/offer/DeclineOffer';
import {
    InfluencerOfferHeader
} from '../../../../../../influencer/offer/modal/offer-modal/tabs/offer/InfluencerOfferHeader';
import {AgencyOfferPlatformTable} from './AgencyOfferPlatformTable';
import {Form} from 'react-bootstrap';
import {AgencyAdditionalItemsTable} from './AgencyAdditionalItemsTable';
import {AgencyOptionalItemsTable} from './AgencyOptionalItemsTable';
import {setPersonalOfferDescription} from '../../../../../../../store/agency/agencyOfferSlice';

export function AgencyOfferTab() {
    const {t} = useTranslation();
    const {
        initialOffer,
        isEditable,
        campaign,
        offerModalStatus,
        isCharity,
    } = useSelector((state: IAllStates) => state.offerAgency);
    const dispatch = useDispatch();
    const changeHandler = _debounce(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = event?.target?.value;
            dispatch(setPersonalOfferDescription(value));
        },
        300,
    );

    return (
        <>
            <DeclineOffer declineComment={campaign?.offers[0]?.declineComment}
                          declineReason={campaign?.offers[0]?.declineReason}
                          status={campaign?.offers[0]?.status}/>
            <InfluencerOfferHeader createdAt={campaign?.offers[0]?.createdAt ?? ''}
                                   offerModalStatus={offerModalStatus}
                                   isEditable={isEditable} isRequested={campaign?.offers[0]?.isRequested}/>
            <div className="p-md-3">
                {!campaign?.offers[0]?.isAddonsOnly ? (
                    <AgencyOfferPlatformTable/>
                ) : (
                    <div className="d-flex align-items-center mb-2">
                        <h6 className="ml-md-2 ml-1 mb-0">
                            {t(`influencer.offer_management.modal.addons_only`)}
                        </h6>
                        <p className="mb-0 ml-1">
                            - {t(`influencer.offer_management.modal.add-ons-only-desc`)}
                            <span className="ml-1 font-weight-bold">({campaign?.parent?.title})</span>
                        </p>
                    </div>
                )}
                {!campaign?.offers[0]?.isLongTerm &&
                    <AgencyAdditionalItemsTable/>
                }
                {(offerModalStatus === 'negotiate' && (campaign?.offerPlaceholder?.distribution && campaign?.offerPlaceholder?.distribution?.length > 0) && !isCharity) &&
                    <AgencyOptionalItemsTable/>
                }

                {offerModalStatus === 'negotiate' &&
                    <>
                        <h6 className="mt-3">{t('influencer.offer_management.modal.add_personal_offer')}</h6>
                        <Form.Control
                            id="basic-textarea"
                            onChange={changeHandler}
                            defaultValue={initialOffer.personalOffer}
                            as="textarea"
                            name="personalOffer"
                            rows={3}
                            disabled={campaign?.isCharity}
                            placeholder={t('influencer.offer_management.modal.add_personal_offer_placeholder')}/>
                    </>
                }
                {(offerModalStatus !== 'negotiate' && campaign?.offers[0]?.personalOffer) &&
                    <div className="mt-4">
                        <div>
                            <h5>{t('influencer.offer_management.modal.add_personal_offer')}</h5>
                        </div>
                        <h5 className="text-muted mb-0">{campaign?.offers[0]?.personalOffer}</h5>
                    </div>
                }

            </div>
        </>
    )
}
