import React, {SetStateAction} from 'react';
import {TFunction} from 'react-i18next';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {IParams} from '../../../../../../../model/shared/IParams';
import {CalendarSwitcher} from '../../../../../../../shared/components/CalendarSwitcher';
import {CustomImageHandler} from '../../../../../../../shared/components/CustomImageHandler';
import {RatingStar} from '../../../../../../../shared/components/RatingStar';
import {getCorrectFilterTitle, hasAccessRights} from '../../../../../../../shared/functions/Functions';
import {iManageList} from '../../dto/IManageCalendar';
import {manageBrand} from '../../../../../../../store/brand/manageBrandSlice';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../store/rootReducer';
import {AccessRightEnum} from '../../../../../../../enum/AccessRightEnum';

interface iCalendarCreateModalHeader {
    t: TFunction<'translation'>;
    state: iManageList;
    setSelectedEventId: React.Dispatch<SetStateAction<number>>
}


export const CalendarCreateModalHeader = ({state, t, setSelectedEventId}: iCalendarCreateModalHeader) => {
    const history = useHistory();
    const location = useLocation();
    const {id} = useParams<IParams>();
    const dispatch = useDispatch();
    const {isClient} = useSelector((reduxState: IAllStates) => reduxState.auth.user);
    const increaseStep = () => {
        setSelectedEventId(state?.nextId);
    };

    const navigateToMeasure = (userId: number) => {
        history.push(`/brand/campaign/measure/${id}`, {influencerId: userId})
    };

    const reduceStep = () => {
        setSelectedEventId(state?.previousId);
    };

    const openBookingModal = () => {
        if (!isClient && hasAccessRights(AccessRightEnum.CAN_VIEW_BOOKING) && !location.pathname.includes('campaign/dashboard')) {
            dispatch(manageBrand.actions.setModalInfo({
                offerId: state?.offerItem?.offer?.id, userId: state?.offerItem?.offer?.user?.id
            }));
            dispatch(manageBrand.actions.setShowBookModal(true));
        }
    };

    return (
        <div
            className="border-bottom m-0 pb-2 d-flex align-items-center justify-content-between flex-column flex-md-row  pl-md-3">
            <div className="d-flex align-items-center justify-content-between w-100">
                <div className="d-flex align-items-center">
                    <CustomImageHandler
                        altTag="User Profile"
                        classes="middle-rounded-logo cursor-pointer"
                        photoPath={state?.offerItem?.offer?.user?.profilePhoto?.path ?? null}
                        thumbnailPath={state?.offerItem?.offer?.user?.profilePhoto?.thumbnail}
                        onClick={() => openBookingModal()}
                    />
                    <div className="ml-2">
                        <h6 className="mb-0 cursor-pointer" onClick={openBookingModal}>
                            {state?.offerItem?.offer?.user?.displayName}
                        </h6>
                        <div className="d-flex align-items-center">
                            {state?.offerItem?.offer?.user?.totalRating ?
                                <div className="mr-3"><RatingStar rating={state?.offerItem?.offer?.user?.totalRating}/>
                                </div> : ''}
                            <div className={`status ${state?.status} w-fit-content  `}>
                                {getCorrectFilterTitle(state?.status, t)}
                            </div>
                            {state?.offerItem?.offer?.isLongTerm &&
                                <div className={'custom-badge long-term ml-1 font-weight-normal'}>
                                    {t(`brand.campaign.briefing.overview_card.long_term`)}
                                </div>}
                            {state?.offerItem?.offer?.isAddonsOnly &&
                                <div className={'custom-badge additional ml-1 font-weight-normal'}>
                                    {t('influencer.offer_management.modal.additional')}
                                </div>}
                        </div>
                    </div>
                    <div className="d-md-flex d-none align-items-center mt-1 ml-3">
                        {state?.status === 'reported' && hasAccessRights(AccessRightEnum.CAN_VIEW_MEASURE) &&
                            <span className="text-underline font-weight-normal text-muted ml-2 cursor-pointer"
                                  onClick={() => navigateToMeasure(state?.offerItem?.offer?.user?.id ?? 0)}>
                                    {t('brand.campaign.manage.goToReporting')}
                                </span>}
                    </div>
                </div>
                <div className="d-md-none d-flex align-items-center mt-1">
                    {state?.status === 'reported' && hasAccessRights(AccessRightEnum.CAN_VIEW_MEASURE) &&
                        <span className="text-underline font-weight-normal text-muted ml-2 cursor-pointer"
                              onClick={() => navigateToMeasure(state?.offerItem?.offer?.user?.id ?? 0)}>
                                    {t('brand.campaign.manage.goToReporting')}
                                </span>}
                </div>
            </div>
            <CalendarSwitcher increaseStep={increaseStep} reduceStep={reduceStep}
                              isReduceDisabled={!state?.previousId}
                              isIncreaseDisabled={!state?.nextId}/>

        </div>
    );
};
