import {TextField} from '@material-ui/core';
import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BsFillTrashFill, BsPencilFill} from 'react-icons/bs';
import {IoIosCloseCircle} from 'react-icons/io';
import {IPicture} from '../../../../../../model/shared/IPicture';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import {Fancybox} from '../../../../../../shared/components/Fancybox';
import {UploadImage} from '../../../../../../shared/components/UploadImage';
import {formatDate, getCorrectImageThumbnail, getExternalLink} from '../../../../../../shared/functions/Functions';
import {ErrorToast, SuccessToast} from '../../../../../../utils/toasters';
import {iManageAttachments, iManageList} from '../../../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {IExternalLink, initialExternalState} from '../../../../planner/components/preview/PreviewContentFiels';
import {InfluencerReportingService} from '../../../service';
import {IReportingUpdate, ISelectedEvent} from '../../ReportingCampaignCard';

interface IYourContent {
    selectedAttachments: iManageAttachments[];
    externalLinks?: string[];
    isUploadPossible?: boolean;
    isButtonDisabled?: boolean;
    eventId?: number;
    setUpdateData?: React.Dispatch<SetStateAction<IReportingUpdate>>;
    setSelectedFile?: React.Dispatch<SetStateAction<IPicture>>;
    setIsAttachmentClicked?: React.Dispatch<SetStateAction<boolean>>;
    setSelectedOfferItem: React.Dispatch<SetStateAction<ISelectedEvent | undefined>>;
    isAdditional?: boolean;
    hideExternalSearch?: boolean;
    offerEvent?: iManageList;
}

const YourContent = ({
                         selectedAttachments,
                         setSelectedFile,
                         setIsAttachmentClicked,
                         externalLinks,
                         eventId,
                         setSelectedOfferItem,
                         isUploadPossible = false,
                         isAdditional = false,
                         isButtonDisabled = false,
                         setUpdateData,
                         hideExternalSearch,
                         offerEvent
                     }: IYourContent) => {
    const {t} = useTranslation();
    const [externalLink, setExternalLink] = useState<IExternalLink>(initialExternalState);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, id} = event.target;
        setExternalLink(prev => ({...prev, link: value, id: +id}))
    }
    const [showInputField, setShowInputField] = useState(false);
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    
    const submitAttachmentHandler = (isUpdate = false) => {
        if (isAdditional && setUpdateData) {
            setExternalLink(initialExternalState);
            setUpdateData(prev => ({...prev, externalLinks: prev?.externalLinks?.concat([externalLink?.link])}))
        } else {
            if (isUpdate) {
                InfluencerReportingService.updateExternalLink(externalLink.id, externalLink.link).then(response => {
                    if (response?.id) {
                        setExternalLink(initialExternalState);
                        setShowInputField(false);
                        SuccessToast(t('general.toasts.successUpdated'))
                        setSelectedOfferItem((prev: any) => ({
                            ...prev, event: {
                                ...prev?.event,
                                attachments: response?.attachments
                            }
                        }))
                    }
                }).catch(error => ErrorToast(error));
            } else {
                InfluencerReportingService.createExternalLink(externalLink?.id, externalLink?.link).then(response => {
                    if (response?.id) {
                        setSelectedOfferItem((prev: any) => ({
                            ...prev, event: {
                                ...prev?.event,
                                attachments: response?.attachments
                            }
                        }))
                        setShowInputField(false);
                        SuccessToast(t('general.toasts.successUpdated'))
                        setExternalLink(initialExternalState);
                    }
                }).catch(error => ErrorToast(error));
            }
        }
    }

    const handleCancelAttachmentHandler = () => {
        setShowInputField(false);
        setExternalLink(initialExternalState);
    }

    const removeExternalLink = (link: string, index: number) => {
        setUpdateData && setUpdateData(prev => ({
            ...prev,
            externalLinks: prev.externalLinks?.filter((singleLink, ind) => singleLink !== link && ind !== index)
        }))
    }

    useEffect(() => {
        if (files && setUpdateData && isUploadPossible) {
            setUpdateData(prev => ({...prev, attachments: files}));
        }
        // eslint-disable-next-line
    }, [files]);
    return (
        <div className="col-md-12 my-3">
            <h5>
                {t('influencer.planner.contentFiles')}
                {/* {t('influencer.reporting.yourContent')} */}
            </h5>
            {offerEvent?.status !== 'approved' && (
                <Row>
                    <div className="col-md-5 pl-md-0 my-1">
                        <UploadImage saveFile={setFiles} isDeleteHidden={true} isSmall isMultiple
                                    maxSize={Number(process.env.REACT_APP_PLANNER_MAX_FILE_SIZE)}
                                    disabled={isButtonDisabled} acceptedFormats="video/*, image/jpeg, image/png, image/jpg"
                                    classes="px-0"/>
                    </div>
                    {!hideExternalSearch && (
                        <div className={`col-md-1 d-flex justify-content-center ${showInputField ? 'invisible' : ''}`}>
                            <span className="text-muted mb-4 mb-md-0 mt-md-5">OR</span>
                        </div>
                    )}
                    {!hideExternalSearch && !showInputField && (
                        <div className="d-flex col pr-0">
                            <TextField
                                label={t('general.externalLink')}
                                variant="outlined"
                                size="small"
                                name="link"
                                id={String(eventId)}
                                value={externalLink?.link ?? ''}
                                onChange={changeHandler}
                                className="w-100"
                            />

                            <Col className='pr-0'>
                                <Button disabled={!externalLink?.link} variant="primary" className="ml-1"
                                        onClick={() => submitAttachmentHandler(false)}>
                                    {t('general.button.create')}
                                </Button>
                            </Col>
                        </div>
                    )}
                </Row>
            )} 
            <div className="row">
                {showInputField && isAdditional && (
                    <div className={`col-md-6 pr-md-0 my-1 ${showInputField ? 'invisible' : ''}`}>
                        {isAdditional && <ul className="pl-3 mt-2">
                            {externalLinks?.map((link, index) => (
                                <li key={`${link}-${index}`}>
                                    <a href={link} className="text-dark" target="_blank" rel="noreferrer">
                                        {link}
                                    </a>
                                    <IoIosCloseCircle size={20} className="text-danger cursor-pointer"
                                                        onClick={() => removeExternalLink(link, index)}/>
                                </li>
                            ))}
                        </ul>}
                    </div>
                )}
                <Fancybox>
                {selectedAttachments?.map((file, index) => {
                    return (
                        <div className="col-xl-4 pl-md-0 my-2"
                             key={file?.id}>
                            <div
                                className={`content-files-card my-2 my-md-0 border-${file?.isApproved ? 'primary' : 'warning'}`}>
                                <div className="row p-3 p-md-0">
                                    <div className="col-4">
                                        {!!file?.externalLink ? (
                                            <a href={getExternalLink(file?.externalLink)} target='_blank' className="cursor-pointer">
                                                <CustomImageHandler
                                                    photoPath={getCorrectImageThumbnail(!!file?.externalLink, file?.originalName ?? '', file?.thumbPath ?? '', file?.path)}
                                                    altTag="Event"
                                                    thumbnailPath={file?.thumbnail}
                                                    placeholder={getCorrectImageThumbnail(!!file?.externalLink, file?.originalName ?? '', file?.thumbPath ?? '', file?.path)}
                                                    classes="card-img cursor-pointer shadow-lg default-radius-sm"
                                                />
                                            </a>
                                        ) : (
                                                <div
                                                    data-fancybox="gallery"
                                                    className="cursor-pointer"
                                                    data-src={file?.externalLink ?? file?.path ?? '/icons/filePlaceholder.png'}
                                                >
                                                    <CustomImageHandler
                                                        classes="card-img cursor-pointer shadow-lg default-radius-sm"
                                                        photoPath={getCorrectImageThumbnail(!!file?.externalLink, file?.originalName ?? '', file?.thumbPath ?? '', file?.path)}
                                                        placeholder={getCorrectImageThumbnail(!!file?.externalLink, file?.originalName ?? '', file?.thumbPath ?? '', file?.path)}
                                                        altTag="Campaign image"/>
                                                </div>
                                        )}
                                    </div>
                                    <div className="col-8">
                                        <div
                                            className="pl-3 p-md-0 d-flex flex-column align-items-end justify-content-between h-100">
                                            <div className="d-flex flex-column w-100">
                                                <div className="d-flex justify-content-between">
                                                    {!showInputField ? (
                                                        <a href={getExternalLink(file?.externalLink) ?? file?.path} target="_blank"
                                                            className="w-75 word-break-word text-dark" rel="noreferrer">
                                                            {t('general.file')} {index + 1}/{selectedAttachments?.length} - <span
                                                            className="text-muted">{eventId}</span>
                                                        </a>
                                                    ) : (
                                                        (file?.externalLink && externalLink?.id === file?.id) ?
                                                            <TextField
                                                                label={t('general.externalLink')}
                                                                variant="outlined"
                                                                size="small"
                                                                name="link"
                                                                id={String(file?.id)}
                                                                focused={!!file?.externalLink?.length}
                                                                defaultValue={file?.externalLink ?? ''}
                                                                onChange={changeHandler}
                                                                className="w-100"
                                                            /> :
                                                            <span>{file?.id === externalLink?.id ? file?.externalLink : (
                                                                <a href={getExternalLink(file?.externalLink) ?? file?.path} target="_blank"
                                                                    className="w-75 word-break-word text-dark" rel="noreferrer">
                                                                    {t('general.file')} {index + 1}/{selectedAttachments?.length} - <span
                                                                    className="text-muted">{eventId}</span>
                                                                </a>
                                                            )}</span>
                                                    )}
                                                    {file?.externalLink && (!showInputField ? (
                                                        <BsPencilFill 
                                                            onClick={() => {
                                                                setExternalLink(prev => ({
                                                                    ...prev,
                                                                    link: file?.externalLink ?? '',
                                                                    id: file?.id
                                                                }))
                                                                setShowInputField(prev => !prev)
                                                            }}
                                                            size={15}
                                                            className="text-primary cursor-pointer"/> 
                                                    ) : (
                                                        <>
                                                            {file?.id === externalLink?.id && (
                                                                <Button 
                                                                    variant="primary" className="ml-1"
                                                                    disabled={!externalLink?.link}    
                                                                    onClick={() => submitAttachmentHandler(true)}>
                                                                    {t('general.button.update')}
                                                                </Button>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                                <span className="text-muted">
                                                    {formatDate(file?.createdAt)}
                                                </span>
                                                {!!file?.externalLink && (
                                                    <a
                                                        href={getExternalLink(file?.externalLink)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {t('general.externalLink')}
                                                    </a>
                                                )}
                                            </div>
                                            <div className="d-flex align-items-center">
                                                {file?.externalLink && showInputField && file?.id === externalLink?.id && (
                                                    <Button variant="outline-primary"
                                                            onClick={handleCancelAttachmentHandler}>
                                                        {t('general.button.cancel')}
                                                    </Button>
                                                )}
                                                {!file?.isApproved && !showInputField && (
                                                    <BsFillTrashFill className="text-muted cursor-pointer font-20"
                                                                     onClick={() => {
                                                                         if (setSelectedFile && setIsAttachmentClicked) {
                                                                             setIsAttachmentClicked(true);
                                                                             setSelectedFile(file as unknown as IPicture);
                                                                         }
                                                                     }}/>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                </Fancybox>
            </div>
        </div>
    );
};

export default YourContent;
