import {useTranslation} from 'react-i18next';
import {priceSplitter} from '../../../../../../../../utils/helpers/priceSplitter';
import {TotalTypes} from '../../../../../../../../enum/TotalTypes';

interface ITotals {
    price: any;
    title: TotalTypes;
    isCharity?: boolean;
    isGrandTotal?: boolean;
    feePercentage?: number;
    displayPlus?: boolean;
}

export const Totals = ({price, isGrandTotal, isCharity, feePercentage, displayPlus, title}: ITotals) => {
    const {t} = useTranslation();
    const isFee = title === TotalTypes.FEE;
    const isGreyText = title === TotalTypes.OFFER_ITEMS || title === TotalTypes.ADDITIONAL_ITEMS;
    return (
        <div className={`table-offer-items d-flex justify-content-between p-2 ${(isGreyText && 'table-head-color')} default-radius  ${!isFee && 'background-gray-100'}`}>
            {!isFee ? (
                <h6 className="mb-0">
                    {t(`brand.campaign.book.offer.modal.${title}`)} {!!feePercentage &&
                    <span> {`(${feePercentage ?? 0}%)`}</span>}
                </h6>
            ) : (
                <p className="mb-0">
                    {t(`brand.campaign.book.offer.modal.${title}`)} {!!feePercentage &&
                    <span> {`(${feePercentage ?? 0}%)`}</span>}
                </p>
            )}
            {isCharity ? (
                <>
                    {+priceSplitter(Math.round(price ?? 0)) > 0 ? (
                        <h5 className="text-primary mb-0 euro-symbol">{priceSplitter(Math.round(price ?? 0))}</h5>
                    ) : (
                        <h4 className='text-primary-dark mb-0'>{t('general.button.barter_deal')}</h4>
                    )}
                </>
            ) : (
               <>
                {!!isFee ? (
                    <p className={`mb-0 ${displayPlus ? 'euro-and-plus-symbol' : 'euro-symbol'}`}>{priceSplitter(Math.round(price ?? 0))}</p>
                ) : (
                    <h5 className={`text-primary mb-0 ${displayPlus ? 'euro-and-plus-symbol' : 'euro-symbol'}`}>{priceSplitter(Math.round(price ?? 0))}</h5>
                )}
                </>
            )}
        </div>
    );
};