import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IContentModal} from '../../app/brand/campaign/book/offer/dto/IContentModal';

export interface IManageBrandStore {
    selectedInfluencer?: IContentModal | undefined;
    modalInfo?: {
        userId: number,
        offerId: number;
    };
    showBookModal: boolean;
    showChildModal: boolean;
    triggerRescheduleList: boolean;
    previewEventModal: {
        eventId: number;
        showPreviewEventModal: boolean;
    };
};

const initialManageBrandSlice: IManageBrandStore = {
    selectedInfluencer: undefined,
    modalInfo: undefined,
    showBookModal: false,
    showChildModal: false,
    triggerRescheduleList: false,
    previewEventModal: {
        eventId: 0,
        showPreviewEventModal: false,
    }
};

export const manageBrand = createSlice({
    name: 'manageBrand',
    initialState: initialManageBrandSlice,
    reducers: {
        setSelectedInfluencer: (state, action) => {
            state.selectedInfluencer = action.payload;
            return state;
        },
        setModalInfo: (state, action) => {
            state.modalInfo = action.payload;
            return state;
        },
        setShowBookModal: (state, action) => {
            state.showBookModal = action.payload;
            return state;
        },
        setShowChildModal: (state: IManageBrandStore, {payload: showModal}: PayloadAction<boolean>) => {
            state.showChildModal = showModal;
            return state;
        },
        setTriggerRescheduleList: (state, action) => {
            state.triggerRescheduleList = action.payload;
            return state;
        },
        setOpenPreviewModal: (state, action) => {
            state.previewEventModal = action.payload;
            return state;
        },
    },
});
