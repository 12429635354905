import {TextField} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {FaCalendarAlt, FaPencilAlt} from 'react-icons/fa';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import {formatDate, truncateString} from '../../../../../../shared/functions/Functions';
import {IReportingUpdate, ISelectedEvent} from '../../ReportingCampaignCard';

interface Props {
    selectedOfferItem: ISelectedEvent;
    updateData: IReportingUpdate;
}

const ReportingHeader = ({selectedOfferItem, updateData}: Props) => {
    const {t} = useTranslation();
    const isLongTerm = selectedOfferItem?.offerItem?.socialPlatform?.isLongTerm;
    const isAddonsOnly = selectedOfferItem?.offerItem?.socialPlatform?.isAddonsOnly;
    return (
        <>
            <div
                className="col-12 border-bottom mb-3 pb-2 d-flex align-items-center
                        justify-content-between flex-column flex-md-row">
                <div
                    className="d-flex align-items-center justify-content-between w-100 flex-column flex-md-row pb-2 pb-md-0">
                    <div className="d-flex align-items-center w-100">
                        <CustomImageHandler
                            altTag="Company logo"
                            classes="h-80px img-cover rounded w-80px"
                            photoPath={selectedOfferItem?.campaign?.campaignImages?.[0]?.path as string}
                        />
                        <div className="ml-3">
                            <h6 className="mb-0 d-none d-md-block">
                                {selectedOfferItem?.campaign?.title ?? '-'}
                            </h6>
                            <h6 className="mb-0 d-md-none">
                                {truncateString(selectedOfferItem?.campaign?.title ?? '-', 40)}
                            </h6>
                            <div className="d-flex align-items-center">
                                {selectedOfferItem?.event?.status && <div className="d-flex align-items-center mt-1">
                                    <div
                                        className={`status ${selectedOfferItem?.event?.status} w-fit-content`}>
                                        {selectedOfferItem?.event?.status}
                                    </div>
                                </div>}
                                {isLongTerm &&
                                    <div className={'custom-badge long-term ml-1'}>
                                        {t(`brand.campaign.briefing.overview_card.linked`)}
                                    </div>}
                                {isAddonsOnly &&
                                    <div className={'custom-badge additional ml-1'}>
                                        {t('influencer.offer_management.modal.additional')}
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
                <div className="card">
                    <div className="card-body">
                        <span className="font-weight-semi-bold">
                            {t('influencer.planner.postingType')}
                        </span>
                        <div>
                            <img src={selectedOfferItem?.offerItem?.socialPlatform?.path}
                                 alt={selectedOfferItem?.offerItem?.socialPlatform?.name}/>
                            <span className="ml-2">
                                     {t(`general.platforms.${selectedOfferItem?.offerItem?.socialPlatform?.name}`)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
                <div className="card h-100">
                    <div className="card-body">
                                <span className="font-weight-semi-bold">
                                    {t('influencer.planner.postingDate')}
                                </span>
                        <div className="position-relative w-fit-content">
                            <FaCalendarAlt
                                className="text-danger font-20"/>
                            <div
                                className="position-absolute bg-white d-flex align-items-center justify-content-center border"
                                style={{
                                    width: 15,
                                    height: 15,
                                    top: '35%',
                                    left: '35%',
                                    borderRadius: '50%',
                                    boxShadow: '1px 1px 2px #00000026',
                                }}
                            >
                                <FaPencilAlt className="font-12 text-danger"/>
                            </div>
                            <span className="position-absolute ml-2">
                                {formatDate(updateData?.postingDate ? updateData?.postingDate : selectedOfferItem?.event?.postingDate)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <TextField
                    fullWidth
                    name="postingLink"
                    value={updateData?.postingLink ?? ''}
                    disabled
                    label={t('influencer.reporting.postingLink')}
                    type="text"
                    variant="outlined"
                    size="small"
                />
                <TextField
                    fullWidth
                    name="trackingLink"
                    className="mt-3"
                    value={selectedOfferItem?.event?.trackingLink ?? ''}
                    disabled
                    label={t('influencer.reporting.trackingLink')}
                    type="text"
                    variant="outlined"
                    size="small"
                />
            </div>
        </>
    );
};

export default ReportingHeader;
