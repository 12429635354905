import React from 'react';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import {setActiveTab} from '../../../../../../store/influencer/contractInfluencerSlice';
import TabPanel from '@mui/lab/TabPanel';
import {ContractContentTab} from './ContractContentTab';
import {ContractDistributionTab} from './ContractDistributionTab';
import {ContractLegalTab} from './ContractLegalTab';
import {FaCheck} from 'react-icons/fa';
import {scrollToTopRef} from '../../../../../../utils/helpers/ScrollToTop';
import {scrollToBottomRef} from '../../../../../../utils/helpers/scrollToBottomRef';
import {OfferComments} from '../../../../../../shared/components/offer-comments/OfferComments';
import {OverviewTab} from '../../offer-modal/tabs/OverviewTab';
import {ContractHistoryTab} from './history/ContractHistoryTab';
import {InfluencerContractTab} from './contract/InfluencerContractTab';

interface IOfferModalTabProps {
    offerModalRef: any;
}

export function ContractModalTabs({offerModalRef}: IOfferModalTabProps) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {
        activeTabModal,
        isStepperFinished,
        campaign,
        hasLegal,
        canWriteComment,
        isLegalChecked,
        isDistributionChecked,
        requiredFieldChangeColor,
        hasUnreadComments
    } = useSelector((state: IAllStates) => state.contractInfluencer);
    const isMobile = window.innerWidth <= 700;
    const isNotInitialOffer = !!campaign?.contracts[0]?.id;

    const handleTab = (event: React.SyntheticEvent, newValue: string) => {
        dispatch(setActiveTab(+newValue));
        if (requiredFieldChangeColor && activeTabModal === 1) {
            scrollToTopRef(offerModalRef);
        } else if (!isLegalChecked && activeTabModal === 3) {
            scrollToBottomRef(offerModalRef);
        } else if (!isDistributionChecked && activeTabModal === 2) {
            scrollToBottomRef(offerModalRef);
        } else {
            scrollToTopRef(offerModalRef);
        }
    };

    const checkIcon = (tabValue: number) => {
        if (activeTabModal > tabValue || isStepperFinished) {
            return <FaCheck/>;
        }
        return undefined;
    };

    return (
        <>
            <TabContext value={String(activeTabModal)}>
                <Box className="mb-3">
                    <TabList allowScrollButtonsMobile={isMobile} variant={isMobile ? 'scrollable' : 'fullWidth'}
                             onChange={handleTab}>
                        <Tab label={t('influencer.offer_management.modal.overview')}
                             value="0" icon={checkIcon(0)} iconPosition={'end'}/>
                        <Tab label={t('influencer.offer_management.modal.content')}
                             value="1" icon={checkIcon(1)} iconPosition={'end'}/>
                        <Tab label={t('influencer.offer_management.modal.distribution')}
                             value="2" icon={checkIcon(2)} iconPosition={'end'}/>
                        {hasLegal &&
                            <Tab label={t('influencer.offer_management.modal.legal')}
                                 value="3" icon={checkIcon(3)} iconPosition={'end'}/>
                        }
                        <Tab label={t('influencer.offer_management.modal.contract')}
                             value="4"/>
                        {isNotInitialOffer &&
                            <Tab label={t('influencer.offer_management.modal.comments')}
                                 icon={<> {hasUnreadComments && <span className="notification-comment-badge">
                                </span>}</>}
                                 value="5"/>
                        }
                        {campaign?.contractPlaceholder?.flags?.hasHistory &&
                            <Tab label={t('influencer.offer_management.modal.history')}
                                 value="6"/>
                        }
                    </TabList>
                </Box>
                <Box ref={offerModalRef} className="modal-right-content">
                    <TabPanel className="p-0 pb-4" value="0">
                        <OverviewTab campaign={campaign}/>
                    </TabPanel>
                    <TabPanel className="p-0 pb-4" value="1">
                        <ContractContentTab/>
                    </TabPanel>
                    <TabPanel className="p-0 pb-4" value="2">
                        <ContractDistributionTab/>
                    </TabPanel>
                    <TabPanel className="p-0 pb-4" value="3">
                        <ContractLegalTab/>
                    </TabPanel>
                    <TabPanel className="p-0 pb-4" value="4">
                        <InfluencerContractTab/>
                    </TabPanel>
                    {isNotInitialOffer &&
                        <TabPanel className="p-0 h-100" value="5">
                            <OfferComments isContract={true} canWriteComment={canWriteComment} offerId={campaign?.contracts[0]?.id}/>
                        </TabPanel>
                    }
                    <TabPanel className="p-0" value="6">
                        <ContractHistoryTab/>
                    </TabPanel>
                </Box>
            </TabContext>
        </>
    );
}
