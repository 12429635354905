import React, {SetStateAction, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {GoDotFill} from 'react-icons/go';
import {useDispatch, useSelector} from 'react-redux';
import {Campaign} from '../../../../model/campaign/Campaign';
import {Platforms} from '../../../../model/platform/Platforms';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import usePagination from '../../../../shared/hooks/usePagination';
import {IconToDo} from '../../../../shared/Icons';
import {plannerInfluencer} from '../../../../store/influencer/plannerSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast} from '../../../../utils/toasters';
import {iFilterPlatforms} from '../../../brand/campaign/manage/calendar/components/CalendarListViewHeader';
import {ManageService} from '../../../brand/campaign/manage/service';

interface IPlannerFiltersProps {
    selectedCalendarView: 'dayGridMonth' | 'dayGridWeek' | 'listWeek'
    isToDoSelected: boolean;
    setIsToDoSelected: React.Dispatch<SetStateAction<boolean>>;
    setIsCreateModalOpened: React.Dispatch<SetStateAction<boolean>>;
}

const PlannerFilters = ({
                            selectedCalendarView,
                            setIsCreateModalOpened,
                            isToDoSelected,
                            setIsToDoSelected
                        }: IPlannerFiltersProps) => {
    const dispatch = useDispatch();
    const {campaigns, selectedCampaign, filterParams} = useSelector((state: IAllStates) => state.plannerInfluencer);
    const [platformDropdown, setPlatformDropdown] = useState<Platforms[]>([]);
    const [filterDropdownPlatforms, setFilterDropdownPlatforms] =
        useState<iFilterPlatforms>({
            name: '',
            isEnabled: true,
            page: 1,
            perPage: 10,
        });
    const {t} = useTranslation();

    const {offerItemEventsEnum} = useSelector((state: IAllStates) => state.enums);
    const changeCreatorsHandler = (paramKey: string, paramValue: string | number) => {
        if (paramKey === 'offerId') {
            const selectedCampaign = campaigns?.find(campaign => campaign.offerId === +paramValue);
            dispatch(plannerInfluencer.actions.setFilterParams({
                campaignId: selectedCampaign?.id,
                isLongTerm: selectedCampaign?.offers?.[0]?.isLongTerm
            }));
            dispatch(plannerInfluencer.actions.setSelectedCampaign(selectedCampaign));
        } else {
            dispatch(plannerInfluencer.actions.setFilterParams({[paramKey]: paramValue}));
            // dispatch(plannerInfluencer.actions.setSelectedCampaign(campaigns?.find(campaign => campaign.id === +paramValue)));
        }
    };
    const selectedPlatform = platformDropdown?.filter(platform => platform?.id === filterParams?.platformId)[0]

    useEffect(() => {
        ManageService.getAdminPlatforms(filterDropdownPlatforms)
            .then((response) => {
                setPlatformDropdown(response.data)
            })
            .catch((error) => ErrorToast(error));
    }, [filterDropdownPlatforms])

    useEffect(() => {
        dispatch(plannerInfluencer.actions.setFilterParams({campaignId: null, isLongTerm: null}));
        dispatch(plannerInfluencer.actions.setSelectedCampaign(null));
        // eslint-disable-next-line
    }, []);

    return (
        <div className="d-flex align-items-center flex-column flex-md-row mb-3 mb-md-0">
            <div className="d-flex align-items-center col-12 col-md pl-0 flex-wrap">
                <span
                    className="font-weight-bold mb-2 mb-md-0 d-md-block d-none p-0">{t('influencer.planner.filters')}:</span>
                <div className="col-12 col-md mb-3 mb-md-0">
                    <CustomAutocomplete
                        dataArray={campaigns} isWithImage={false} inputLabel="title"
                        key={selectedCampaign?.id}
                        isLongSelected={selectedCampaign?.offers?.[0]?.isLongTerm}
                        defaultArray={selectedCampaign as unknown as Campaign[]}
                        disableClearable={false}
                        changeEventKey="title" queryFilterKey="offerId"
                        inputLabelTranslation={t('influencer.planner.filterByCampaign')}
                        handleChangeParams={changeCreatorsHandler}
                    />
                </div>
                {selectedCalendarView !== 'listWeek' ? <>
                    <div className="col-6 col-md">
                        <CustomAutocomplete
                            dataArray={offerItemEventsEnum} inputLabel="plannerStatus" isWithImage={false}
                            queryFilterKey="status" disableClearable={false}
                            classes="pr-2 pr-md-0"
                            inputLabelTranslation={t('influencer.planner.filterByStatus')}
                            handleChangeParams={changeCreatorsHandler}
                        />
                    </div>
                    <div className="col-6 col-md pr-0">
                        <CustomAutocomplete
                            dataArray={platformDropdown?.map(platform => ({
                                ...platform,
                                name: t(`general.platforms.${platform?.name}`)
                            }))}
                            setFilterState={setFilterDropdownPlatforms}
                            isWithImage={true}
                            inputLabel="name"
                            classes="pl-2 pl-md-0"
                            changeEventKey="name"
                            inputLabelTranslation={t('brand.campaign.manage.postingType')}
                            photoLabel="path"
                            selectedStateImage={selectedPlatform?.path as string}
                            queryFilterKey="platformId"
                            disableClearable={false}
                            handleChangeParams={changeCreatorsHandler}
                        />
                    </div>
                </> : <div className="col-md-6 ">
                    <div className="position-relative w-fit-content">
                        <Button variant={isToDoSelected ? 'secondary' : 'outline-secondary'}
                                onClick={() => {
                                    setIsToDoSelected(prev => !prev);
                                }}
                                className={`h-100 d-flex align-items-center py-2 ${isToDoSelected ? 'text-white' : ''} text-uppercase`}>
                            <IconToDo classes="mr-1" fill={isToDoSelected ? '#fff' : undefined}/>
                            {t('general.button.unscheduled')}
                        </Button>
                        {campaigns?.length > 0 && <GoDotFill className="text-danger top-right-icon"
                                                             style={{top: '-15%', right: '-5%'}}
                                                             size={20}/>}
                    </div>
                </div>}

            </div>
            <div className="d-none d-md-flex justify-content-md-end pr-0 col-auto">
                <Button variant="primary"
                        onClick={() => setIsCreateModalOpened(true)}>{t('influencer.planner.addNewPosting')}</Button>
            </div>
        </div>
    );
};

export default PlannerFilters;
