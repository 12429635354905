import {useState} from 'react';
import {scrollToTop} from '../../utils/helpers/ScrollToTop';


interface IProps {
    perPage?: number;
    otherKeys?: any
}

const usePagination = ({perPage = 10, otherKeys}: IProps) => {
    const [count, setCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [filterParams, setFilterParams] = useState({
        page: 1,
        perPage,
        ...otherKeys
    });

    const handlePages = (updatePage: number) => {
        setFilterParams({...filterParams, page: updatePage});
        scrollToTop();
    };
    return {
        totalPages,
        setTotalPages,
        count,
        setCount,
        filterParams,
        handlePages,
        setFilterParams,
    };
};

export default usePagination;
