import {Button, Col, Dropdown, Modal, Row} from 'react-bootstrap';
import React, {SetStateAction, useEffect, useState} from 'react';
import {PackagesEnum} from '../../../../../../enum/PackageEnum';
import ModalProfileInfo from './components/ModalProfileInfo';
import ModalTabs from './components/ModalTabs';
import {useTranslation} from 'react-i18next';
import {BookingService} from '../../service';
import {ErrorToast, SuccessToast} from '../../../../../../utils/toasters';
import {
    formatDate,
    getGrandTotal,
    getNumberContentPieces,
    getNumberPosts,
    getStatusIcon, getSubtotalAdditionalItems
} from '../../../../../../shared/functions/Functions';
import {useLocation} from 'react-router-dom';
import {ConfirmationModal} from '../../../../../../shared/components/alert/ConfirmationModal';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import {
    setActiveTab,
    setCampaignId, setInitialState,
    setOfferModalDetails,
    setOfferModalStatus, setProfileInfo,
    setUserId,
} from '../../../../../../store/brand/offerBrandSlice';
import {priceSplitter} from '../../../../../../utils/helpers/priceSplitter';
import {ConfirmSendingOfferModal} from '../../../../../influencer/offer/modal/offer-modal/components/ConfirmSendingOfferModal';
import {BookingAcceptOfferModal} from '../BookingAcceptOfferModal';
import {DeclineOfferModal} from '../../../../../influencer/offer/modal/offer-modal/components/DeclineOfferModal';
import {IDeclineOffer} from '../../../../../influencer/offer/dto/IDeclineOffer';
import {gtmService} from '../../../../../../utils/gtm.service';
import HtmlToPdf from '../../../../../../shared/components/generate-pdf';

interface IBookingOfferModal {
    show: boolean;
    closeModal: () => void;
    setDeclineReason?: React.Dispatch<SetStateAction<IDeclineOffer>>;
    offerId?: number;
    modalStatus?: string;
    userId?: number;
    passedCampaignId?: number;
    acceptOffer?: (offerId: number) => void;
    declineOffer?: (offerId: number) => void;
    selectOffer?: (offerId: number, isSelected: boolean) => void;
    setUpdateList?: React.Dispatch<React.SetStateAction<boolean>>;
}

function BookingOfferModal({
                               show,
                               closeModal,
                               offerId,
                               userId,
                               passedCampaignId,
                               modalStatus,
                               acceptOffer,
                               declineOffer,
                               selectOffer,
                               setUpdateList,
                               setDeclineReason,
                           }: IBookingOfferModal) {
    const {
        campaign,
        offerModalStatus,
        isEditable,
        canCounter,
        initialOffer,
        isCharityOrPrivate,
        user,
        isTransparentPricing,
    } = useSelector((state: IAllStates) => state.offerBrand);

    const [showModal, setShowModal] = useState(false);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const {company} = useSelector((state: IAllStates) => state.auth.user);
    const {t} = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const campaignId = +location?.pathname?.split('/')?.filter(currLoc => +currLoc)[0];
    const isInvite = modalStatus === 'invite';
    const isNegotiate = offerModalStatus === 'negotiate';
    const grandTotalSum = initialOffer.isAddonsOnly ? getSubtotalAdditionalItems(initialOffer.distribution) : getGrandTotal(initialOffer.offerItems, initialOffer.distribution);
    const grandTotalFeeSum = Math.ceil(grandTotalSum / (campaign?.feePercentage ?? 1));
    const grandTotalForDisplaying = isTransparentPricing ? (isNegotiate ? priceSplitter(grandTotalFeeSum) : priceSplitter(campaign?.offers[0]?.totalWithFee ?? 0)) : priceSplitter(grandTotalSum);

    useEffect(() => {
        dispatch(setInitialState({}));
        if (show) {
            BookingService.getOffer(userId, passedCampaignId ?? campaignId)
                .then(response => {
                    dispatch(setOfferModalDetails(response));
                    if (modalStatus === 'negotiate' || isInvite) {
                        dispatch(setOfferModalStatus('negotiate'));
                    }
                    if (response.offers[0]?.user?.id) {
                      dispatch(setProfileInfo(response.offers[0]?.user));
                    }
                    dispatch(setUserId(userId));
                    dispatch(setCampaignId(campaignId));
                }).catch(err => ErrorToast(err));
            if (isInvite) {
                BookingService.getInviteContent(userId)
                    .then((response) => {
                        if (response) {
                            dispatch(setProfileInfo(response));
                        }
                    });
            }
        }
        // eslint-disable-next-line
    }, [show]);

    const submitOfferRequest = () => {
        setDisableButton(true);
        let initialOfferCopy = initialOffer;
        const offerItems = isCharityOrPrivate ? initialOfferCopy.offerItems : initialOfferCopy.offerItems.filter(item => (item.nbPosts > 0 && item.price > 0));
        const price = getGrandTotal(initialOfferCopy.offerItems, initialOfferCopy.distribution);
        initialOfferCopy = {...initialOfferCopy, offerItems, price};
        BookingService.submitOffer(initialOfferCopy, userId, campaignId)
            .then((response) => {
                gtmService('brand_offer_submitted', grandTotalForDisplaying);
                setDisableButton(false);
                closeModal();
                if (setUpdateList) {
                    setUpdateList(prevState => !prevState);
                }
            }).catch(error => {
            ErrorToast(error);
            setDisableButton(false);
        });
    };
    const withdrawOffer = () => {
        BookingService.withdrawOffer(offerId)
            .then((response) => {
                closeModal();
                if (setUpdateList) {
                    setUpdateList(prevState => !prevState);
                }
                SuccessToast(t('brand.campaign.book.offer.modal.successfully_withdrew'));
            }).catch(error => ErrorToast(error));
    };

    const inviteUserRequest = () => {
        setDisableButton(true);
        let initialOfferCopy = initialOffer;
        const offerItems = isCharityOrPrivate ? initialOfferCopy.offerItems : initialOfferCopy.offerItems.filter(item => (item.nbPosts > 0 && item.price > 0));
        const price = getGrandTotal(initialOfferCopy.offerItems, initialOfferCopy.distribution);
        initialOfferCopy = {...initialOfferCopy, offerItems, price};
        BookingService.inviteUser(initialOfferCopy, userId, campaignId)
            .then((response) => {
                gtmService('brand_offer_submitted', grandTotalForDisplaying);
                setDisableButton(false);
                SuccessToast(t('brand.campaign.book.invite.successfully_invite_user'));
                closeModal();
                if (setUpdateList) {
                    setUpdateList(prevState => !prevState);
                }
            }).catch(error => {
            ErrorToast(error);
            setDisableButton(false);
        });
    };

    const declineOfferAction = () => {
        if (declineOffer) {
            declineOffer(offerId ?? 0);
        }
        closeModal();
    };

    const closeInternalWithdrawModal = () => {
        setShowModal(false);
    };

    const closeDeclineModal = () => {
        setShowDeclineModal(false);
    };

    const changeStatus = () => {
        dispatch(setActiveTab('0'));
        dispatch(setOfferModalStatus('negotiate'));
    };

    const closeAcceptConfirmModal = () => {
        setShowAcceptModal(false);
        closeModal();
    };
    const closeSubmitModal = () => {
        setShowSubmitModal(false);
    };

    const submitForm = (event: any) => {
        event.preventDefault();
        isInvite ? inviteUserRequest() :
            setShowSubmitModal(true);
    };

    return (
        <div onClick={e => e.stopPropagation()}>
            <Modal show={show}
                   onHide={closeModal}
                   id="offer-modal"
                   size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <form onSubmit={(event) => submitForm(event)}>
                    <Modal.Body>
                        {(!isInvite && campaign?.offers[0]?.id) &&
                            <div className={`offer-status-badge ${campaign?.offers[0]?.status}`}>
                                {getStatusIcon(campaign?.offers[0]?.status ?? '')}
                                <p>{t(`general.offerStatus.${campaign?.offers[0]?.status}`)}</p>
                            </div>
                        }
                        <Row>
                            <Col xl={3} lg={12} className="profile-info pt-2">
                                <ModalProfileInfo/>
                            </Col>
                            <Col xl={9} lg={12} className="px-0 px-md-2 d-flex">
                                <ModalTabs/>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between justify-content-md-end shadow-top">
                        {isInvite ?
                            <div onClick={e => e.stopPropagation()}
                                 className="d-flex align-items-center justify-content-between justify-content-md-end w-100">
                                <div className="d-flex mx-md-4 align-items-center  mr-1 ">
                                    <div className="d-flex align-items-end">
                                        <h4 className="mb-md-0 text-uppercase mr-1 d-md-block d-none">
                                            {t('brand.campaign.book.offer.modal.grand_total')}
                                        </h4>
                                        <h6 className="mb-0 text-uppercase mr-1 d-md-none">
                                            {t('brand.campaign.book.offer.modal.grand_total')}:
                                        </h6>
                                        {!isTransparentPricing &&
                                            <p className="mb-0 d-none d-md-block">
                                                {company?.package?.name === PackagesEnum.BASIC ?
                                                    t('brand.campaign.book.offer.modal.incl_booking_fee')
                                                    : t('brand.campaign.book.offer.modal.incl_reduced_fee')}:
                                            </p>
                                        }
                                    </div>
                                    <h4 className="text-primary m-0 px-2 d-none d-md-block euro-symbol">
                                        {grandTotalForDisplaying}
                                    </h4>
                                    <h6 className="text-primary m-0 px-2 d-md-none euro-symbol">
                                        {grandTotalForDisplaying}
                                    </h6>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Button variant="outline-primary" className="btn-sm position-relative mr-3"
                                            onClick={() => closeModal()}>
                                        {t('general.button.cancel')}
                                    </Button>
                                    <Button variant="primary" className="btn-sm position-relative"
                                            type="submit">
                                        {t('brand.campaign.book.invite.invite')}
                                    </Button>
                                </div>

                            </div>
                            :
                            <div className="d-flex justify-content-between w-100">
                                <Button variant="outline-secondary"
                                        className="btn-sm position-relative ml-md-3 mr-1 text-upper"
                                        onClick={() => closeModal()}>
                                    {t('general.button.cancel')}
                                </Button>
                                <div className="d-flex align-items-center">
                                    {(campaign?.offers[0]?.status === 'accepted' || campaign?.offers[0]?.status === 'completed') && (
                                        <HtmlToPdf passedCampaignId={passedCampaignId ?? campaignId}
                                                   passedOffers={campaign?.offers[0]?.id}/>
                                    )}
                                    <div className="d-md-flex mx-md-4 align-items-center mr-1">
                                        <div className="d-flex align-items-end">
                                            <h4 className="mb-md-0 text-uppercase mr-1 d-md-block d-none">
                                                {t('brand.campaign.book.offer.modal.grand_total')}
                                            </h4>
                                            <h6 className="mb-0 text-uppercase mr-1 d-md-none">
                                                {t('brand.campaign.book.offer.modal.grand_total')}
                                            </h6>
                                            {!isTransparentPricing &&
                                                <p className="mb-0 d-none d-md-block">
                                                    {company?.package?.name === PackagesEnum.BASIC ?
                                                        t('brand.campaign.book.offer.modal.incl_booking_fee')
                                                        : t('brand.campaign.book.offer.modal.incl_reduced_fee')}:
                                                </p>
                                            }
                                        </div>
                                        <h4 className="text-primary m-0 px-2 d-none d-md-block euro-symbol">
                                            {grandTotalForDisplaying}
                                        </h4>
                                        <h6 className="text-primary m-0 px-2 d-md-none euro-symbol">
                                            {grandTotalForDisplaying}
                                        </h6>
                                    </div>
                                    {((!campaign?.offerPlaceholder?.flags?.canWithdraw && !canCounter) || isNegotiate) ? null :
                                        <Dropdown>
                                            <Dropdown.Toggle className="mr-2 btn-outline-primary btn-sm" variant="light"
                                                             id="dropdown-basic">
                                                {t('general.button.respond')}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {canCounter &&
                                                    <Dropdown.Item onClick={() => selectOffer &&
                                                        (selectOffer(offerId ?? 0, !campaign?.offers[0]?.isSelected), closeModal())}
                                                                   id="addToCart">{campaign?.offers[0]?.isSelected ? t('general.button.remove_from_cart') : t('general.button.add_to_cart')}
                                                    </Dropdown.Item>
                                                }
                                                {isEditable &&
                                                    <Dropdown.Item id="negotiate"
                                                                   onClick={changeStatus}> {t('general.button.negotiate')} </Dropdown.Item>
                                                }
                                                {(campaign?.offerPlaceholder?.flags?.canWithdraw &&
                                                    <Dropdown.Item onClick={() => setShowModal(true)} id="withdraw">
                                                        {t('brand.campaign.book.offer.modal.withdraw_offer')}
                                                    </Dropdown.Item>
                                                )
                                                }
                                                {canCounter &&
                                                    <Dropdown.Item id="decline"
                                                                   onClick={() => setShowDeclineModal(true)}>
                                                        {t('general.button.decline')}
                                                    </Dropdown.Item>
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                    <Button variant="primary"
                                            type="submit"
                                            className={`${(canCounter && isNegotiate) ? '' : 'd-none'} btn-sm position-relative mr-2`}
                                    >
                                        {t('general.button.submit')}
                                    </Button>
                                    {(canCounter && !campaign?.offers[0]?.isExpired) ?
                                        <Button variant="primary"
                                                className={`btn-sm position-relative ${isNegotiate && 'd-none'} mr-md-3`}
                                                onClick={(e) => setShowAcceptModal(true)}>
                                            {t('general.button.accept')}
                                        </Button> : null
                                    }
                                </div>
                            </div>
                        }
                    </Modal.Footer>
                </form>
                <ConfirmSendingOfferModal action={submitOfferRequest}
                                          grandTotal={grandTotalForDisplaying}
                                          show={showSubmitModal}
                                          disableButton={disableButton}
                                          closeModal={closeSubmitModal}
                                          nbPosts={initialOffer.isAddonsOnly ? 0 : getNumberContentPieces(initialOffer.offerItems)}
                                          user={user}
                />
                <ConfirmationModal
                    title={t('brand.campaign.book.offer.modal.withdraw_offer_question')}
                    description={` ${t('brand.campaign.book.offer.modal.withdraw_description')} ${formatDate(campaign?.offers[0]?.createdAt ?? '')} - ${campaign?.offers[0]?.createdAt.substring(11, 16)}! ${t('brand.campaign.book.offer.modal.are_you_sure')} `}
                    action={withdrawOffer}
                    show={showModal} closeModal={closeInternalWithdrawModal}
                />
                <BookingAcceptOfferModal action={acceptOffer}
                                         offerId={offerId}
                                         show={showAcceptModal}
                                         closeModal={closeAcceptConfirmModal}
                                         grandTotal={grandTotalForDisplaying}
                                         nbPosts={getNumberPosts(campaign?.offerPlaceholder?.totalPlatforms)}
                                         user={campaign?.offers[0]?.user}
                />
                <DeclineOfferModal date={formatDate(campaign?.offers[0]?.createdAt ?? '')}
                                   declineOffer={declineOfferAction}
                                   show={showDeclineModal} closeModal={closeDeclineModal}
                                   setDeclineReason={setDeclineReason}/>
            </Modal>
        </div>
    );
}

export default BookingOfferModal;
